import React from "react";

const Minus = ({color, width}) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 128 128"
    style={{ enableBackground: "new 0 0 128 128" }}
    xmlSpace="preserve"
    width={width}
    fill={color}
  >
    <g id="_x31_6">
      <path
        id="icon_15_"
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
        }}
        d="M64,0C28.654,0,0,28.654,0,64 c0,35.345,28.654,64,64,64s64-28.655,64-64C128,28.654,99.346,0,64,0z M94.545,71.273H33.455V59.636h61.091V71.273z"
      />
    </g>
  </svg>
);

export default Minus;
