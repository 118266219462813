import { useState } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";

const FeedbackAccordian = ({ title, children, id, open=false }) => {
  const [isOpen, setIsOpen] = useState(open);

  return (
    <div className="scroll-smooth" id={id}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="border-t border-kitsaWhite text-kitsaWhite font-medium flex justify-center items-center w-full py-[13px]"
      >
        <span className="text-sm text-left">{title}</span>

        {/* {isOpen ? (
          <div className="text-xl font-light transition-all duration-500">
            <FiMinus />
          </div>
        ) : (
          <div className="text-xl font-light transition-all duration-500">
            <FiPlus />
          </div>
        )} */}
      </button>

      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-white ${
          isOpen ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className="mb-[8px] font-light text-[14px] md:text-[16px] text-kitsaWhite overflow-hidden">
          <div className="text-center mx-auto">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackAccordian;
