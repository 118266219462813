import React from "react";

const CrossLine = ({color, width}) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 128 128"
      xmlSpace="preserve"
      fill={color}
      width={width}
    >
      <g id="_x31_1">
        <polygon
          id="icon_10_"
          points="128,105.706 105.706,128 64,86.294 22.294,128 0,105.706 41.706,64 0,22.294 
		22.294,0 64,41.706 105.706,0 128,22.294 86.294,64 	"
        />
      </g>
      <g id="Layer_1"></g>
    </svg>
  );
};

export default CrossLine;
