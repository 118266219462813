import { useDispatch, useSelector } from "react-redux";
import imageNA from "../../assets/Image-NA.webp";

import discount from "../../assets/icons/discount.svg";
import { useEffect, useState } from "react";
import { addToCartApi, deleteFromCartApi } from "../../api";
import { setShowComingSoon } from "../../features/slidersSlice";
import { setUserCartItems } from "../../features/apiCartSlice";
import EtaIcon from "../../assets/icon components/Eta";
import Minus from "../../assets/icon components/Minus";
import Plus from "../../assets/icon components/Plus";

const CartProducts = ({ userCart }) => {
  const { eta } = useSelector((state) => state.googleMapLocation);
  return (
    <div className="text-kitsaWhite shadow-peachBox rounded-[20px] mx-3 px-2 py-4">
      <div className="flex items-center justify-between mb-6">
        <h5 className="text-sm font-bold">
          Shipment of {userCart?.length} essentials
        </h5>
        <div className="flex items-center border border-kitsaWhite gap-2 px-[5px] py-[2px] rounded-full">
          <p className="text-[11px] tracking-[-0.28px] font-bold leading-none">
            {eta.eta_minutes} mins
          </p>
          <EtaIcon color="#f1edeb" />
        </div>
      </div>

      <div className="flex flex-col gap-10">
        {userCart.map((product) => (
          <div key={product.product_id}>
            <ProductCard userCart={userCart} product={product} />
            <hr className="border-b border-kitsaWhite opacity-[0.3] blur-sm" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CartProducts;

const ProductCard = ({ product }) => {
  const dispatch = useDispatch();
  const [productQuantity, setProductQuantity] = useState(product.quantity);
  useEffect(() => {
    setProductQuantity(product.quantity);
  }, [product]);

  const handleDecrement = () => {
    if (productQuantity > 1) {
      const updatedQuantity = productQuantity - 1;
      setProductQuantity(updatedQuantity);
      handleAddToCartApiCall(updatedQuantity);
    } else {
      handleDeleteApiCall();
    }
  };

  const handleIncrement = () => {
    const updatedQuantity = productQuantity + 1;
    setProductQuantity(updatedQuantity);
    handleAddToCartApiCall(updatedQuantity);
  };

  const handleAddToCartApiCall = async (quantity) => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(addToCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          cart_data: [
            {
              product_id: product.product_id,
              quantity,
            },
          ],
        }),
      });

      const data = await response.json();

      if (response.ok) {
        dispatch(setUserCartItems(data));
      } else {
      }
    } catch (error) {
      // console.error("Failed to add to cart:", error);
    }
  };

  const handleDeleteApiCall = async () => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(deleteFromCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          product_ids: [product.product_id],
        }),
      });

      const data = await response.json();

      if (response.ok) {
        dispatch(setUserCartItems(data));
      } else {
      }
    } catch (error) {
      // console.error("Failed to delete:", error);
    }
  };

  return (
    <div className="flex gap-2 justify-between">
      <div className="flex gap-3">
        <div className="p-[5px] bg-white rounded-[10px] relative shrink-0 h-[77px] w-[78px] shadow shadow-kitsaWhite/70">
          <img
            src={
              product.product_image !== "NA" &&
              product.product_image !==
                "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
                ? product.product_image
                : "https://media-hosting.imagekit.io//a3385a43077f4e0e/Image%20Not%20Available.png?Expires=1833358862&Key-Pair-Id=K2ZIVPTIP2VGHC&Signature=h-gValwZSoRKUBi9iEHAzKOKdwPlgYLNYSi5K7sAEH2knqMt4gpgaf~GJytsoI9gQemKj-5mIaJ4SPminI9Ts4j4esZ4j-udW3LgKjxS5Vn7goq6Z4bhS~5hzKy4uzSjttW1KAoemJvrM3em5fiBlImWlXQ6lOkyt79rhvtE11KwbRFH2w2pbdXPemroPpZxgHLRAAJ6EEsWL-psJxPosRF6LZz5giAWuUNGeem~P0IieT38Qlzvb6~nyJ96og4-XODPBV46s6vk3amWOa20pBrPSDP0X6a7Tjwpo9vunyMapTz9U0ucJqtk~c6yn9kD-YcgMIlQtPJmOG8AbIdtSA__"
            }
            loading="lazy"
            alt={product.product_name}
            className="aspect-square rounded-[10px]"
          />
          <div className="h-[23px] w-[19px] absolute -top-[7px] -left-[1px]">
            <img src={discount} alt="discount!" className="" />
            <div className="absolute -top-1 -left-[2.5px] h-[28px] w-[23px] flex items-center justify-center">
              <p className="text-[8px] text-formisBlack font-bold leading-none">
                {parseInt(product.discount_percentage)}%
                <br /> OFF
              </p>
            </div>
          </div>
        </div>

        <div className="shrink">
          <p className="text-xs font-medium mb-1">{product.product_name}</p>
          {/* <a
            href="tel:+91-9911266699"
            className="text-[10px] font-bold text-kitsaGreen border-b border-kitsaGreen leading-none"
          >
            Add to Auto Refill Schedule
          </a> */}
          <button
            onClick={() => dispatch(setShowComingSoon(true))}
            className="text-[10px] font-bold text-kitsaGreen border-b border-kitsaGreen leading-none"
          >
            Add to Auto Refill Schedule
          </button>
        </div>
      </div>

      <div className="">
        <div className="bg-kitsaGreen text-kitsaWhite flex items-center w-fit place-self-end rounded-[8px] py-[2px] mb-2">
          <button onClick={handleDecrement} className="w-[20px] pl-2">
            <Minus width="15" color="#f1edeb" />
          </button>
          <input
            value={productQuantity}
            className="text-sm font-bold bg-transparent outline-none w-[40px] text-center"
            readOnly
          />
          <button onClick={handleIncrement} className="w-[20px] pr-2">
            <Plus width="15" color="#f1edeb" />
          </button>
        </div>

        <div className="flex items-center gap-1 font-bold place-self-end">
          <p className="text-[10px] border-r pr-[6px]">
            ₹{(product.selling_price_at_time * product.quantity).toFixed(2)}
          </p>
          <span className="text-[10px]">
            <s>₹{(product.mrp * product.quantity).toFixed(2)}</s>
          </span>
        </div>
      </div>
    </div>
  );
};
