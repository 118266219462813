import React from "react";

const DSun = ({color, width}) => {
  return (
    <svg id="Glyph" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill={color} width={width}>
      <path d="M32,13c-.55,0-1-.45-1-1V5c0-.55,.45-1,1-1s1,.45,1,1v7c0,.55-.45,1-1,1Z" />
      <path d="M59,33h-7c-.55,0-1-.45-1-1s.45-1,1-1h7c.55,0,1,.45,1,1s-.45,1-1,1Z" />
      <path d="M32,60c-.55,0-1-.45-1-1v-7c0-.55,.45-1,1-1s1,.45,1,1v7c0,.55-.45,1-1,1Z" />
      <path d="M12,33H5c-.55,0-1-.45-1-1s.45-1,1-1h7c.55,0,1,.45,1,1s-.45,1-1,1Z" />
      <path d="M46.14,18.86c-.26,0-.51-.1-.71-.29-.39-.39-.39-1.02,0-1.41l4.95-4.95c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41l-4.95,4.95c-.2,.2-.45,.29-.71,.29Z" />
      <path d="M51.09,52.09c-.26,0-.51-.1-.71-.29l-4.95-4.95c-.39-.39-.39-1.02,0-1.41s1.02-.39,1.41,0l4.95,4.95c.39,.39,.39,1.02,0,1.41-.2,.2-.45,.29-.71,.29Z" />
      <path d="M12.91,52.09c-.26,0-.51-.1-.71-.29-.39-.39-.39-1.02,0-1.41l4.95-4.95c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41l-4.95,4.95c-.2,.2-.45,.29-.71,.29Z" />
      <path d="M17.86,18.86c-.26,0-.51-.1-.71-.29l-4.95-4.95c-.39-.39-.39-1.02,0-1.41s1.02-.39,1.41,0l4.95,4.95c.39,.39,.39,1.02,0,1.41-.2,.2-.45,.29-.71,.29Z" />
      <path d="M24.35,14.52c-.39,0-.76-.23-.92-.62l-1.53-3.7c-.21-.51,.03-1.1,.54-1.31,.51-.21,1.1,.03,1.31,.54l1.53,3.7c.21,.51-.03,1.1-.54,1.31-.13,.05-.25,.08-.38,.08Z" />
      <path d="M13.52,25.35c-.13,0-.26-.02-.38-.08l-3.7-1.53c-.51-.21-.75-.8-.54-1.31,.21-.51,.79-.75,1.31-.54l3.7,1.53c.51,.21,.75,.8,.54,1.31-.16,.38-.53,.62-.92,.62Z" />
      <path d="M9.83,42.19c-.39,0-.76-.23-.92-.62-.21-.51,.03-1.1,.54-1.31l3.7-1.53c.51-.21,1.1,.03,1.31,.54s-.03,1.1-.54,1.31l-3.7,1.53c-.13,.05-.25,.08-.38,.08Z" />
      <path d="M22.81,55.17c-.13,0-.26-.02-.38-.08-.51-.21-.75-.8-.54-1.31l1.53-3.7c.21-.51,.79-.75,1.31-.54,.51,.21,.75,.8,.54,1.31l-1.53,3.7c-.16,.38-.53,.62-.92,.62Z" />
      <path d="M41.19,55.17c-.39,0-.76-.23-.92-.62l-1.53-3.7c-.21-.51,.03-1.1,.54-1.31,.51-.21,1.1,.03,1.31,.54l1.53,3.7c.21,.51-.03,1.1-.54,1.31-.13,.05-.25,.08-.38,.08Z" />
      <path d="M54.17,42.19c-.13,0-.26-.02-.38-.08l-3.7-1.53c-.51-.21-.75-.8-.54-1.31,.21-.51,.79-.75,1.31-.54l3.7,1.53c.51,.21,.75,.8,.54,1.31-.16,.38-.53,.62-.92,.62Z" />
      <path d="M50.48,25.35c-.39,0-.76-.23-.92-.62-.21-.51,.03-1.1,.54-1.31l3.7-1.53c.51-.21,1.09,.03,1.31,.54,.21,.51-.03,1.1-.54,1.31l-3.7,1.53c-.13,.05-.25,.08-.38,.08Z" />
      <path d="M39.65,14.52c-.13,0-.26-.02-.38-.08-.51-.21-.75-.8-.54-1.31l1.53-3.7c.21-.51,.79-.75,1.31-.54,.51,.21,.75,.8,.54,1.31l-1.53,3.7c-.16,.38-.53,.62-.92,.62Z" />
      <circle cx="32" cy="32" r="16" />
    </svg>
  );
};

export default DSun;
