import { useEffect, useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { FaUserEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { profileApi } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { toggleProfileComponent } from "../features/profileSlice";

import { BiSolidPhoneCall } from "react-icons/bi";
import { MdNavigateNext, MdOutlineKeyboardArrowLeft } from "react-icons/md";

const Profile = () => {
  const [menuStep, setMenuStep] = useState(null);
  const dispatch = useDispatch();
  const isProfileOpen = useSelector((state) => state.profile.isProfileOpen);
  const toggleProfile = () => {
    dispatch(toggleProfileComponent());
  };

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showIosMessage, setShowIosMessage] = useState(false);

  useEffect(() => {
    const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isStandalone =
      window.matchMedia("(display-mode: standalone)").matches ||
      navigator.standalone;

    if (isIos && !isStandalone) {
      setShowIosMessage(true); // Show guide for iOS (Safari & Chrome)
    }

    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(() => {
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <div className="text-formisBlack">
      <div
        className={`transition-opacity duration-500 ${
          isProfileOpen ? "z-20" : "bg-transparent -z-50"
        } fixed inset-0 cross-cursor`}
        onClick={toggleProfile}
      />
      <div
        id="profile-slider"
        className={`fixed text-kitsaWhite pt-5 gradient-bg z-[31] h-[calc(100dvh-3rem)] lg:h-[calc(88dvh-2.5rem)] lg:w-[380px] 2xl:w-[430px]
               transition-transform duration-500 flex flex-col ${
                 isProfileOpen ? "slide-up" : "slide-down"
               }`}
      >
        <div className="mb-5">
          <div className="flex justify-between items-center pb-7 px-5">
            <h3 className="text-[26px] font-bold">Support</h3>
            <div
              onClick={toggleProfile}
              className="p-2 rounded-full border border-kitsaWhite text-kitsaWhite text-2xl cursor-pointer hover:bg-formisBlack transition-all hover:rotate-90 duration-500"
            >
              <IoCloseOutline />
            </div>
          </div>
          <hr className="seperator w-full" />
        </div>

        <div className="px-4">
          <a
            href="tel:+91-9911266699"
            onTouchStart={(e) =>
              e.currentTarget.classList.add("scale-95", "opacity-70")
            }
            onTouchEnd={(e) =>
              e.currentTarget.classList.remove("scale-95", "opacity-70")
            }
            className="flex items-center justify-between w-full border-b border-kitsaWhite/50 pb-2 mb-8 transition-all duration-150"
          >
            <div className="text-sm font-bold flex items-center gap-3 text-kitsaWhite  px-4 py-2 rounded-full">
              Call to order
              <BiSolidPhoneCall className="text-xl" />
            </div>
            <MdNavigateNext className="text-3xl" />
          </a>

          <button
            onClick={() => setMenuStep(1)}
            onTouchStart={(e) =>
              e.currentTarget.classList.add("scale-95", "opacity-70")
            }
            onTouchEnd={(e) =>
              e.currentTarget.classList.remove("scale-95", "opacity-70")
            }
            className="flex items-center justify-between w-full border-b border-kitsaWhite/50 pb-2 mb-8 transition-all duration-150"
          >
            <div className="text-sm font-bold flex items-center gap-3 text-kitsaWhite  px-4 py-2 rounded-full">
              Profile
            </div>
            <MdNavigateNext className="text-3xl" />
          </button>

          {deferredPrompt && (
            <button
              onClick={handleInstallClick}
              onTouchStart={(e) =>
                e.currentTarget.classList.add("scale-95", "opacity-70")
              }
              onTouchEnd={(e) =>
                e.currentTarget.classList.remove("scale-95", "opacity-70")
              }
              className="flex items-center justify-between w-full border-b border-kitsaWhite/50 pb-2 mb-8 transition-all duration-150"
            >
              <div className="text-sm font-bold flex items-center gap-3 text-kitsaWhite  px-4 py-2 rounded-full">
                <p className="rounded-full">Install App</p>
              </div>
              <MdNavigateNext className="text-3xl" />
            </button>
          )}
          {showIosMessage && (
            <div className="">
              📲 <strong>iOS Users (Safari & Chrome):</strong> Tap{" "}
              <strong>Share</strong> →<strong> Add to Home Screen</strong> to
              install.
            </div>
          )}
        </div>

        <div
          className={`overflow-scroll absolute bottom-0 left-0 bg-gradient-to-b from-[#203040] via-[#203040] to-[#15171c] transition-all duration-300 ease-out h-full w-full text-formisBlack ${
            menuStep === 1 ? "translate-x-0" : "translate-x-full"
          }`}
        >
          <ProfileDetails setMenuStep={setMenuStep} />
        </div>
      </div>
    </div>
  );
};

export default Profile;

const ProfileDetails = ({ setMenuStep }) => {
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const isEffectCalled = useRef(false);

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({ ...prev, [name]: value }));
  };

  const toggleProfile = () => {
    dispatch(toggleProfileComponent());
  };

  const fetchProfile = async () => {
    try {
      const access = localStorage.getItem("accessToken");
      const response = await fetch(profileApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setProfileData(data);
      } else if (response.status === 401) {
        localStorage.setItem("isAuthenticated", false);
      }
    } catch (error) {
      // console.error("Error fetching profile:", error);
    }
  };

  const saveProfile = async () => {
    try {
      const access = localStorage.getItem("accessToken");
      const response = await fetch(profileApi, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          first_name: profileData.first_name,
          last_name: profileData.last_name,
        }),
      });
      if (response.ok) {
        setIsEditing(false);
      } else {
      }
    } catch {}
  };

  useEffect(() => {
    if (isEffectCalled.current === false) {
      fetchProfile();
    }
    return () => (isEffectCalled.current = true);
  }, []);

  return (
    <div className="text-kitsaWhite">
      <button
        onClick={() => setMenuStep(null)}
        className="bg-kitsaWhite text-formisBlack px-4 w-full grid grid-cols-5 items-center justify-center rounded-b-[30px] h-[52px] mb-5"
      >
        <MdOutlineKeyboardArrowLeft className="text-2xl" />
        <p className="text-sm font-[700] col-span-3">Profile</p>
      </button>

      <div className="px-5">
        {profileData ? (
          <div>
            <div className="cursor-pointer flex items-center justify-between gap-3 mb-5">
              <div className="flex items-center gap-2">
                <FaUserEdit />
                <button
                  onClick={handleEditToggle}
                  className="text-sm font-bold"
                >
                  {isEditing ? "Cancel" : "Edit Profile"}
                </button>
              </div>
            </div>
            <div className="mb-5">
              <p className="text-sm font-bold mb-2">First Name</p>
              <input
                className="address-input"
                readOnly={!isEditing}
                name="first_name"
                value={profileData.first_name}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-5">
              <p className="text-sm font-bold mb-2">Last Name</p>
              <input
                className="address-input"
                readOnly={!isEditing}
                name="last_name"
                value={profileData.last_name}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-5">
              <p className="text-sm font-bold mb-2">Mobile Number</p>
              <input
                className="address-input"
                readOnly
                value={profileData.mobile_number}
              />
            </div>
            {isEditing && (
              <button
                onClick={saveProfile}
                className="mt-4 px-4 py-2 bg-formisBlack text-kitsaWhite rounded-full"
              >
                Save
              </button>
            )}
          </div>
        ) : (
          <div className="w-full text-center">
            <Link
              to="/login"
              onClick={toggleProfile}
              className="bg-formisBlack text-kitsaWhite px-4 py-2 rounded-full"
            >
              Login to view profile
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
