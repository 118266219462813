import favicon from "../assets/kitsa-favicon.ico";

const EndText = () => {
  return (
    <div className="text-[#666666]/50 pt-14 px-3">
      <h1 className="text-[60px] font-black text-kitsaGreen leading-none mb-2">
        Healthcare
      </h1>
      <h2 className="text-[30px] font-extrabold leading-none mb-2">
        The Way It Should Be.
      </h2>
      <p className="text-lg font-bold mb-5">
        It’s Going To Be{" "}
        <span className="text-kitsaGreen font-[MestizaSans]">KITSA™.</span>
      </p>
      <img src={favicon} alt="FORMIS" className="" />
    </div>
  );
};

export default EndText;
