import React from "react";

const Plus = ({ color, width }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 128 128"
    style={{ enableBackground: "new 0 0 128 128" }}
    xmlSpace="preserve"
    fill={color}
    width={width}
  >
    <g id="_x31_">
      <path
        id="icon"
        style={{
          fillRule: "evenodd",
          clipRule: "evenodd",
        }}
        d="M64,0C28.654,0,0,28.654,0,64 c0,35.345,28.654,64,64,64s64-28.655,64-64C128,28.654,99.346,0,64,0z M94.545,68.364H68.364v26.182h-8.727V68.364H33.455v-8.727 h26.182V33.455h8.727v26.182h26.182V68.364z"
      />
    </g>
  </svg>
);

export default Plus;
