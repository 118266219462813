import React from "react";

const AddressOther = ({ width, color }) => {
  return (
    <svg
      version="1.1"
      id="Glyph"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
      xmlSpace="preserve"
      fill={color}
      width={width}
    >
      <g>
        <path
          d="M29.0001,26.99728c-0.60165-0.01062-1.13267-0.59048-0.97031-1.23995L28.71918,23h-2.16547l-3.16534,5.05817
		c1.58026,2.67981,4.36548,4.56732,7.61163,4.89117v-6.83118c0,0-1.55273,0.77637-1.55274,0.77637
		C29.30011,26.96811,29.14797,26.99989,29.0001,26.99728z"
        />
        <path
          d="M22.37213,20.33276C22.13611,21.18341,22,22.0752,22,23c0,0.98724,0.14917,1.93939,0.41693,2.84088l2.26746-3.62335
		L22.37213,20.33276z"
        />
        <path
          d="M28.71918,21c0,0-0.68711-2.7482-0.68939-2.7573c-0.20369-0.81469,0.66647-1.51221,1.41747-1.13723l7.52795,3.76398
		l4.01025-2.22961C39.36145,15.30737,35.9494,13,32,13c-3.84686,0-7.18768,2.18646-8.85968,5.37897L26.35596,21H28.71918z"
        />
        <path
          d="M30.49756,24.1333L34.76367,22l-4.26611-2.1333l0.47266,1.89062c0.03955,0.15918,0.03955,0.32617,0,0.48535
		L30.49756,24.1333z"
        />
        <path
          d="M31.31787,48.73096C31.50977,48.91016,31.75488,49,32,49s0.49023-0.08984,0.68213-0.26904
		C33.30762,48.14746,48,34.30469,48,23c0-8.82227-7.17773-16-16-16s-16,7.17773-16,16
		C16,34.30469,30.69238,48.14746,31.31787,48.73096z M20,23c0-6.6167,5.3833-12,12-12c6.61719,0,12,5.3833,12,12s-5.38281,12-12,12
		C25.3833,35,20,29.6167,20,23z"
        />
        <path
          d="M40,25.81836l0.75415,2.00531C41.54614,26.39209,42,24.74866,42,23c0-0.84985-0.11804-1.67072-0.31854-2.45911L40,21.47601
		V25.81836z"
        />
        <path
          d="M38,26v-3.41168l-0.51465,0.28619c-0.00684,0.00378-0.0144,0.00488-0.0213,0.00848
		c-0.0061,0.00323-0.01056,0.00842-0.01678,0.01154L33,25.11816v7.83118c2.50293-0.24969,4.72913-1.43292,6.3454-3.18713
		l-1.28192-3.41016C38.02148,26.23926,38,26.12012,38,26z"
        />
        <path
          d="M7.76019,47.96997c0.07147,0.01788,23.99982,6,23.99982,6c0.1579,0.03949,0.32202,0.03949,0.47998,0l24-6
		c0.49701-0.12152,0.7439-0.54529,0.7428-0.96942c-0.00116-0.42346-0.24951-0.84729-0.74298-0.97064
		c-0.05688-0.01422-14.95978-3.73993-14.95978-3.73993C37.94,46.48999,34.76001,49.51996,34.04999,50.19
		C33.48999,50.70996,32.76001,51,32,51s-1.48999-0.29004-2.04999-0.81c-0.71002-0.67004-3.89001-3.70001-7.23004-7.90002
		L7.76001,46.02997C6.76697,46.27277,6.771,47.72272,7.76019,47.96997z"
        />
        <path
          d="M56.72998,49.90997l-24,6c-0.47784,0.11945-0.98206,0.11945-1.45996,0l-24-6C7.17999,49.89001,7.09003,49.85999,7,49.81V51
		c0,0.45996,0.31,0.85999,0.76001,0.96997l24,6c0.15796,0.03949,0.3219,0.03949,0.47998,0c0.0011-0.00031,24-6,24-6
		C56.69,51.85999,57,51.45996,57,51v-1.19C56.90997,49.85999,56.82001,49.89001,56.72998,49.90997z"
        />
      </g>
    </svg>
  );
};

export default AddressOther;
