import { useNavigate } from "react-router-dom";
import imageNA from "../../assets/Image-NA.webp";
import discount from "../../assets/icons/discount.svg";

const ProductCardAvailable = ({ product }) => {
  const navigate = useNavigate();
  return (
    <div className="flex gap-4 justify-between pt-2">
      <div className="flex gap-4">
        <div
          onClick={() => navigate(`/product/${product.product.slug_field}`)}
          className="bg-white rounded-[10px] relative shrink-0 h-[77px] w-[78px]"
        >
          <img
            src={
              product?.product.primary_image_url ===
              "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
                ? "https://media-hosting.imagekit.io//a3385a43077f4e0e/Image%20Not%20Available.png?Expires=1833358862&Key-Pair-Id=K2ZIVPTIP2VGHC&Signature=h-gValwZSoRKUBi9iEHAzKOKdwPlgYLNYSi5K7sAEH2knqMt4gpgaf~GJytsoI9gQemKj-5mIaJ4SPminI9Ts4j4esZ4j-udW3LgKjxS5Vn7goq6Z4bhS~5hzKy4uzSjttW1KAoemJvrM3em5fiBlImWlXQ6lOkyt79rhvtE11KwbRFH2w2pbdXPemroPpZxgHLRAAJ6EEsWL-psJxPosRF6LZz5giAWuUNGeem~P0IieT38Qlzvb6~nyJ96og4-XODPBV46s6vk3amWOa20pBrPSDP0X6a7Tjwpo9vunyMapTz9U0ucJqtk~c6yn9kD-YcgMIlQtPJmOG8AbIdtSA__"
                : product.product.primary_image_url
            }
            loading="lazy"
            alt="image"
            className="h-[77px] w-[78px] rounded-[10px]"
          />
          <div className="h-[23px] w-[19px] absolute -top-[7px] z-10 -left-[1px]">
            <img src={discount} alt="discount!" className="" />
            <div className="absolute -top-1 -left-[2.5px] h-[28px] w-[23px] flex items-center justify-center">
              <p className="text-[8px] text-formisBlack font-bold leading-none">
                {parseInt(product?.discount_percentage)}%
                <br /> OFF
              </p>
            </div>
          </div>
        </div>

        <div className="shrink">
          <p className="text-xs font-bold mb-1">{product?.product.name}</p>
          <div className="flex items-center gap-1 mb-2">
            <div className="bg-kitsaWhite rounded-full">
              <span className="capitalize text-[10px] font-bold px-1 leading-none h-[13px] tracking-[-0.25px] flex items-center justify-center rounded-[10px] bg-kitsaWhite text-formisBlack border border-kitsaWhite">
                {product?.product.packaging}
              </span>
            </div>
          </div>

          <div className="text-[10px] font-medium">
            <p>
              {product?.product.salt_composition !== "NA"
                ? product.product.salt_composition
                : ""}
            </p>
          </div>
        </div>
      </div>

      <div className="">
        <div className="bg-kitsaGreen text-kitsaWhite flex items-center justify-center w-full rounded-[8px] py-[2px] mb-4">
          <input
            value={product?.quantity}
            className="text-sm font-bold bg-transparent outline-none w-[40px] text-center"
            readOnly
          />
        </div>

        <div className="flex items-center gap-1 font-bold place-self-end">
          <p className="text-[12px]">₹{product?.selling_price_at_time}</p>
          <span className="text-[10px]">
            <s>₹{product?.product.mrp}</s>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductCardAvailable;
