import Expandable from "./Expandable";
import { GiKidneys } from "react-icons/gi";
import { GiLiver } from "react-icons/gi";
import { MdDriveEta } from "react-icons/md";
import { FaPersonBreastfeeding } from "react-icons/fa6";
import { FaPersonPregnant } from "react-icons/fa6";
import { BiSolidDrink } from "react-icons/bi";

const ProductFeatures = ({ productData }) => {
  return (
    <div className="mt-20">
      <div className="mb-14">
        <h3 className="title-md font-bold mb-8">Product Details</h3>
        <Expandable isFirst={true} title="Introduction">
          <p>{productData.description}</p>
        </Expandable>
        <Expandable title="Uses">
          <p>{productData.use_of}</p>
        </Expandable>
        <Expandable title="Benefits">
          <p>{productData.benefits}</p>
        </Expandable>
        <Expandable title="How to use">
          <p>{productData.how_to_use}</p>
        </Expandable>
        <Expandable title="Side Effects">
          <p>{productData.common_side_effects}</p>
        </Expandable>
        <Expandable title="How it works">
          <p>{productData.how_it_works}</p>
        </Expandable>
        <Expandable title="If Dose Missed">
          <p>{productData.if_miss}</p>
        </Expandable>
      </div>

      <div className="">
        <div className="max-w-48 mx-auto">
          <svg
            viewBox="0 0 234 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 20C0 8.95431 8.95431 0 20 0H214C225.046 0 234 8.9543 234 20V74H0V20Z"
              fill="currentColor"
            ></path>
            <rect x="58" y="32" width="118" height="19" rx="9.5"></rect>
            <circle cx="117.5" cy="32.5" r="11.5"></circle>
          </svg>
        </div>
        <div className="text-formisBlack bg-formisWhite/50 bg-opacity-[0.05] px-5 py-7 rounded-[10px]">
          <h4 className="text-2xl lg:text-[30px] font-bold text-center mb-7">
            Safety Advice
          </h4>

          <div className="flex items-start justify-center flex-wrap gap-6">
            <div className="flex flex-col items-center max-w-28">
              <div className="bg-white/80 mb-3 rounded-full flex items-center justify-center w-fit p-4">
                <BiSolidDrink className="text-5xl text-formisBlack15" />
              </div>
              <p className="text-sm font-[700] mb-1">Alcohol</p>
              <p className="text-center font-[MestizaSans] text-sm">
                {productData.alcohol_interaction}
              </p>
            </div>

            <div className="flex flex-col items-center max-w-28">
              <div className="bg-white/80 mb-3 rounded-full flex items-center justify-center w-fit p-4">
                <FaPersonPregnant className="text-5xl text-formisBlack15" />
              </div>
              <p className="text-sm font-[700] mb-1">Pregnancy</p>
              <p className="text-center font-[MestizaSans] text-sm">
                {productData.pregnancy_interaction}
              </p>
            </div>

            <div className="flex flex-col items-center max-w-28">
              <div className="bg-white/80 mb-3 rounded-full flex items-center justify-center w-fit p-4">
                <FaPersonBreastfeeding className="text-5xl text-formisBlack15" />
              </div>
              <p className="text-sm font-[700] mb-1">Breast Feeding</p>
              <p className="text-center font-[MestizaSans] text-sm">
                {productData.lactation_interaction}
              </p>
            </div>

            <div className="flex flex-col items-center max-w-28">
              <div className="bg-white/80 mb-3 rounded-full flex items-center justify-center w-fit p-4">
                <MdDriveEta className="text-5xl text-formisBlack15" />
              </div>
              <p className="text-sm font-[700] mb-1">Driving</p>
              <p className="text-center font-[MestizaSans] text-sm">
                {productData.driving_interaction}
              </p>
            </div>

            <div className="flex flex-col items-center max-w-28">
              <div className="bg-white/80 mb-3 rounded-full flex items-center justify-center w-fit p-4">
                <GiKidneys className="text-5xl text-formisBlack15" />
              </div>
              <p className="text-sm font-[700] mb-1">Kidney</p>
              <p className="text-center font-[MestizaSans] text-sm">
                {productData.kidney_interaction}
              </p>
            </div>

            <div className="flex flex-col items-center max-w-28">
              <div className="bg-white/80 mb-3 rounded-full flex items-center justify-center w-fit p-4">
                <GiLiver className="text-5xl text-formisBlack15" />
              </div>
              <p className="text-sm font-[700] mb-1">Liver</p>
              <p className="text-center font-[MestizaSans] text-sm">
                {productData.liver_interaction}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductFeatures;
