import React from "react";

const Back = ({ color, width }) => {
  return (
    <svg
      id="noun-back-7391075"
      xmlns="http://www.w3.org/2000/svg"
      width="30.223"
      height="30.223"
      viewBox="0 0 30.223 30.223"
      width={width}
    >
      <path
        id="Path_163"
        data-name="Path 163"
        d="M16.111,1A15.112,15.112,0,1,1,5.426,5.426,15.063,15.063,0,0,1,16.111,1Zm2.507,6.67a.707.707,0,1,1,1.056.941l-6.691,7.5,6.691,7.5a.707.707,0,1,1-1.056.941l-7.105-7.966a.708.708,0,0,1-.006-.947l7.111-7.973Z"
        transform="translate(-1 -1)"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Back;
