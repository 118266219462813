import Accordian from "./Accordian";
// import { Link } from "react-router-dom";

const FAQ = () => {
  return (
    <section
      className="pt-20 bg-formisBlack px-4 rounded-t-[25px] shadow-footer"
      id="faq"
    >
      <div className="text-4xl text-kitsaWhite font-medium mb-10">
        <h1 className="leading-[2.5rem]">
          Frequently Asked Questions
          <span className="text-kitsaWhite">.</span>
        </h1>
      </div>

      <div className="flex flex-col gap-3">
        <Accordian title="Q: What kind of products does KITSA™ deliver?">
          <p className="text-[14px] opacity-60">
            A: <span className="">KITSA™</span> delivers everything from daily
            health essentials to lifestyle-enhancing products . You’ll get every
            cure that contributes to making you feel as healthy as you were in
            your youthful fit —and then some.
          </p>
        </Accordian>
        <Accordian title="Q: Do you charge delivery fees for online health essentials?">
          <p className="text-[14px] opacity-60">
            A: Nope. At <span className="">KITSA™</span> , healthcare is
            simplified —there are no hidden fees, no delivery charges, and no
            minimum order value. Just fast, reliable service.
          </p>
        </Accordian>
        <Accordian title="Q: Do I need an app to use KITSA™?">
          <p className="text-[14px] opacity-60">
            {/* A: Absolutely not. All you need is WhatsApp.{" "}
            <span className="">KITSA™</span> keeps it simple, so you never need
            to worry about downloading another app. The cure is around the
            corner, without the clutter. */}
            Absolutely not. All you need is your browser.{" "}
            <span className="">KITSA™</span> keeps it simple with our new
            progressive web app—no extra downloads, no clutter. Your wellness
            connection is right around the corner.
          </p>
        </Accordian>
        <Accordian title="Q: How do I join KITSA™ Access Pass?">
          <p className="mb-4 text-[14px] opacity-60">
            When you order through <span className="">KITSA™</span>, you unlock
            the exclusive KITSA™ Access Pass! Enjoy free entry to life-enhancing
            events, energizing experiences, and even in-store pickup perks—all
            designed to keep you youthful, fit, and thriving.
          </p>
          {/* <a target="_blank"> */}
          <button
            disabled
            className="bg-kitsaWhite text-formisBlack border border-kitsaWhite rounded-full text-[12px] md:text-[14px] font-[500] h-[47px] px-4"
          >
            COMING SOON
          </button>
          {/* </a> */}
        </Accordian>
      </div>
    </section>
  );
};

export default FAQ;
