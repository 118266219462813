import React from "react";

const ArrowLeft = ({ color, width }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    style={{ enableBackground: "new 0 0 32 32" }}
    xmlSpace="preserve"
    width={width}
    fill={color}
  >
    <g>
      <g>
        <path
          d="M10.7783203,24.7783203c-0.2558594,0-0.5117188-0.0976563-0.7070313-0.2929688l-7.7783203-7.7783203
            c-0.390625-0.390625-0.390625-1.0234375,0-1.4140625l7.7783203-7.7783203c0.390625-0.390625,1.0234375-0.390625,1.4140625,0
            s0.390625,1.0234375,0,1.4140625L4.4140625,16l7.0712891,7.0712891c0.390625,0.390625,0.390625,1.0234375,0,1.4140625
            C11.2900391,24.6806641,11.0341797,24.7783203,10.7783203,24.7783203z"
        />
      </g>
      <g>
        <path d="M29,17H3c-0.5522461,0-1-0.4477539-1-1s0.4477539-1,1-1h26c0.5522461,0,1,0.4477539,1,1S29.5522461,17,29,17z" />
      </g>
    </g>
  </svg>
);

export default ArrowLeft;
