import React from 'react';

const ArrowRight = ({color, width}) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 32 32"
    style={{ enableBackground: 'new 0 0 32 32' }}
    xmlSpace="preserve"
    fill={color}
    width={width} 
  >
    <g>
      <g>
        <path
          d="M21.2216797,24.7783203c-0.2558594,0-0.5117188-0.0976563-0.7070313-0.2929688
            c-0.390625-0.390625-0.390625-1.0234375,0-1.4140625L27.5859375,16l-7.0712891-7.0712891
            c-0.390625-0.390625-0.390625-1.0234375,0-1.4140625s1.0234375-0.390625,1.4140625,0l7.7783203,7.7783203
            c0.390625,0.390625,0.390625,1.0234375,0,1.4140625l-7.7783203,7.7783203
            C21.7333984,24.6806641,21.4775391,24.7783203,21.2216797,24.7783203z"
        />
      </g>
      <g>
        <path d="M29,17H3c-0.5527344,0-1-0.4477539-1-1s0.4472656-1,1-1h26c0.5527344,0,1,0.4477539,1,1S29.5527344,17,29,17z" />
      </g>
    </g>
  </svg>
);

export default ArrowRight;
