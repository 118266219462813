import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setSearchQuery,
  toggleSearchComponent,
} from "../../features/searchSlice";

const Brand = ({ cardData, name }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className={`text-kitsaWhite bg-transparent`}>
      <div className="flex items-center justify-between mb-6 px-4">
        <h5 className="text-sm font-bold">{name}</h5>
        <div className="flex items-center eta-shadow text-kitsaWhite gap-2 px-[5px] py-[2px]">
          <p className="text-[11px] tracking-[-0.28px] font-bold leading-none">
            Sponsored
          </p>
        </div>
      </div>
      <Swiper
        modules={[Navigation, Autoplay]}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        loop={false}
        className="lg:w-[30vw] text-center"
      >
        {cardData?.data.map((result, index) => (
          <SwiperSlide key={index}>
            <div
              onClick={() => {
                navigate(`?search=search`);
                dispatch(setSearchQuery(result.search_query));
                dispatch(toggleSearchComponent());
              }}
              className="flex items-center overflow-x-scroll gap-4 pb-5 scrollbar-hide px-4 lg:max-w-[380px] 2xl:max-w-[430px]"
            >
              <div
                // style={{ backgroundImage: `url('${result.image}')` }}
                className="relative w-full shrink-0 border border-[#666666] h-[42dvh] rounded-[10px] shadow-peachBox bg-cover bg-no-repeat object-cover"
              >
                <button className="absolute -bottom-2 -right-2 place-self-end text-formisBlack font-[MestizaSans] font-bold text-[11px] bg-kitsaWhite h-[28px] flex items-center justify-center px-4 rounded-[8px] shadow-md shadow-kitsaWhite">
                  SHOP NOW
                </button>

                <img
                  src={result.image}
                  alt={result.search_query}
                  className="w-full h-full rounded-[inherit] object-cover"
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Brand;
