import { useDispatch, useSelector } from "react-redux";
import etaImg from "../../assets/icons/eta.svg";
import imageNA from "../../assets/Image-NA.webp";

import { FaMinus, FaPlus } from "react-icons/fa6";

import discount from "../../assets/icons/discount.svg";
import {
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../../features/cartSlice";
import { setShowComingSoon } from "../../features/slidersSlice";

import Minus from "../../assets/icon components/Minus";
import Plus from "../../assets/icon components/Plus";

const CartProductsLocal = () => {
  const { eta } = useSelector((state) => state.googleMapLocation);
  const { items } = useSelector((state) => state.cartItems);

  return (
    <div className="text-kitsaWhite shadow-peachBox rounded-[20px] mx-3 px-2 py-4">
      <div className="flex items-center justify-between mb-6">
        <h5 className="text-sm font-bold">
          Shipment of {items.length} essentials
        </h5>
        <div className="flex items-center border border-kitsaWhite gap-2 px-[5px] py-[2px] rounded-full">
          <p className="text-[11px] tracking-[-0.28px] font-bold leading-none">
            {eta.eta_minutes} mins
          </p>
          <img src={etaImg} alt="ETA" />
        </div>
      </div>

      <div className="flex flex-col gap-3">
        {items.map((item) => (
          <ProductCard item={item} key={item.product_id} />
        ))}
      </div>
    </div>
  );
};

export default CartProductsLocal;

const ProductCard = ({ item }) => {
  const dispatch = useDispatch();

  const cartItem = useSelector((state) =>
    state.cartItems.items.find(
      (cartItem) => cartItem.product_id === item.product_id
    )
  );

  const handleDecrement = () => {
    if (cartItem?.quantity === 1) dispatch(removeFromCart(cartItem));
    if (cartItem?.quantity > 1) {
      dispatch(decrementQuantity({ product_id: item.product_id }));
    }
  };
  return (
    <div className="flex gap-2 justify-between">
      <div className="flex gap-3">
        <div className="p-[5px] relative shrink-0 h-[77px] w-[78px]">
          <img
            src={
              item.image_url !== "NA" &&
              item.image_url !==
                "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
                ? item.image_url
                : "https://media-hosting.imagekit.io//a3385a43077f4e0e/Image%20Not%20Available.png?Expires=1833358862&Key-Pair-Id=K2ZIVPTIP2VGHC&Signature=h-gValwZSoRKUBi9iEHAzKOKdwPlgYLNYSi5K7sAEH2knqMt4gpgaf~GJytsoI9gQemKj-5mIaJ4SPminI9Ts4j4esZ4j-udW3LgKjxS5Vn7goq6Z4bhS~5hzKy4uzSjttW1KAoemJvrM3em5fiBlImWlXQ6lOkyt79rhvtE11KwbRFH2w2pbdXPemroPpZxgHLRAAJ6EEsWL-psJxPosRF6LZz5giAWuUNGeem~P0IieT38Qlzvb6~nyJ96og4-XODPBV46s6vk3amWOa20pBrPSDP0X6a7Tjwpo9vunyMapTz9U0ucJqtk~c6yn9kD-YcgMIlQtPJmOG8AbIdtSA__"
            }
            loading="lazy"
            alt={item.name}
            className="aspect-square rounded-[10px]"
          />
          <div className="h-[23px] w-[19px] absolute -top-[7px] -left-[1px]">
            <img src={discount} alt="discount!" className="" />
            <div className="absolute -top-1 -left-[2.5px] h-[28px] w-[23px] flex items-center justify-center">
              <p className="text-[8px] text-formisBlack font-bold leading-none">
                {parseInt(item.discount_percentage)}%
                <br /> OFF
              </p>
            </div>
          </div>
        </div>

        <div className="shrink">
          <p className="text-xs font-medium mb-1">
            {item?.name.length > 42
              ? item.name.slice(0, 42) + "..."
              : item.name}
          </p>
          <button
            onClick={() => dispatch(setShowComingSoon(true))}
            className="text-[10px] font-bold text-kitsaGreen border-b border-kitsaGreen leading-none"
          >
            Add to Auto Refill Schedule
          </button>
        </div>
      </div>

      <div className="">
        <div className="bg-kitsaGreen text-kitsaWhite flex items-center w-fit place-self-end rounded-[8px] py-[2px] mb-2">
          <button className="w-[20px] pl-2" onClick={handleDecrement}>
            <Minus width="15" color="#f1edeb" />
          </button>
          <input
            value={cartItem?.quantity || 0}
            className="text-sm font-bold bg-transparent outline-none w-[40px] text-center"
            readOnly
          />
          <button
            className="w-[20px] pr-2"
            onClick={() =>
              dispatch(incrementQuantity({ product_id: item.product_id }))
            }
          >
            <Plus width="15" color="#f1edeb" />
          </button>
        </div>

        <div className="flex items-center gap-1 font-bold place-self-end">
          <p className="text-[10px] border-r pr-[6px]">
            ₹{(item.final_price * item.quantity).toFixed(2)}
          </p>
          <span className="text-[10px]">
            <s>₹{(item.mrp * item.quantity).toFixed(2)}</s>
          </span>
        </div>
      </div>
    </div>
  );
};
