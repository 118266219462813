import { useEffect, useState } from "react";
import close from "../../assets/icons/close.svg";
import { addressesApi } from "../../api";
import EditAddressMap from "./EditAddressMap";

const EditAddress = ({ setEditAddress, address }) => {
  const [addressType, setAddressType] = useState(address.name);
  const [flatNo, setFlatNo] = useState(address.address_line_1);
  const [address2, setAddress2] = useState(address.address_line_2);
  const [city, setCity] = useState(address.city);
  const [state, setState] = useState(address.state);
  const [contactName, setContactName] = useState(address.contact_name);
  const [phoneNumber, setPhoneNumber] = useState(address.contact_number);
  const [landmark, setLandmark] = useState(address.nearby_landmark);
  const [editLat, setEditLat] = useState(address.latitude);
  const [editLng, setEditLng] = useState(address.longitude);

  const handleEditAddress = async (e) => {
    e.preventDefault();
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(addressesApi + `/${address.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          address_person: address.address_person,
          name: addressType,
          address_line_1: flatNo,
          address_line_2: address2,
          city,
          state,
          latitude: parseFloat(editLat.toFixed(6)),
          longitude: parseFloat(editLng.toFixed(6)),
          postal_code: address.postal_code,
          nearby_landmark: landmark,
          contact_name: contactName,
          contact_number: phoneNumber,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // console.log("SAVED", data)
      } else if (response.status === 401) {
        localStorage.setItem("isAuthenticated", false);
      } else {
        // console.log("Error:", data);
      }
    } catch (error) {
      // console.log("edit request Failed", error);
    }
  };

  return (
    <div className="bg-kitsaWhite h-full w-full">
      <div
        className={`bg-kitsaWhite px-4 w-full flex items-end justify-between h-[83px] pb-3`}
        onClick={() => setEditAddress(false)}
      >
        <h6 className="text-xs font-bold">Cancel</h6>
        <img src={close} alt="Close" />
      </div>

      <form
        onClick={handleEditAddress}
        className="bg-gradient-to-b from-[#203040] via-[#203040] to-[#15171c] min-h-[calc(100%-83px)] text-kitsaWhite py-7 px-4 rounded-t-[30px]"
      >
        <div>
          <div className="mb-5">
            <p className="text-xs font-bold mb-3">Address Name</p>
            <input
              onChange={(e) => setAddressType(e.target.value)}
              required
              value={addressType}
              className="bg-formisWhite w-full outline-none text-formisBlack text-xs font-bold h-11 px-5 rounded-[10px]"
            />
          </div>
        </div>
        <div>
          <div className="mb-5">
            <p className="text-xs font-bold mb-3">
              Flat/ House No./ Building Name
            </p>
            <input
              onChange={(e) => setFlatNo(e.target.value)}
              required
              value={flatNo}
              className="bg-formisWhite w-full outline-none text-formisBlack text-xs font-bold h-11 px-5 rounded-[10px]"
            />
          </div>
        </div>

        <EditAddressMap
          editLat={editLat}
          editLng={editLng}
          setEditLat={setEditLat}
          setEditLng={setEditLng}
        />

        <div>
          <div className="mb-5">
            <p className="text-xs font-bold mb-3">Address</p>
            <input
              onChange={(e) => setAddress2(e.target.value)}
              required
              value={address2}
              className="bg-formisWhite w-full outline-none text-formisBlack text-xs font-bold h-11 px-5 rounded-[10px]"
            />
          </div>
        </div>
        <div>
          <div className="mb-5">
            <p className="text-xs font-bold mb-3">City</p>
            <input
              onChange={(e) => setCity(e.target.value)}
              required
              value={city}
              className="bg-formisWhite w-full outline-none text-formisBlack text-xs font-bold h-11 px-5 rounded-[10px]"
            />
          </div>
        </div>
        <div>
          <div className="mb-5">
            <p className="text-xs font-bold mb-3">State</p>
            <input
              onChange={(e) => setState(e.target.value)}
              required
              value={state}
              className="bg-formisWhite w-full outline-none text-formisBlack text-xs font-bold h-11 px-5 rounded-[10px]"
            />
          </div>
        </div>
        <div>
          <div className="mb-5">
            <p className="text-xs font-bold mb-3">Nearby Landmark</p>
            <input
              onChange={(e) => setLandmark(e.target.value)}
              required
              value={landmark || ""}
              className="bg-formisWhite w-full outline-none text-formisBlack text-xs font-bold h-11 px-5 rounded-[10px]"
            />
          </div>
        </div>
        <div>
          <div className="mb-5">
            <p className="text-xs font-bold mb-3">Contact Name</p>
            <input
              onChange={(e) => setContactName(e.target.value)}
              required
              value={contactName}
              className="bg-formisWhite w-full outline-none text-formisBlack text-xs font-bold h-11 px-5 rounded-[10px]"
            />
          </div>
        </div>
        <div>
          <div className="mb-5">
            <p className="text-xs font-bold mb-3">Contact Number</p>
            <input
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              value={phoneNumber}
              className="bg-formisWhite w-full outline-none text-formisBlack text-xs font-bold h-11 px-5 rounded-[10px]"
            />
          </div>
        </div>

        <button
          type="submit"
          className="bg-kitsaWhite text-formisBlack rounded-[10px] py-3 w-full font-extrabold tracking-[-0.4px]"
        >
          Save Address
        </button>
      </form>
    </div>
  );
};

export default EditAddress;
