import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCoordinates } from "../features/mapSlice";
import customPin from "../assets/icons/pin.svg";
import Lottie from "lottie-react";
import animatedPin from "../assets/map-pin.json";

const GoogleMap = () => {
  const google = window.google;

  const [mapLoaded, setMapLoaded] = useState(false);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const mapInstance = useRef(null);

  const { coordinates } = useSelector((state) => state.googleMapLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkGoogleMaps = setInterval(() => {
      if (window.google && window.google.maps) {
        setMapLoaded(true);
        clearInterval(checkGoogleMaps);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    const initMap = () => {
      if (mapLoaded && mapRef.current) {
        const mapOptions = {
          center: coordinates || { lat: 28.48218, lng: 77.0933 },
          zoom: 16,
          disableDefaultUI: true,
          gestureHandling: "greedy",
        };

        mapInstance.current = new google.maps.Map(mapRef.current, mapOptions);

        markerRef.current = new google.maps.Marker({
          position: mapOptions.center,
          map: mapInstance.current,
          icon: {
            url: customPin,
            scaledSize: new google.maps.Size(0, 0),
          },
        });
        mapInstance.current.addListener("drag", () => {
          if (markerRef.current) {
            const center = mapInstance.current.getCenter();
            markerRef.current.setPosition(center);
          }
        });
        mapInstance.current.addListener("idle", () => {
          const center = mapInstance.current.getCenter();
          const lat = center.lat();
          const lng = center.lng();

          dispatch(
            setCoordinates({
              lat: lat,
              lng: lng,
            })
          );
        });
      }
    };

    if (window.google && window.google.maps) {
      initMap();
    } else {
      window.addEventListener("load", initMap);
    }

    return () => {
      window.removeEventListener("load", initMap);
    };
  }, [mapLoaded, mapRef, dispatch]);

  useEffect(() => {
    if (coordinates && mapInstance.current) {
      mapInstance.current.panTo(coordinates);
      if (markerRef.current) {
        markerRef.current.setPosition(coordinates);
      }
    }
  }, [coordinates, window.google]);

  return (
    <div className="relative h-[75dvh] w-full rounded-[inherit]">
      <div className="absolute top-0 left-0 z-10 flex items-center justify-center pointer-events-none h-full w-full">
        <div className="w-[3.25rem]">
          <Lottie animationData={animatedPin} speed={0.5} />
        </div>
      </div>
      
      <div
        id="map"
        ref={mapRef}
        className="rounded-[inherit] h-[75dvh] w-full"
      ></div>
    </div>
  );
};

export default GoogleMap;