import { useDispatch, useSelector } from "react-redux";
import closePeach from "../assets/icons/close-peach.svg";
import { setShowComingSoon } from "../features/slidersSlice";

const ComingSoon = () => {
  const dispatch = useDispatch();
  const { showComingSoon } = useSelector((state) => state.sliders);
  return (
    <div
      className={`${
        showComingSoon ? "opacity-100" : "opacity-0 -z-50 pointer-events-none"
      } transition-opacity duration-300 fixed z-[31] text-kitsaWhite top-0 left-0 gradient-bg to-formisBlack h-full w-full flex flex-col justify-end p-3`}
    >
      <img
        onClick={() => dispatch(setShowComingSoon(false))}
        src={closePeach}
        alt="Close"
        className="w-14 mx-auto mb-[42px]"
      />
      <div className="h-[364.5px] w-full bg-formisBlack rounded-[20px] shadow-peachBox px-2 pt-6 pb-3">
        <p className="text-xs font-bold mb-2">Coming Soon</p>
        <div className="font-[MestizaSans] text-[10px] font-medium space-y-4">
          <p>
            We are working hard to get these features alongside best offers for
            you as soon as possible.
          </p>
          <p>
            We’re hustling behind the scenes to roll out these features (and
            snag the best offers) for you—stat!
          </p>
          <p>
            Think of this as a power-up toward our mission: making life’s
            essentials show up at your door before you even think, “uh-oh, need
            that.”
          </p>
          <p>
            Next up? Emergency services, at-home care, doorstep diagnostics, and
            more—all wrapped into our patent-pending FORMIS® forLife Smart Pass.
          </p>
          <p>
            The best part? You’ll enjoy all this goodness at nearly zero cost.
          </p>
          <p>Because proactive living should never break the bank.</p>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
