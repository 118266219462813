import React from "react";

const DFewClouds = ({color, width}) => {
  return (
    <svg id="Glyph" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill={color} width={width}>
      <path d="M27.78,20.48c1.04-.32,2.12-.48,3.22-.48,4.18,0,7.94,2.36,9.79,6,.07,0,.14,0,.21,0,3.19,0,6.16,1.52,8.03,4.04,.32-.03,.65-.04,.97-.04,2.08,0,4.05,.54,5.75,1.47,.15-.58,.25-1.18,.25-1.8,0-2.39-1.21-4.5-3.06-5.74,.22-.81,.35-1.65,.35-2.53,0-5.19-4.21-9.41-9.41-9.41-2.11,0-4.06,.71-5.63,1.88-1.4-.7-2.97-1.1-4.64-1.1-3.03,0-5.75,1.3-7.65,3.37-1.9,.01-3.62,.74-4.95,1.91,2.41,.22,4.75,1.05,6.75,2.42Z" />
      <path d="M50,52c5.52,0,10-4.48,10-10s-4.48-10-10-10c-.67,0-1.33,.07-1.96,.2-1.35-2.5-4-4.2-7.04-4.2-.5,0-.99,.05-1.47,.14-1.2-3.57-4.56-6.14-8.53-6.14-1.26,0-2.46,.26-3.54,.73-2.1-1.7-4.78-2.73-7.7-2.73-6.76,0-12.24,5.48-12.24,12.24,0,1.14,.17,2.24,.46,3.29-2.4,1.62-3.98,4.36-3.98,7.47,0,4.97,4.03,9,9,9H50Z" />
    </svg>
  );
};

export default DFewClouds;
