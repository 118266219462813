import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setSearchQuery,
  toggleSearchComponent,
} from "../../features/searchSlice";

const MustTry = ({ productData, name }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className={`text-kitsaWhite bg-transparent`}>
      <div className="flex items-center justify-between mb-6 px-4">
        <h5 className="text-sm font-bold">{name}</h5>
        <div className="flex items-center eta-shadow text-kitsaWhite gap-2 px-[5px] py-[2px]">
          <p className="text-[11px] tracking-[-0.28px] font-bold leading-none">
            Sponsored
          </p>
        </div>
      </div>

      <div className="flex items-center overflow-x-scroll gap-4 pb-5 scrollbar-hide px-4">
        {productData?.data.map((result, index) => (
          <div
            onClick={() => {
              navigate(`?search=search`);
              dispatch(setSearchQuery(result.search_query));
              dispatch(toggleSearchComponent());
            }}
            className="relative w-[50%] shrink-0 border border-[#666666] h-[200px] rounded-[10px] shadow-peachBox bg-cover bg-no-repeat object-cover"
            key={index}
          >
            <button className="absolute -bottom-2 -right-2 place-self-end text-formisBlack font-[MestizaSans] font-bold text-[11px] bg-kitsaWhite h-[28px] flex items-center justify-center px-4 rounded-[8px] shadow-md shadow-kitsaWhite">
              SHOP NOW
            </button>

            <img
              src={result.image}
              alt=""
              className="w-full h-full rounded-[inherit] object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MustTry;
