import React from "react";

const PinIcon = ({ color, width }) => {
  return (
    <svg
      version="1.1"
      id="Glyph"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
      xmlSpace="preserve"
      fill={color}
      width={width}
    >
      <g>
        <path
          d="M7.76001,47.96997c0,0,24,6,24.00002,6c0.15861,0.03968,0.32128,0.03967,0.47996,0c0.00001,0,24-6,24-6
		C56.69,47.85999,57,47.45996,57,47c0-0.46002-0.31-0.85999-0.76001-0.97003L37,41.21997v0.95001
		c1.15997,0.40997,2,1.53003,2,2.83002c0,1.64996-1.34998,3-3,3h-8c-1.65002,0-3-1.35004-3-3c0-1.29999,0.84003-2.42004,2-2.83002
		v-0.95001l-19.23999,4.81C7.31,46.14001,7,46.53998,7,47C7,47.45996,7.31,47.85999,7.76001,47.96997z"
        />
        <path
          d="M32.72998,55.90997c-0.47811,0.11953-0.98186,0.11953-1.45996,0l-24-6C7.17999,49.89001,7.09003,49.85999,7,49.81V51
		c0,0.45996,0.31,0.85999,0.76001,0.96997c0,0,24,6,24.00002,6c0.15838,0.03962,0.32162,0.03958,0.47996,0c0.00001,0,24-6,24-6
		C56.69,51.85999,57,51.45996,57,51v-1.19c-0.09003,0.04999-0.17999,0.08002-0.27002,0.09998
		C56.72998,49.90997,32.74018,55.90742,32.72998,55.90997z"
        />
        <path
          d="M32,31c6.61719,0,12-5.3833,12-12S38.61719,7,32,7s-12,5.3833-12,12S25.38281,31,32,31z M32,11c4.41113,0,8,3.58887,8,8
		c0,0.55225-0.44727,1-1,1s-1-0.44775-1-1c0-3.30859-2.69141-6-6-6c-0.55273,0-1-0.44775-1-1S31.44727,11,32,11z"
        />
        <path
          d="M32,33c-1.03003,0-2.03003-0.10999-3-0.32001V44h-1c-0.54999,0-1,0.45001-1,1s0.45001,1,1,1h8c0.54999,0,1-0.45001,1-1
		s-0.45001-1-1-1h-1V32.67999C34.03003,32.89001,33.03003,33,32,33z"
        />
      </g>
    </svg>
  );
};

export default PinIcon;
