import React from "react";

const NFewClouds = ({color, width}) => {
  return (
    <svg id="Glyph" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill={color} width={width}>
      <path d="M55.01,35c-9.39,0-17-7.61-17-17,0-3.74,1.22-7.18,3.26-9.99-.09,0-.17-.01-.26-.01-10.74,0-19.48,8.47-19.95,19.09,1.19,.35,2.33,.88,3.38,1.58,.88-.26,1.79-.38,2.71-.38,3.63,0,6.9,2.01,8.57,5.14h0c2.78,0,5.36,1.29,7.02,3.45,.23-.02,.47-.02,.7-.02,4.53,0,8.39,2.87,9.89,6.88,3.07-2.41,5.43-5.69,6.68-9.48-1.58,.48-3.25,.75-4.99,.75Z" />
      <path d="M43.43,56c4.73,0,8.57-3.84,8.57-8.57s-3.84-8.57-8.57-8.57c-.58,0-1.14,.06-1.68,.17-1.16-2.14-3.43-3.6-6.03-3.6-.43,0-.85,.04-1.26,.12-1.02-3.06-3.91-5.26-7.31-5.26-1.08,0-2.11,.22-3.04,.62-1.8-1.46-4.1-2.34-6.6-2.34-5.79,0-10.49,4.7-10.49,10.49,0,.98,.14,1.92,.39,2.82-2.06,1.39-3.41,3.74-3.41,6.4,0,4.26,3.45,7.71,7.71,7.71h31.71Z" />
    </svg>
  );
};

export default NFewClouds;
