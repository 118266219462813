import React from "react";

const Thunderstorm = ({ color, width }) => {
  return (
    <svg
      id="Glyph"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
    >
      <path d="M57.37,14.26c.19-.69,.3-1.42,.3-2.17,0-4.47-3.62-8.09-8.09-8.09-1.82,0-3.49,.61-4.84,1.62-1.2-.6-2.55-.95-3.99-.95-2.6,0-4.94,1.12-6.57,2.89-1.44,.01-2.76,.49-3.84,1.28,2.28,.8,4.21,2.41,5.36,4.58h0c2.78,0,5.36,1.29,7.02,3.45,.23-.02,.47-.02,.7-.02,5.04,0,9.27,3.55,10.32,8.29h.31c3.28,0,5.95-2.66,5.95-5.95,0-2.06-1.04-3.87-2.63-4.94Z" />
      <polygon points="12.92 43.41 20.8 46.25 15.19 60 31.27 42.49 24.29 39.76 27.81 27.95 12.92 43.41" />
      <path d="M43.43,18.86c-.58,0-1.14,.06-1.68,.17-1.16-2.14-3.43-3.6-6.03-3.6-.43,0-.85,.04-1.26,.12-1.02-3.06-3.91-5.26-7.31-5.26-1.08,0-2.11,.22-3.04,.62-1.8-1.46-4.1-2.34-6.6-2.34-5.79,0-10.49,4.7-10.49,10.49,0,.98,.14,1.92,.39,2.82-2.06,1.39-3.41,3.74-3.41,6.4,0,4.26,3.45,7.71,7.71,7.71h5.57l9.08-9.44c.39-.4,.91-.61,1.44-.61,.34,0,.69,.09,1.01,.27,.8,.46,1.17,1.41,.91,2.3l-2.23,7.48h4.95c0-.51,.19-1.01,.55-1.39l9.88-10.26c.39-.4,.91-.61,1.44-.61,.34,0,.69,.09,1.01,.27,.8,.46,1.17,1.41,.91,2.3l-1.81,6.08,2.92,1.14c.44,.17,.8,.5,1.02,.9,2.19-1.55,3.63-4.1,3.63-6.99,0-4.73-3.84-8.57-8.57-8.57Z" />
      <polygon points="34.45 36 39.67 37.88 35.95 47.01 46.63 35.38 41.99 33.57 44.32 25.73 34.45 36" />
    </svg>
  );
};

export default Thunderstorm;
