import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCartDetailsApi } from "../api";
import { setUserCartItems } from "../features/apiCartSlice";

const useCartDetails = () => {
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  const getCartDetails = async () => {
    const access = localStorage.getItem("accessToken");

    try {
      const response = await fetch(getCartDetailsApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.setItem("isAuthenticated", false);
        }
      }

      const data = await response.json();
      dispatch(setUserCartItems(data));
    } catch (error) {}
  };

  useEffect(() => {
    if (isAuthenticated === "true") {
      // console.log("cart details called")
      getCartDetails();
    }
  }, []);

  return { getCartDetails };
};

export default useCartDetails;