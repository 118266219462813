import { setApproval, setUserCartItems } from "../../features/apiCartSlice";
import { Link, useNavigate } from "react-router-dom";
import home from "../../assets/icons/home-icon.svg";
import {
  setAddressStep,
  toggleIsAddressSliderOpen,
} from "../../features/mapSlice";
import { toast } from "sonner";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { placeOrderApi } from "../../api";
import BackIcon from "../../assets/icon components/Back";

const PlaceOrderButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const { items } = useSelector((state) => state.cartItems);
  const userCart = useSelector((state) => state.apiCart.userCartItems);
  const { addressId } = useSelector((state) => state.googleMapLocation);
  const [loading, setLoading] = useState(false);

  const handlePlaceOrder = async (e) => {
    setLoading(true);
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(placeOrderApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          address_id: addressId,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        // console.log("place order api data", data);
        localStorage.setItem("orderCode", data.fast_pass_order_code);
        dispatch(setApproval(true));
        // navigate("/track-order");
        dispatch(setUserCartItems([]));
      } else {
        toast.error(
          "Something went wrong while placing your order. Please try again after some time."
        );
      }
    } catch (error) {
      toast.error(
        "Something went wrong while placing your order. Please try again after some time."
      );
      console.error("error in placing order", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isAuthenticated === "true" ? (
        <div className="relative flex items-center justify-center gap-3 mb-3">
          {addressId ? (
            <div
              className={`relative flex items-center gap-2 justify-center text-kitsaWhite`}
              onClick={handlePlaceOrder}
            >
              <div className="bg-kitsaGreen w-fit px-2 py-2 h-10 rounded-full flex items-center gap-3 shadow-md shadow-formisBlack">
                <div className="size-7 border border-kitsaWhite rounded-full flex items-center justify-center font-[MestizaSans] text-[11px] font-bold">
                  {isAuthenticated === "true" ? userCart.length : items.length}
                </div>
                <div className="text-xs font-bold">
                  {loading ? (
                    <div className="loader4"></div>
                  ) : (
                    "Place your order"
                  )}
                </div>
                <div className="rotate-180">
                  <BackIcon width={18} color="#f1edeb" />
                </div>
              </div>
            </div>
          ) : (
            <div
              className={`relative flex items-center gap-2 justify-center text-kitsaWhite `}
              onClick={() => {
                dispatch(setAddressStep(2));
                dispatch(toggleIsAddressSliderOpen(true));
              }}
            >
              <div className="bg-kitsaGreen w-fit p-[0.65rem] h-10 rounded-full flex items-center gap-3 shadow-md shadow-formisBlack">
                <div className="text-xs font-bold">Select Address</div>
                <div className="rotate-180">
                  <BackIcon width={18} color="#f1edeb" />
                </div>
              </div>
            </div>
          )}

          <Link
            to="/"
            className="bg-kitsaGreen h-full border border-kitsaWhite p-[9px] rounded-full"
          >
            <img src={home} alt="Home" />
          </Link>
        </div>
      ) : (
        <div className="relative flex items-center justify-center gap-3 mb-3">
          <Link
            to="/login"
            className={`relative flex items-center gap-2 justify-center text-kitsaWhite`}
          >
            <div className="bg-kitsaGreen w-fit px-2 pl-3 py-3 h-10 rounded-full flex items-center gap-3 shadow-md shadow-formisBlack">
              <div className="text-xs font-bold">Login to place order</div>
              <div className="rotate-180">
                <BackIcon width={18} color="#f1edeb" />
              </div>
            </div>
          </Link>

          <Link
            to="/"
            className="bg-kitsaGreen h-full border border-kitsaWhite p-[9px] rounded-full"
          >
            <img src={home} alt="Home" />
          </Link>
        </div>
      )}
    </>
  );
};

export default PlaceOrderButton;
