import { useEffect, useState } from "react";
import close from "../../assets/icons/close.svg";
import { PiMapPinLineFill } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { addressesApi } from "../../api";
import {
  setAddressDetails,
  setAddressStep,
  toggleIsAddressSliderOpen,
} from "../../features/mapSlice";
import { toast } from "sonner";

const RemainingDetails = ({ setFillRemainingDetails }) => {
  const dispatch = useDispatch();

  const [flatNo, setFlatNo] = useState();
  const [orderingFor, setOrderingFor] = useState("MYSELF");
  const [addressTypeInput, setAddressType] = useState("Home");
  const [contactName, setContactName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [landmark, setLandmark] = useState("");
  const [message, setMessage] = useState("");
  const [isDefault, setIsDefault] = useState("false");

  const [showAddressSection, setShowAddressSection] = useState(true);
  const [customAddressType, setCustomAddressType] = useState("");
  const { coordinates, address, address2, first, state, city, pinCode } =
    useSelector((state) => state.googleMapLocation);

  const handleOrderingForChange = (value) => {
    if (value === "MYSELF") {
      setShowAddressSection(true);
    } else {
      setShowAddressSection(false);
    }
    setOrderingFor(value);
  };

  function generateRandomString() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const length = Math.floor(Math.random() * 2) + 7;
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }

    return result;
  }

  const handleAddAddress = async (e) => {
    e.preventDefault();
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(addressesApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          address_person: orderingFor,
          name:
            orderingFor === "MYSELF"
              ? addressTypeInput
              : generateRandomString(),
          address_line_1: flatNo,
          address_line_2: address2,
          city: city,
          state: state,
          is_default: isDefault === "false" ? false : true,
          latitude: parseFloat(coordinates.lat.toFixed(6)),
          longitude: parseFloat(coordinates.lng.toFixed(6)),
          postal_code: pinCode,
          nearby_landmark: landmark,
          contact_name: contactName,
          contact_number: phoneNumber,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // console.log("address saved", data);
        dispatch(
          setAddressDetails({
            address: flatNo,
            address2,
            city,
            state,
            pinCode,
            addressPerson: orderingFor || "MYSELF",
            addressType:
              orderingFor === "MYSELF"
                ? addressTypeInput
                : generateRandomString(),
            nearbyLandmark: landmark,
            contactName: contactName,
            contactNumber: phoneNumber,
            addressId: data.id,
          })
        );
        dispatch(setAddressStep(null));
        dispatch(toggleIsAddressSliderOpen());
        setFillRemainingDetails(false);
      } else if (response.status === 401) {
        localStorage.setItem("isAuthenticated", false);
        dispatch(
          setAddressDetails({
            address: flatNo,
            address2,
            city,
            state,
            pinCode,
            addressPerson: orderingFor || "MYSELF",
            addressType:
              orderingFor === "MYSELF"
                ? addressTypeInput
                : generateRandomString(),
            nearbyLandmark: landmark,
            contactName: contactName,
            contactNumber: phoneNumber,
            addressId: data.id,
          })
        );
        dispatch(setAddressStep(null));
        dispatch(toggleIsAddressSliderOpen());
      } else {
        // console.log("Error:", data);
        setMessage(data);
      }
    } catch (error) {
      // console.log("Request Failed", error);
    }
  };

  return (
    <div
      className={`h-fit mb-10 ${
        addressTypeInput === "Other" ? "bg-formisWhite" : "bg-kitsaWhite"
      } transition-colors`}
    >
      <div
        className={`${
          addressTypeInput === "Other" ? "bg-formisWhite" : "bg-kitsaWhite"
        } transition-colors px-4 w-full flex items-end justify-between h-[83px] pb-3`}
      >
        {addressTypeInput === "Other" ? (
          <h6 className="text-xs font-bold">Give a label to your address</h6>
        ) : (
          <h6 className="text-xs font-bold">Add more address Details</h6>
        )}
        <img
          src={close}
          alt="Close"
          onClick={() => {
            if (addressTypeInput === "Other") {
              setCustomAddressType("");
              setAddressType("Home");
              return;
            }
            setFillRemainingDetails(false);
          }}
        />
      </div>

      <div className="text-kitsaWhite px-6 pt-7 rounded-t-[30px] gradient-bg h-[calc(100%-83px)]">
        {/* "Who are you ordering for?" Section */}
        <div>
          <p className="text-xs font-bold mb-3">Who are you ordering for?</p>
          <div className="flex gap-4">
            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="orderingFor"
                value="MYSELF"
                checked={orderingFor === "MYSELF"}
                onChange={(e) => handleOrderingForChange(e.target.value)}
                className="hidden peer"
              />
              <div className="w-4 h-4 border border-kitsaGreen rounded-full peer-checked:bg-kitsaGreen"></div>
              <span className="text-xs font-bold">Myself</span>
            </label>

            <label className="flex items-center gap-2">
              <input
                type="radio"
                name="orderingFor"
                value="SOMEONE ELSE"
                checked={orderingFor === "SOMEONE ELSE"}
                onChange={(e) => handleOrderingForChange(e.target.value)}
                className="hidden peer"
              />
              <div className="w-4 h-4 border border-kitsaGreen rounded-full peer-checked:bg-kitsaGreen"></div>
              <span className="text-xs font-bold">Someone Else</span>
            </label>
          </div>
        </div>

        {/* "Save address as" Section */}
        <div
          className={`my-3 transition-all duration-300 ${
            showAddressSection
              ? "opacity-100 max-h-28"
              : "opacity-0 max-h-0 pointer-events-none"
          }`}
        >
          <p className="text-xs font-bold mb-3">Save address as *</p>
          <div className="flex gap-4 flex-wrap">
            {/* Render the radio buttons only if the addressType is not "Other" */}
            {!customAddressType &&
              ["Home", "Work", "Hotel"].map((type) => (
                <label key={type} className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="addressType"
                    value={type}
                    checked={addressTypeInput === type}
                    onChange={(e) => setAddressType(e.target.value)}
                    className="hidden peer"
                  />
                  <div className="w-4 h-4 border border-kitsaGreen rounded-full peer-checked:bg-kitsaGreen"></div>
                  <span className="text-xs font-bold">{type}</span>
                </label>
              ))}

            {/* Render the "Other" option */}
            <label
              className={`flex items-center gap-2 ${
                customAddressType && "w-full"
              }`}
            >
              <input
                type="radio"
                name="addressType"
                value="Other"
                checked={addressTypeInput === "Other" || customAddressType}
                onChange={(e) => setAddressType(e.target.value)}
                className="hidden peer"
              />
              <div className="w-4 h-4 border border-kitsaGreen rounded-full peer-checked:bg-kitsaGreen"></div>
              <span className="text-xs font-bold">Other</span>
              {customAddressType && (
                <div className="w-full flex border-b ml-2">
                  <input
                    value={addressTypeInput}
                    readOnly
                    className="bg-transparent w-full border-formisWhite"
                  />
                  <button
                    onClick={() => setAddressType("Other")}
                    className="relative bottom-1 text-[10px] font-bold bg-kitsaGreen text-formisBlack px-3 py-[2px] rounded-[10px]"
                  >
                    Change
                  </button>
                </div>
              )}
            </label>
          </div>

          <div className="my-3">
            <p className="text-xs font-bold mb-3">Set as default address?</p>
            <div className="flex gap-4">
              <label className="flex items-center gap-2">
                <input
                  type="radio"
                  name="isDefault"
                  value={true}
                  checked={isDefault === "true"}
                  className="hidden peer"
                  onChange={(e) => setIsDefault(e.target.value)}
                />
                <div className="w-4 h-4 border border-kitsaGreen rounded-full peer-checked:bg-kitsaGreen"></div>
                <span className="text-xs font-bold">Yes</span>
              </label>
              <label className="flex items-center gap-2">
                <input
                  type="radio"
                  name="isDefault"
                  value={false}
                  checked={isDefault === "false"}
                  className="hidden peer"
                  onChange={(e) => setIsDefault(e.target.value)}
                />
                <div className="w-4 h-4 border border-kitsaGreen rounded-full peer-checked:bg-kitsaGreen"></div>
                <span className="text-xs font-bold">No</span>
              </label>
            </div>
          </div>
        </div>

        <form onSubmit={handleAddAddress}>
          <div className="mb-5">
            <p className="text-xs font-bold mb-3">
              Flat/ House No./ Building Name *
            </p>
            <input
              onChange={(e) => setFlatNo(e.target.value)}
              required
              // value={flatNo}
              className="bg-formisWhite w-full outline-none text-formisBlack text-xs font-bold h-11 px-5 rounded-[10px]"
            />
          </div>

          <div className="mb-5">
            <p className="text-xs font-bold mb-3">Floor (Optional)</p>
            <input className="bg-formisWhite w-full outline-none text-formisBlack text-xs font-bold h-11 px-5 rounded-[10px]" />
          </div>

          <div className="mb-5">
            <p className="text-xs font-bold mb-3">Area</p>
            <div className="bg-[#666666] text-formisWhite p-2 rounded-[10px]">
              <p className="text-xs font-[700] mb-2">
                Delivering your order to
              </p>

              <div className="flex items-center justify-between shadow-custom px-3 py-2 rounded-[10px] mb-2">
                <div className="flex items-center gap-7">
                  <PiMapPinLineFill className="text-3xl shrink-0" />
                  <div className="text-xs">
                    <p className="font-[700]">{first}</p>
                    <p className="font-[500]">
                      {city}, {pinCode}
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => setFillRemainingDetails(false)}
                  className="text-xs font-bold bg-kitsaGreen text-kitsaWhite rounded-[10px] py-1 px-2"
                >
                  Change
                </button>
              </div>
            </div>
          </div>

          <div className="mb-5">
            <p className="text-xs font-bold mb-3">Nearby Landmark (Optional)</p>
            <input
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
              className="bg-formisWhite w-full outline-none text-formisBlack text-xs font-bold h-11 px-5 rounded-[10px]"
            />
          </div>

          <div>
            <p className="text-xs text-[#666666] font-bold mb-3">
              Provide your details for easy delivery.*
            </p>
            <div className="mb-5">
              <p className="text-xs font-bold mb-3">Your Name*</p>
              <input
                required
                onChange={(e) => setContactName(e.target.value)}
                className="bg-formisWhite w-full outline-none text-formisBlack text-xs font-bold h-11 px-5 rounded-[10px]"
              />
            </div>
            <div className="mb-5">
              <p className="text-xs font-bold mb-3">Your Phone Number*</p>
              <div className="bg-formisWhite w-full outline-none text-formisBlack text-xs font-bold h-11 px-2 rounded-[10px] flex items-center gap-3">
                <p>+91</p>
                <input
                  required
                  type="tel"
                  onChange={(e) => {
                    if (e.target.value.length <= 10) {
                      setPhoneNumber(e.target.value);
                    }
                  }}
                  value={phoneNumber}
                  className="bg-transparent h-full w-full outline-none"
                />
              </div>
            </div>
          </div>

          {message && (
            <p className="text-center mb-1 w-full text-red-500 text-sm">
              {message.name?.[0] || message.non_field_errors?.[0]}
            </p>
          )}

          <button
            type="submit"
            className="active:opacity-70 xl:active:opacity-100 transition-opacity bg-kitsaWhite text-formisBlack rounded-[10px] py-2 w-full font-bold tracking-[-0.4px]"
          >
            Save Address
          </button>
        </form>
      </div>

      {addressTypeInput === "Other" && (
        <div className="fixed flex justify-center top-[83px] left-0 h-[calc(100%-83px)] rounded-t-[30px] w-full">
          <div
            className="absolute bg-formisBlack/90 h-full w-full rounded-t-[30px]"
            onClick={() => {
              setAddressType("Home");
              setCustomAddressType(null);
            }}
          />
          <div className="relative z-10 mt-10 h-fit w-full mx-5">
            <p className="text-formisWhite text-sm font-bold mb-4">Save As</p>
            <div className="flex flex-col items-center justify-center">
              <input
                className="bg-formisWhite w-full outline-none text-formisBlack text-xs font-bold h-11 px-5 rounded-[10px] mb-8"
                required
                onChange={(e) => setCustomAddressType(e.target.value)}
              />
              <button
                className="bg-kitsaWhite text-formisBlack px-8 py-[6px] font-bold rounded-[10px] mx-auto"
                onClick={() => {
                  if (customAddressType === "") {
                    toast.error("Please enter a valid address name");
                    return;
                  }
                  setAddressType(customAddressType);
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RemainingDetails;
