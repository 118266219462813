import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import Lottie from "lottie-react";
import orderDelivered from "../assets/order-delivered.json";
import congratulations from "../assets/congratulations.json";
import kitsaLogo from "../assets/kitsa-logo-green.png";
import FeedbackAccordian from "../components/Order Track Components/FeedbackAccordian";
import { useState } from "react";
import StarIcon from "../assets/icon components/Star";
import { Link, useNavigate } from "react-router-dom";
import home from "../assets/icons/home-icon.svg";
import { feedbackResponseApi, orderStatusApi } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { setUpdates } from "../features/apiCartSlice";

const OrderDelivered = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [condition, setCondition] = useState();
  const [deliverySpeed, setDeliverySpeed] = useState();
  const [overallStars, setOverallStars] = useState(0);
  const [productStars, setProductStars] = useState(0);
  const [additionalComments, setAdditionalComments] = useState();

  const [showSubmitButton, setShowSubmitButton] = useState();
  const [showSubmitted, setShowSubmitted] = useState(false);

  const { updates } = useSelector((state) => state.apiCart);

  const animationRef = useRef(null);
  const contentRef = useRef(null);
  const logoRef = useRef(null);

  const handleOverallStars = (index) => setOverallStars(index + 1);
  const handleProductStars = (index) => setProductStars(index + 1);

  const getOrderUpdates = async () => {
    const access = localStorage.getItem("accessToken");
    const orderCode = localStorage.getItem("orderCode");
    try {
      const response = await fetch(orderStatusApi + orderCode, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        dispatch(setUpdates(data));
      } else if (response.status === 401) {
        localStorage.setItem("isAuthenticated", false);
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  };

  const sendFeedback = async () => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(feedbackResponseApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify([
          {
            question: 1,
            answer: overallStars,
          },
          {
            question: 2,
            answer: productStars,
          },
          {
            question: 3,
            answer: condition,
          },
          {
            question: 4,
            answer: deliverySpeed,
          },
          {
            question: 5,
            answer: additionalComments,
          },
        ]),
      });
      const data = await response.json();
      if (response.ok) {
        // console.log(data);
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getOrderUpdates();
    const tl = gsap.timeline();

    tl.fromTo(
      animationRef.current,
      { scale: 2, opacity: 1 },
      { scale: 1, duration: 1, ease: "power2.out", delay: 0.2 }
    );

    setTimeout(() => {
      tl.to(animationRef.current, {
        width: "50dvw",
        duration: 1,
        ease: "power2.out",
      });

      tl.to(contentRef.current, { opacity: 1, duration: 1 }, "-=0.5");
      tl.to(logoRef.current, { opacity: 1, duration: 1 }, "-=0.5");
    }, 2500);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowSubmitted(true);
    sendFeedback();
  };

  useEffect(() => {
    if (condition && deliverySpeed && overallStars && productStars)
      setShowSubmitButton(true);
  }, [
    condition,
    deliverySpeed,
    overallStars,
    productStars,
    additionalComments,
  ]);

  useEffect(() => {
    let timeoutId;
    if (showSubmitted) {
      timeoutId = setTimeout(() => {
        navigate("/");
      }, 2000);
    }

    return () => clearTimeout(timeoutId);
  }, [showSubmitted]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      localStorage.removeItem("orderCode");
      dispatch(setUpdates(null))
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="text-kitsaWhite px-3 flex flex-col justify-between min-h-[100dvh] lg:max-h-[100dvh] pt-3 overflow-hidden">
      <div>
        {/* Lottie Animation */}
        <div className="w-[70dvw] lg:w-[30dvw] mx-auto" ref={animationRef}>
          <Lottie animationData={orderDelivered} loop={false} />
        </div>

        <div ref={contentRef} className="opacity-0 -mt-8">
          <Link
            to="/"
            className="absolute top-0 right-0 flex items-center gap-2 py-[2px] bg-kitsaGreen px-2 text-sm font-medium"
          >
            <img src={home} alt="home" className="" />
            <p>Return to home</p>
          </Link>

          <p className="text-center text-sm font-bold mb-5">
            Your order has been successfully delivered by <br />
            <span className="text-kitsaGreen">
              KITSA™ Rider {updates?.rider_name}
            </span>
          </p>

          <FeedbackAccordian title="Please rate your experience." open={true}>
            <form onSubmit={handleSubmit}>
              {/* OVERALL EXPERIENCE */}
              <div className="mb-5">
                <p className="text-sm font-medium">
                  How would you rate your overall experience?
                </p>
                <div className="flex items-center gap-1 w-fit mx-auto">
                  {Array.from({ length: 5 }, (_, index) => (
                    <StarIcon
                      key={index}
                      width="30"
                      color={index < overallStars ? "#47b24f" : "#f1edeb"}
                      onClick={() => handleOverallStars(index)}
                    />
                  ))}
                </div>
              </div>

              {/* RATE PRODUCT */}
              <div className="mb-5">
                <p className="text-sm font-medium">
                  How would you rate the product itself?
                </p>
                <div className="flex items-center gap-1 w-fit mx-auto">
                  {Array.from({ length: 5 }, (_, index) => (
                    <StarIcon
                      key={index}
                      width="30"
                      color={index < productStars ? "#47b24f" : "#f1edeb"}
                      onClick={() => handleProductStars(index)}
                    />
                  ))}
                </div>
              </div>

              {/* PRODUCT CONDITION */}
              <div className="mb-5">
                <p className="text-sm font-medium mb-2">
                  Was the product in perfect condition?
                </p>
                <div className="flex gap-4 w-fit mx-auto">
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="condition"
                      value="Yes"
                      checked={condition === "Yes"}
                      onChange={(e) => setCondition(e.target.value)}
                      className="hidden peer"
                    />
                    <div className="w-4 h-4 border border-kitsaGreen rounded-full peer-checked:bg-kitsaGreen"></div>
                    <span className="text-xs font-bold">Yes</span>
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="condition"
                      value="No"
                      checked={condition === "No"}
                      onChange={(e) => setCondition(e.target.value)}
                      className="hidden peer"
                    />
                    <div className="w-4 h-4 border border-kitsaGreen rounded-full peer-checked:bg-kitsaGreen"></div>
                    <span className="text-xs font-bold">No</span>
                  </label>
                </div>
              </div>

              {/* DELIVERY SPEED */}
              <div className="mb-5">
                <p className="text-sm font-medium mb-2">
                  How was the delivery speed?
                </p>
                <div className="flex gap-4 w-fit mx-auto">
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="deliverySpeed"
                      value="Very Fast"
                      checked={deliverySpeed === "Very Fast"}
                      onChange={(e) => setDeliverySpeed(e.target.value)}
                      className="hidden peer"
                    />
                    <div className="w-4 h-4 border border-kitsaGreen rounded-full peer-checked:bg-kitsaGreen"></div>
                    <span className="text-xs font-bold">Very Fast</span>
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="deliverySpeed"
                      value="On Time"
                      checked={deliverySpeed === "On Time"}
                      onChange={(e) => setDeliverySpeed(e.target.value)}
                      className="hidden peer"
                    />
                    <div className="w-4 h-4 border border-kitsaGreen rounded-full peer-checked:bg-kitsaGreen"></div>
                    <span className="text-xs font-bold">On Time</span>
                  </label>

                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      name="deliverySpeed"
                      value="Delayed"
                      checked={deliverySpeed === "Delayed"}
                      onChange={(e) => setDeliverySpeed(e.target.value)}
                      className="hidden peer"
                    />
                    <div className="w-4 h-4 border border-kitsaGreen rounded-full peer-checked:bg-kitsaGreen"></div>
                    <span className="text-xs font-bold">Delayed</span>
                  </label>
                </div>
              </div>

              <div>
                <p className="text-sm font-medium mb-2">
                  Any additional comments?
                </p>
                <textarea
                  required
                  className="w-[80%] h-18 rounded-[8px] outline-none text-formisBlack p-2 text-xs font-medium"
                  placeholder="Tell us how we can make your next experience even better!"
                  onChange={(e) => setAdditionalComments(e.target.value)}
                />
              </div>

              {showSubmitted && (
                <div className="absolute top-1/2 h-full w-full">
                  <Lottie animationData={congratulations} />
                </div>
              )}

              <button
                onTouchStart={(e) =>
                  e.currentTarget.classList.add("scale-95", "opacity-70")
                }
                onTouchEnd={(e) =>
                  e.currentTarget.classList.remove("scale-95", "opacity-70")
                }
                type="submit"
                disabled={!showSubmitButton}
                className={`${
                  showSubmitButton ? "opacity-100" : "opacity-0"
                } mt-3 bg-kitsaGreen w-[40%] font-medium py-1 rounded-[8px] transition-opacity duration-300`}
              >
                {showSubmitted ? "Submitted" : "Submit"}
              </button>
            </form>
          </FeedbackAccordian>
        </div>
      </div>

      <div ref={logoRef} className="opacity-0">
        <img src={kitsaLogo} alt="Kitsa" className="w-[80dvw] mx-auto" />
      </div>
    </div>
  );
};

export default OrderDelivered;
