import React from "react";

const AddressHome = ({ color, width }) => {
  return (
    <svg
      version="1.1"
      id="Glyph"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      enableBackground="new 0 0 64 64"
      xmlSpace="preserve"
      fill={color}
      width={width}
    >
      <g>
        <path
          d="M37.86914,21.23438c0.03125-0.87695-0.2959-1.71582-0.92285-2.36523c-1.11816-1.15918-2.9834-1.43652-4.42285-0.55176
		c-0.32225,0.19725-0.72456,0.19727-1.04681,0.00004c-1.41258-0.86453-3.26777-0.6474-4.42291,0.55172
		c-0.62695,0.64941-0.9541,1.48828-0.92285,2.36328c0.08789,2.54297,3.8457,5.95801,5.86914,7.52051
		C34.02344,27.19043,37.78125,23.77539,37.86914,21.23438z"
        />
        <path
          d="M42.83331,23c0-5.97314-4.86017-10.83337-10.83331-10.83337S21.16669,17.02686,21.16669,23S26.02686,33.83331,32,33.83331
		S42.83331,28.97314,42.83331,23z M24.13281,21.30273c-0.05176-1.41992,0.47461-2.77832,1.48047-3.82227
		c1.6084-1.66699,4.25684-2.17383,6.38672-1.15332c2.13184-1.02051,4.7793-0.51367,6.38672,1.15332
		c1.00586,1.04395,1.53223,2.40234,1.48047,3.82422c-0.14746,4.2959-6.55664,8.98828-7.28617,9.50979
		c-0.34487,0.24604-0.8161,0.2473-1.16208-0.00002C30.68945,30.29297,24.28027,25.60059,24.13281,21.30273z"
        />
        <path
          d="M31.31738,48.73145C31.50977,48.91016,31.75488,49,32,49s0.49023-0.08984,0.68262-0.26855
		C33.30762,48.14746,48,34.30469,48,23c0-8.82227-7.17773-16-16-16s-16,7.17773-16,16
		C16,34.30469,30.69238,48.14746,31.31738,48.73145z M19,23c0-7.16864,5.83136-13,13-13s13,5.83136,13,13
		c0,7.16858-5.83136,13-13,13S19,30.16858,19,23z"
        />
        <path
          d="M7.76001,47.96997l24,6C31.84003,53.98999,31.91998,54,32,54s0.15997-0.01001,0.23999-0.03003l24-6
		C56.69,47.85999,57,47.45996,57,47c0-0.46002-0.31-0.85999-0.76001-0.97003l-14.96997-3.73999
		C37.94,46.48999,34.76001,49.51996,34.04999,50.19C33.48999,50.70996,32.76001,51,32,51s-1.48999-0.29004-2.03998-0.79999
		c-0.72003-0.67004-3.90002-3.71002-7.23004-7.91003L7.76001,46.02997C7.31,46.14001,7,46.53998,7,47
		C7,47.45996,7.31,47.85999,7.76001,47.96997z"
        />
        <path
          d="M32.72998,55.90997C32.5,55.96997,32.25,56,32,56s-0.5-0.03003-0.73999-0.09003l-23.98999-6
		C7.17999,49.89001,7.09003,49.85999,7,49.81V51c0,0.45996,0.31,0.85999,0.76001,0.96997l24,6C31.84003,57.98999,31.91998,58,32,58
		s0.15997-0.01001,0.23999-0.03003l24-6C56.69,51.85999,57,51.45996,57,51v-1.19c-0.09003,0.04999-0.17999,0.08002-0.27002,0.09998
		L32.72998,55.90997z"
        />
      </g>
    </svg>
  );
};

export default AddressHome;
