import { useNavigate } from "react-router-dom";
import { useWeather } from "../hooks/useWeather";
import { useSelector } from "react-redux";
import { useMemo, useState, useEffect } from "react";
import close from "../assets/icons/close-peach.svg";

//CUSTOM WEATHER ICONS
import DFewClouds from "../assets/icon components/weather icons/DFewClouds";
import DMist from "../assets/icon components/weather icons/DMist";
import DRain from "../assets/icon components/weather icons/DRain";
import DSun from "../assets/icon components/weather icons/DSun";
import NFewClouds from "../assets/icon components/weather icons/NFewClouds";
import NSun from "../assets/icon components/weather icons/NSun";
import Snow from "../assets/icon components/weather icons/Snow";
import Thunderstorm from "../assets/icon components/weather icons/Thunderstorm";

const WeatherDetails = () => {
  const navigate = useNavigate();
  const { coordinates } = useSelector((state) => state.googleMapLocation);
  const locationInfo = useMemo(() => {
    return {
      latitude: coordinates.lat,
      longitude: coordinates.lng,
    };
  }, [coordinates]);

  const { weatherData } = useWeather(locationInfo);

  const aqiDescriptions = {
    1: "Good",
    2: "Fair",
    3: "Moderate",
    4: "Poor",
    5: "Poor",
  };

  const weatherIconComponents = {
    "01d": <DSun color="#f1edeb" width="120px" />,
    "01n": <NSun color="#f1edeb" width="120px" />,
    "02d": <DFewClouds color="#f1edeb" width="120px" />,
    "02n": <NFewClouds color="#f1edeb" width="120px" />,
    "03d": <DFewClouds color="#f1edeb" width="120px" />,
    "03n": <NFewClouds color="#f1edeb" width="120px" />,
    "04d": <DFewClouds color="#f1edeb" width="120px" />,
    "04n": <NFewClouds color="#f1edeb" width="120px" />,
    "09d": <DRain color="#f1edeb" width="120px" />,
    "09n": <DRain color="#f1edeb" width="120px" />,
    "10d": <DRain color="#f1edeb" width="120px" />,
    "10n": <DRain color="#f1edeb" width="120px" />,
    "11d": <Thunderstorm color="#f1edeb" width="120px" />,
    "11n": <Thunderstorm color="#f1edeb" width="120px" />,
    "13d": <Snow color="#f1edeb" width="120px" />,
    "13n": <Snow color="#f1edeb" width="120px" />,
    "50d": <DMist color="#f1edeb" width="120px" />,
    "50n": <DMist color="#f1edeb" width="120px" />,
  };

  const getAqiDescription = (aqiValue) => {
    return aqiDescriptions[aqiValue] || "Unknown";
  };
  const aqiDescription = getAqiDescription(weatherData?.aqi);

  const [currentTime, setCurrentTime] = useState("");

  // const formatTime = (date) => {
  //   let hours = date.getHours();
  //   let minutes = date.getMinutes();
  //   const ampm = hours >= 12 ? "PM" : "AM";
  //   hours = hours % 12 || 12;
  //   minutes = minutes < 10 ? `0${minutes}` : minutes;
  //   return `${hours}:${minutes} ${ampm}`;
  // };

  const formatTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes} ${ampm}`;
  };

  useEffect(() => {
    const updateClock = () => {
      const now = new Date();
      setCurrentTime(formatTime(now));
    };

    updateClock();
    const interval = setInterval(updateClock, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="bg-formisBlack h-[100dvh] w-full px-4">
      <div className="text-center pt-14 pb-12">
        <img
          src={close}
          alt="Close"
          className="mx-auto mb-10"
          onClick={() => navigate(-1)}
        />
        <p className="text-formisWhite text-xs font-bold">
          Weather Updates are provided by Open Weather using API, <br /> it is
          not a property of <span className="text-kitsaGreen font-[MestizaSans] font-bold">KITSA™</span>.
        </p>
      </div>

      <div className="flex items-start justify-between gap-2 px-4">
        {weatherIconComponents[weatherData?.icon]}
        <div className="mt-5">
          <h2 className="text-kitsaWhite text-7xl font-bold mb-4">
            {weatherData?.temperature}
            <span className="relative bottom-9 text-xl">℃</span>
          </h2>

          <div className="bg-formisWhite text-xl w-fit px-4 py-[2px] mx-auto rounded-[10px] font-semibold">
            AQI - {aqiDescription}
          </div>
        </div>
      </div>

      <div className="mt-24">
        <p className="text-center text-formisWhite font-bold text-sm">
          When the Air Quality Index (AQI) is labeled as “poor,” it means that
          the air contains a high level of pollutants, which can cause
          discomfort or health issues, especially for sensitive groups such as
          children, the elderly, and individuals with pre-existing respiratory
          or heart conditions.
        </p>
      </div>

      <div className="text-center mt-40">
        <h2 className="text-7xl font-bold text-kitsaWhite">{currentTime}</h2>
      </div>
    </div>
  );
};

export default WeatherDetails;
