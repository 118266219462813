import React from "react";

const DMist = ({color, width}) => {
  return (
    <svg id="Glyph" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill={color} width={width}>
      <path d="M43,10c.55,0,1-.45,1-1V5c0-.55-.45-1-1-1s-1,.45-1,1v4c0,.55,.45,1,1,1Z" />
      <path d="M51.48,13.51c.26,0,.51-.1,.71-.29l2.83-2.83c.39-.39,.39-1.02,0-1.41s-1.02-.39-1.41,0l-2.83,2.83c-.39,.39-.39,1.02,0,1.41,.2,.2,.45,.29,.71,.29Z" />
      <path d="M58.99,19.99h-4c-.55,0-1,.45-1,1s.45,1,1,1h4c.55,0,1-.45,1-1s-.45-1-1-1Z" />
      <path d="M52.18,28.76c-.39-.39-1.02-.39-1.41,0s-.39,1.02,0,1.41l2.83,2.83c.2,.2,.45,.29,.71,.29s.51-.1,.71-.29c.39-.39,.39-1.02,0-1.41l-2.83-2.83Z" />
      <path d="M33.82,13.22c.2,.2,.45,.29,.71,.29s.51-.1,.71-.29c.39-.39,.39-1.02,0-1.41l-2.83-2.83c-.39-.39-1.02-.39-1.41,0s-.39,1.02,0,1.41l2.83,2.83Z" />
      <path d="M37.49,10.3c.16,.38,.53,.62,.92,.62,.13,0,.26-.02,.38-.08,.51-.21,.75-.8,.54-1.31l-.77-1.85c-.21-.51-.79-.75-1.31-.54-.51,.21-.75,.8-.54,1.31l.77,1.85Z" />
      <path d="M29.69,16.56l1.85,.77c.12,.05,.25,.08,.38,.08,.39,0,.76-.23,.92-.62,.21-.51-.03-1.1-.54-1.31l-1.85-.77c-.51-.21-1.1,.03-1.31,.54s.03,1.1,.54,1.31Z" />
      <path d="M56.31,25.42l-1.85-.77c-.51-.21-1.1,.03-1.31,.54s.03,1.1,.54,1.31l1.85,.77c.12,.05,.25,.08,.38,.08,.39,0,.76-.23,.92-.62,.21-.51-.03-1.1-.54-1.31Z" />
      <path d="M53.15,16.78c.16,.38,.53,.62,.92,.62,.13,0,.26-.02,.38-.08l1.85-.77c.51-.21,.75-.8,.54-1.31-.21-.51-.8-.75-1.31-.54l-1.85,.77c-.51,.21-.75,.8-.54,1.31Z" />
      <path d="M47.21,10.84c.13,.05,.25,.08,.38,.08,.39,0,.76-.23,.92-.62l.77-1.85c.21-.51-.03-1.1-.54-1.31-.51-.21-1.09,.03-1.31,.54l-.77,1.85c-.21,.51,.03,1.1,.54,1.31Z" />
      <path d="M43,12.99c-4.2,0-7.64,3.24-7.97,7.35,.25,.35,.48,.71,.68,1.09h0c2.78,0,5.36,1.29,7.02,3.45,.23-.02,.46-.02,.7-.02,2,0,3.86,.57,5.46,1.54,1.31-1.42,2.11-3.32,2.11-5.4,0-4.42-3.58-8-8-8Z" />
      <path d="M43.43,26.86c-.58,0-1.14,.06-1.68,.17-1.16-2.14-3.43-3.6-6.03-3.6-.43,0-.85,.04-1.26,.12-1.02-3.06-3.91-5.26-7.31-5.26-1.08,0-2.11,.22-3.04,.62-1.8-1.46-4.1-2.34-6.6-2.34-5.79,0-10.49,4.7-10.49,10.49,0,.98,.14,1.92,.39,2.82-2.06,1.39-3.41,3.74-3.41,6.4,0,4.26,3.45,7.71,7.71,7.71h31.71c4.73,0,8.57-3.84,8.57-8.57s-3.84-8.57-8.57-8.57Z" />
      <path d="M9.47,47.21c-.55-.1-1.06,.27-1.15,.82l-.61,3.6c-.09,.54,.27,1.06,.82,1.15,.06,0,.11,.01,.17,.01,.48,0,.9-.34,.98-.83l.61-3.6c.09-.54-.27-1.06-.82-1.15Z" />
      <path d="M8.25,54.42c-.55-.09-1.06,.27-1.15,.82l-.61,3.6c-.09,.54,.27,1.06,.82,1.15,.06,0,.11,.01,.17,.01,.48,0,.9-.34,.98-.83l.61-3.6c.09-.54-.27-1.06-.82-1.15Z" />
      <path d="M15.47,47.21c-.54-.1-1.06,.27-1.15,.82l-.61,3.6c-.09,.54,.27,1.06,.82,1.15,.06,0,.11,.01,.17,.01,.48,0,.9-.34,.98-.83l.61-3.6c.09-.54-.27-1.06-.82-1.15Z" />
      <path d="M14.25,54.42c-.54-.09-1.06,.27-1.15,.82l-.61,3.6c-.09,.54,.27,1.06,.82,1.15,.06,0,.11,.01,.17,.01,.48,0,.9-.34,.98-.83l.61-3.6c.09-.54-.27-1.06-.82-1.15Z" />
      <path d="M21.47,47.21c-.54-.1-1.06,.27-1.15,.82l-.61,3.6c-.09,.54,.27,1.06,.82,1.15,.06,0,.11,.01,.17,.01,.48,0,.9-.34,.98-.83l.61-3.6c.09-.54-.27-1.06-.82-1.15Z" />
      <path d="M20.25,54.42c-.54-.09-1.06,.27-1.15,.82l-.61,3.6c-.09,.54,.27,1.06,.82,1.15,.06,0,.11,.01,.17,.01,.48,0,.9-.34,.98-.83l.61-3.6c.09-.54-.27-1.06-.82-1.15Z" />
      <path d="M27.47,47.21c-.54-.1-1.06,.27-1.15,.82l-.61,3.6c-.09,.54,.27,1.06,.82,1.15,.06,0,.11,.01,.17,.01,.48,0,.9-.34,.98-.83l.61-3.6c.09-.54-.27-1.06-.82-1.15Z" />
      <path d="M26.25,54.42c-.54-.09-1.06,.27-1.15,.82l-.61,3.6c-.09,.54,.27,1.06,.82,1.15,.06,0,.11,.01,.17,.01,.48,0,.9-.34,.98-.83l.61-3.6c.09-.54-.27-1.06-.82-1.15Z" />
      <path d="M33.47,47.21c-.55-.1-1.06,.27-1.15,.82l-.61,3.6c-.09,.54,.27,1.06,.82,1.15,.06,0,.11,.01,.17,.01,.48,0,.9-.34,.98-.83l.61-3.6c.09-.54-.27-1.06-.82-1.15Z" />
      <path d="M32.25,54.42c-.55-.09-1.06,.27-1.15,.82l-.61,3.6c-.09,.54,.27,1.06,.82,1.15,.06,0,.11,.01,.17,.01,.48,0,.9-.34,.98-.83l.61-3.6c.09-.54-.27-1.06-.82-1.15Z" />
      <path d="M39.47,47.21c-.54-.1-1.06,.27-1.15,.82l-.61,3.6c-.09,.54,.27,1.06,.82,1.15,.06,0,.11,.01,.17,.01,.48,0,.9-.34,.98-.83l.61-3.6c.09-.54-.27-1.06-.82-1.15Z" />
      <path d="M38.25,54.42c-.54-.09-1.06,.27-1.15,.82l-.61,3.6c-.09,.54,.27,1.06,.82,1.15,.06,0,.11,.01,.17,.01,.48,0,.9-.34,.98-.83l.61-3.6c.09-.54-.27-1.06-.82-1.15Z" />
      <path d="M45.47,47.21c-.54-.1-1.06,.27-1.15,.82l-.61,3.6c-.09,.54,.27,1.06,.82,1.15,.06,0,.11,.01,.17,.01,.48,0,.9-.34,.98-.83l.61-3.6c.09-.54-.27-1.06-.82-1.15Z" />
      <path d="M44.25,54.42c-.54-.09-1.06,.27-1.15,.82l-.61,3.6c-.09,.54,.27,1.06,.82,1.15,.06,0,.11,.01,.17,.01,.48,0,.9-.34,.98-.83l.61-3.6c.09-.54-.27-1.06-.82-1.15Z" />
    </svg>
  );
};

export default DMist;
