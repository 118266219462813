import React from "react";

const Snow = ({color, width}) => {
  return (
    <svg id="Glyph" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill={color} width={width}>
      <g>
        <circle cx="12" cy="42" r="2" />
        <circle cx="11" cy="50" r="2" />
        <circle cx="10" cy="58" r="2" />
        <circle cx="22" cy="42" r="2" />
        <circle cx="21" cy="50" r="2" />
        <circle cx="20" cy="58" r="2" />
        <circle cx="32" cy="42" r="2" />
        <circle cx="31" cy="50" r="2" />
        <circle cx="30" cy="58" r="2" />
        <circle cx="42" cy="42" r="2" />
        <circle cx="41" cy="50" r="2" />
        <circle cx="40" cy="58" r="2" />
        <circle cx="52" cy="42" r="2" />
        <circle cx="51" cy="50" r="2" />
        <circle cx="50" cy="58" r="2" />
      </g>
      <path d="M50,36c5.52,0,10-4.48,10-10s-4.48-10-10-10c-.67,0-1.33,.07-1.96,.2-1.35-2.5-4-4.2-7.04-4.2-.5,0-.99,.05-1.47,.14-1.2-3.57-4.56-6.14-8.53-6.14-1.26,0-2.46,.26-3.54,.73-2.1-1.7-4.78-2.73-7.7-2.73-6.76,0-12.24,5.48-12.24,12.24,0,1.14,.17,2.24,.46,3.29-2.4,1.62-3.98,4.36-3.98,7.47,0,4.97,4.03,9,9,9H50Z" />
    </svg>
  );
};

export default Snow;
