import { BrowserRouter, Route, Routes } from "react-router-dom";
import CheckoutCart from "./pages/CheckoutCart";
import Main from "./pages/Main";
import Product from "./pages/Product";
import AllProducts from "./pages/AllProducts";
import Login from "./pages/Login";
import ScrollToTop from "./components/ScrollToTop";
import CategoryPage from "./pages/CategoryPage";
import { useEffect, useState } from "react";
import WeatherDetails from "./pages/WeatherDetails";
import OrderTracking from "./pages/OrderTracking";
import Alternates from "./pages/Alternates";

import Lottie from "lottie-react";
import rotatePhone from "./assets/phone-rotate.json";
import { Toaster } from "sonner";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Blog from "./pages/Blog/Blog";
import BlogPost from "./pages/Blog/BlogPost";
import { useDispatch, useSelector } from "react-redux";
import { setSearchComponent } from "./features/searchSlice";
import PrivacyPolicy from "./pages/Policies/PrivacyPolicy";
import TermsPolicy from "./pages/Policies/TermsPolicy";
import ShippingRefundsPolicy from "./pages/Policies/ShippingRefundsPolicy";
import { setProfileComponent } from "./features/profileSlice";
import { toggleIsAddressSliderOpen } from "./features/mapSlice";
import WaitingScreen from "./pages/WaitingScreen";
import OrderDelivered from "./pages/OrderDelivered";
import OrderCancelled from "./pages/OrderCancelled";
import { setPrevStatus } from "./features/apiCartSlice";
import PageTitleUpdater from "./components/PageTitleUpdater";

import iphone from "./assets/iphone.png";
import SplashPage from "./components/SplashPage";

import kitsaBg from "./assets/KITSA.png";

const orderStatusMessages = {
  "TEST STATUS": "This is a status to test the push notifications",
  "ORDER PLACED":
    "Your order has been placed, we are waiting for pharmacist's approval.",
  "IN PROGRESS": "We are getting your order ready.",

  "INVOICE GENERATED": "Your invoice has been generated.",

  "ALL AVAILABLE": "All your order items are available and are getting packed.",
  "FULLY APPROVED":
    "Your order is fully approved and a rider is being assigned.",
  "RIDER ASSIGNED": "A rider has been assigned to your order.",

  "REACHED PICKUP LOCATION": "The rider has arrived at the pickup location.",
  "START TO PICKUP": "The rider is on the way to collect your order.",
  "ORDER PICKED UP": "Your order is on its way!.",

  "REACHED DELIVERY LOCATION": "The rider has reached your location.",
  DELIVERED: "Your order has been successfully delivered.",

  RETURNED: "Your order has been returned.",
  "OUT OF SERVICEABLE AREA": "We are unable to deliver to your location.",
  "ORDER MODIFIED":
    "A few of your essentials are not available for immediate delivery.",
  "PARTIALLY APPROVED": "Some items are pending for approval.",
  COMPLETED: "Your order is complete.",
  "CANCELLED BY USER": "Your order was cancelled.",
  "CANCELLED DUE TO UNAVAILABILITY":
    "The products are unavailable, and your order was cancelled.",
  "CANCELLED OTC": "Your order was cancelled.",
  "NO MEDICINE AVAILABLE":
    "The requested medicines are currently out of stock.",
};

const App = () => {
  const dispatch = useDispatch();
  const { isSearchOpen } = useSelector((state) => state.search);
  const [showPortraitNotif, setShowPortraitNotif] = useState(false);
  const isProfileOpen = useSelector((state) => state.profile.isProfileOpen);
  const { isAddressSliderOpen } = useSelector(
    (state) => state.googleMapLocation
  );
  const { updates, prevStatus } = useSelector((state) => state.apiCart);
  const [showSplash, setShowSplash] = useState(false);
  const isAuthenticated = localStorage.getItem("isAuthenticated");

  // useEffect(() => {
  //   window.location.replace('https://kitsa.in');
  // }, [])

  useEffect(() => {
    if ("serviceWorker" in navigator && "SyncManager" in window) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.sync.register("dummy-sync").catch((err) => {
          console.error("Background Sync registration failed:", err);
        });
      });
    }
  }, []);

  const showOrderNotification = (status) => {
    if (Notification.permission === "granted") {
      navigator.serviceWorker.getRegistration().then((registration) => {
        registration.showNotification("Order Update", {
          body: `${orderStatusMessages[status] || status}`,
          icon: "/kitsa-192.png",
          badge: "/kitsa-favicon.ico",
          tag: `order-update-${status}`, // Prevent duplicates
          renotify: true,
        });
      });
    } else {
      console.warn("Notification permission not granted");
    }
  };

  useEffect(() => {
    if (updates?.status && updates.status !== prevStatus) {
      showOrderNotification(updates.status);
      dispatch(setPrevStatus(updates.status));
    }
  }, [updates?.status, prevStatus, dispatch]);

  useEffect(() => {
    if ("Notification" in window && "serviceWorker" in navigator) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        }
      });
    }
  }, []);

  //MAINTAIN SESSION STORAGE TO NOT DISPLAY SPLASH PAGE REPEATEDLY ON NAVIGATION
  useEffect(() => {
    if (!sessionStorage.getItem("hasSeenSplash")) {
      setShowSplash(true);
      sessionStorage.setItem("hasSeenSplash", "true");
    }
  }, [isAuthenticated]);

  // CHECK IF USER IS IN LANDSCAPE MODE
  useEffect(() => {
    const handleOrientationChange = () => {
      const isMobile = /Mobi|Android/i.test(navigator.userAgent);

      if (
        isMobile &&
        (window.screen.orientation.type === "landscape-primary" ||
          window.screen.orientation.type === "landscape-secondary")
      ) {
        setShowPortraitNotif(true);
      }
    };
    window.screen.orientation.addEventListener(
      "change",
      handleOrientationChange
    );

    handleOrientationChange();

    return () => {
      window.screen.orientation.removeEventListener(
        "change",
        handleOrientationChange
      );
    };
  }, []);

  // CLOSE SEARCH SLIDER ON BACK BUTTON
  useEffect(() => {
    if (isSearchOpen) {
      window.history.pushState({ modal: true }, "");
    }
    const handlePopState = (event) => {
      if (event.state?.modal) {
        dispatch(setSearchComponent(true));
      } else {
        dispatch(setSearchComponent(false));
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isSearchOpen]);

  // CLOSE PROFILE SLIDER ON BACK BUTTON
  useEffect(() => {
    if (isProfileOpen) {
      window.history.pushState({ profileModal: true }, "");
    }
    const handlePopState = (event) => {
      if (event.state?.profileModal) {
        dispatch(setProfileComponent(true));
      } else {
        dispatch(setProfileComponent(false));
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isProfileOpen]);

  // CLOSE ADDRESS SLIDER ON BACK BUTTON
  useEffect(() => {
    if (isAddressSliderOpen) {
      window.history.pushState({ addressModal: true }, "");
    } else {
      window.history.pushState({ addressModal: false }, "");
    }

    const handlePopState = (event) => {
      if (event.state?.addressModal) {
        dispatch(toggleIsAddressSliderOpen(true));
      } else {
        dispatch(toggleIsAddressSliderOpen(false));
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isAddressSliderOpen]);

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {showSplash && <SplashPage />}

      <div
        style={{
          backgroundImage: isDesktop ? `url(${kitsaBg})` : "none",
        }}
        className="bg-cover"
      >
        <div className="hidden lg:block absolute right-32 top-16 text-kitsaWhite font-medium text-right">
          <p>
            Please switch to mobile <br /> for an immersive experience.
          </p>
        </div>
        <div className="lg:w-[380px] 2xl:w-[430px] lg:mx-auto lg:h-screen lg:relative lg:overflow-auto lg:pt-10">
          <div className="lg:relative lg:z-10 pointer-events-none hidden lg:block">
            <div className="relative flex items-center justify-center">
              <div className="bg-kitsaGreen lg:h-7 2xl:h-10 lg:w-[345px] 2xl:w-[375px] absolute top-[1.15rem] -z-10" />
            </div>

            <img src={iphone} className="w-full h-[85vh]" alt="iPhone frame" />
          </div>
          <div className="lg:absolute lg:top-0 lg:left-0 lg:scale-[.88] lg:overflow-hidden lg:mx-auto lg:max-w-[380px] 2xl:max-w-[430px] lg:flex items-center justify-center lg:pt-36 2xl:pt-40 lg:max-h-[93.25vh] 2xl:max-h-[92.5vh]">
            <BrowserRouter>
              <Toaster
                position="top-center"
                toastOptions={{
                  className:
                    "bg-formisBlack shadow-md shadow-kitsaWhite text-kitsaWhite border border-kitsaWhite",
                  duration: 1500,
                }}
              />
              <PageTitleUpdater />
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/cart" element={<CheckoutCart />} />
                <Route path="/login" element={<Login />} />
                <Route
                  path="/categories/:categoryName"
                  element={<CategoryPage />}
                />
                <Route path="/product/:slug" element={<Product />} />
                <Route path="/weather" element={<WeatherDetails />} />
                <Route path="/all-products" element={<AllProducts />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/about" element={<About />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:blogId" element={<BlogPost />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<TermsPolicy />} />
                <Route
                  path="/shipping-and-refunds-policy"
                  element={<ShippingRefundsPolicy />}
                />

                <Route path="/track-order" element={<OrderTracking />} />
                <Route path="/alternates" element={<Alternates />} />
                <Route path="/pending-approval" element={<WaitingScreen />} />
                <Route path="/order-delivered" element={<OrderDelivered />} />
                <Route path="/order-cancelled" element={<OrderCancelled />} />
              </Routes>

              {showPortraitNotif && (
                <div className="absolute z-[31] left-0 top-0 h-[100dvh] w-screen bg-formisBlack/70 flex items-center justify-center">
                  <div className="bg-[#203040] border border-[#666666] px-3 py-4 rounded-[10px] flex flex-col justify-center items-center shadow-peachBox">
                    <div className="max-w-16 mb-3">
                      <Lottie animationData={rotatePhone} />
                    </div>
                    <div className="text-sm font-bold text-kitsaWhite text-center mb-3">
                      Please switch back to portrait mode <br /> for the best
                      experience.
                    </div>
                    <button
                      onClick={() => setShowPortraitNotif(false)}
                      className="font-[MestizaSans] font-bold text-xs bg-kitsaWhite h-[28px] flex items-center justify-center px-4 rounded-[8px] shadow-md shadow-kitsaWhite"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}
            </BrowserRouter>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
