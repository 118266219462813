import { FaLinkedin } from "react-icons/fa";
import avi from "../../assets/about/avinandan.png";
import aayesha from "../../assets/about/aayesha.png";
import nikhil from "../../assets/about/nikhil.png";
import sarthak from "../../assets/about/sarthak.png";

const Team = () => {
  return (
    <section className="py-[60px] bg-formisBlack15 text-kitsaWhite px-5">
      <h5 className="text-[22px] mb-5">
        Leadership Team
      </h5>

      <div className="grid grid-cols-2 gap-10">
        <div>
          <img src={aayesha} alt="Aayesha" className="shadow-md shadow-kitsaWhite" />
          <div className="flex mt-4 justify-between">
            <div>
              <p>Aayesha</p>
              <p className="text-[13px] opacity-60">Co-Founder and CFO</p>
            </div>
          </div>
        </div>
        <div>
          <img src={avi} alt="Avinandan Choudhary" className="shadow-md shadow-kitsaWhite" />
          <div className="flex mt-4 justify-between">
            <div>
              <p>Avinandan Choudhary</p>
              <p className="text-[13px] opacity-60">Co-Founder and CEO</p>
            </div>
            <a href="https://www.linkedin.com/in/avinandanchoudhary/" target="_blank" rel="noreferrer">
              <FaLinkedin className="text-xl" />
            </a>
          </div>
        </div>
        <div>
          <img src={nikhil} alt="Nikhil Singh Charak" className="shadow-md shadow-kitsaWhite" />
          <div className="flex mt-4 justify-between">
            <div>
              <p>Nikhil Singh Charak</p>
              <p className="text-[13px] opacity-60">Founding Member and COO</p>
            </div>
            <a href="https://www.linkedin.com/in/nikhil-singh-charak-896936150/" target="_blank" rel="noreferrer">
              <FaLinkedin className="text-xl" />
            </a>
          </div>
        </div>
        <div>
          <img src={sarthak} alt="Sarthak Tyagi" className="shadow-md shadow-kitsaWhite" />
          <div className="flex mt-4 justify-between">
            <div>
              <p>Sarthak Tyagi</p>
              <p className="text-[13px] opacity-60">Founding Member and CTO</p>
            </div>
            <a href="https://www.linkedin.com/in/sarthak-tyagi/" target="_blank" rel="noreferrer">
              <FaLinkedin className="text-xl" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
