import { useEffect, useRef, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import {
  addToCartApi,
  deleteFromCartApi,
  recentSearchApi,
  searchAllApi,
  searchSuggestionsApi,
} from "../../api";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../../features/cartSlice";
import imageNA from "../../assets/Image-NA.webp";

import discount from "../../assets/icons/discount.svg";
import close from "../../assets/icons/close.svg";
import { useNavigate } from "react-router-dom";

import RequestFlow from "./RequestFlow";
import { setShowComingSoon } from "../../features/slidersSlice";
import { setUserCartItems } from "../../features/apiCartSlice";
import { setIsRequestOpen, setSearchQuery } from "../../features/searchSlice";

import Minus from "../../assets/icon components/Minus";
import Plus from "../../assets/icon components/Plus";

const SearchPage = ({ handlePageChange }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const userCart = useSelector((state) => state.apiCart.userCartItems);
  const { searchQuery } = useSelector((state) => state.search);
  const [searchResults, setSearchResults] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const debouncedSearchInput = useDebounce(searchQuery, 1000);
  const inputRef = useRef(null);

  const fetchSearchResults = async (debouncedSearchQuery) => {
    if (!debouncedSearchQuery) {
      setSearchResults([]);
      return;
    }
    try {
      const response = await fetch(searchAllApi + debouncedSearchQuery, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        setSearchResults(data);
        // console.log(data);
      } else {
        setSearchResults([]);
      }
    } catch {
      setSearchResults([]);
    }
  };

  const saveRecentSearch = async (query) => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(recentSearchApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          query: query,
        }),
      });
      // const data = await response.json();
      if (response.ok) {
        // console.log("Saved recent search", data)
      }
    } catch {}
  };

  const saveRecentSearchGuest = async (query) => {
    const guestToken = localStorage.getItem("guestToken");
    try {
      const response = await fetch(recentSearchApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: query,
          guest_token: guestToken,
        }),
      });
      // const data = await response.json();
      if (response.ok) {
        // console.log("Saved guest search", data);
      }
    } catch (err) {
      // console.log("err from ", err)
    }
  };

  useEffect(() => {
    if (!searchQuery) setSearchResults([]);
  }, [searchQuery]);

  useEffect(() => {
    if (debouncedSearchInput) {
      fetchSearchResults(debouncedSearchInput);
      if (isAuthenticated === "true") saveRecentSearch(debouncedSearchInput);
      else saveRecentSearchGuest(debouncedSearchInput);
    }
  }, [debouncedSearchInput]);

  const handleInputChange = (e) => {
    dispatch(setSearchQuery(e.target.value));
  };

  const getSuggestions = async () => {
    try {
      const response = await fetch(searchSuggestionsApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        const sortedSuggestions = data.results.sort(
          (a, b) => a.position - b.position
        );
        setSuggestions(sortedSuggestions);
      }
    } catch (error) {
      // console.error("Error fetching suggestions:", error);
    }
  };

  useEffect(() => {
    inputRef.current.focus();
    getSuggestions();
  }, []);

  return (
    <div className="overflow-scroll pointer-events-auto min-h-[100dvh]">
      <div className="px-2 m-3 pt-2 shadow-peachBox rounded-[20px] bg-formisBlack">
        <div className="flex items-center bg-formisWhite text-formisBlack rounded-[10px] px-2 h-[51px] mb-2">
          <IoIosArrowBack
            className="text-xl mr-2 shirnk-0"
            onClick={() => navigate(-1)}
          />
          <input
            ref={inputRef}
            placeholder="Search for ..."
            className="bg-transparent text-sm md:text-base font-[500] w-full placeholder:text-formisBlack/60 outline-none"
            onChange={handleInputChange}
            value={searchQuery}
          />
          {searchQuery && (
            <button
              onClick={() => dispatch(setSearchQuery(""))}
              className="ml-4"
            >
              <img src={close} alt="Clear" />
            </button>
          )}
        </div>

        <div className="px-1">
          {!searchQuery && suggestions.length > 0 && (
            <h5 className="text-sm font-bold mb-[23px]">Suggestions</h5>
          )}
          <ul
            className={`font-bold text-xs flex flex-col gap-2 ${
              searchQuery || suggestions.length <= 0 ? "pb-5 pt-2" : "pb-10"
            } pb-10`}
          >
            {!searchQuery &&
              suggestions?.map((suggestion) => (
                <li
                  key={suggestion.id}
                  onClick={() => dispatch(setSearchQuery(suggestion.term))}
                >
                  {suggestion.term}
                </li>
              ))}
            <li className="flex items-center justify-between">
              <p className="text-xs font-bold">
                Not finding what you are looking for?
              </p>
              <button
                onClick={() => dispatch(setIsRequestOpen(true))}
                onTouchStart={(e) =>
                  e.currentTarget.classList.add("scale-95", "opacity-70")
                }
                onTouchEnd={(e) =>
                  e.currentTarget.classList.remove("scale-95", "opacity-70")
                }
                className="text-[10px] font-semibold
                          bg-kitsaWhite text-formisBlack rounded-[10px] px-2 py-1 transition-all"
              >
                <span className="relative top-[1.5px] active:opacity-70">
                  Request
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      {searchQuery && (
        <p className="px-[15px] text-sm font-bold pt-2 pb-4">
          See products matching "{searchQuery}"
        </p>
      )}
      <div className="flex flex-wrap justify-between pt-2 gap-y-8 px-[15px] overflow-hidden pb-64 ">
        {searchQuery &&
          searchResults.map((result, index) => (
            <ProductCard
              key={index}
              res={result}
              userCart={userCart}
              handlePageChange={handlePageChange}
            />
          ))}
      </div>

      <RequestFlow />
    </div>
  );
};

export default SearchPage;

//--------------------------------------------------------------------------------------------------------------------------------------------//

const ProductCard = ({ handlePageChange, res, userCart }) => {
  const dispatch = useDispatch();
  const cartItem = useSelector((state) =>
    state.cartItems.items.find(
      (cartItem) => cartItem.product_id === res.product_id
    )
  );

  const userCartItem = userCart?.find(
    (item) => item.product_id === res.product_id
  );

  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const [productQuantity, setProductQuantity] = useState(
    userCartItem?.quantity || 1
  );

  useEffect(() => {
    setProductQuantity(userCartItem?.quantity);
  }, [userCart]);

  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    handlePageChange("product");
    localStorage.setItem("productSlug", res.slug);
  };

  const handleAddToCart = () => {
    if (loading) return;
    if (isAuthenticated === "true") {
      handleAddToCartApiCall(productQuantity);
    } else dispatch(addToCart(res));
  };

  const handleDecrement = () => {
    if (loading) return;
    if (productQuantity > 1) {
      const updatedQuantity = productQuantity - 1;
      setProductQuantity(updatedQuantity);
      handleAddToCartApiCall(updatedQuantity);
    } else {
      handleDeleteApiCall();
    }
  };

  const handleReduxDecrement = () => {
    if (cartItem?.quantity === 1) dispatch(removeFromCart(cartItem));
    if (cartItem?.quantity > 1) {
      dispatch(decrementQuantity({ product_id: res.product_id }));
    }
  };

  const handleIncrement = () => {
    if (loading) return;
    const updatedQuantity = productQuantity + 1;
    setProductQuantity(updatedQuantity);
    if (isAuthenticated === "true") {
      handleAddToCartApiCall(updatedQuantity);
      // dispatch(incrementQuantity({ product_id: res.product_id }));
    } else dispatch(incrementQuantity({ product_id: res.product_id }));
  };

  const handleAddToCartApiCall = async (quantity) => {
    const access = localStorage.getItem("accessToken");
    setLoading(true);
    try {
      const response = await fetch(addToCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          cart_data: [
            {
              product_id: res.product_id,
              quantity,
            },
          ],
        }),
      });

      const data = await response.json();
      if (response.ok) {
        dispatch(setUserCartItems(data));
      } else {
      }
    } catch (error) {
      // console.error("Failed to add to cart:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteApiCall = async () => {
    const access = localStorage.getItem("accessToken");
    setLoading(true);
    try {
      const response = await fetch(deleteFromCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          product_ids: [res.product_id],
        }),
      });

      const data = await response.json();

      if (response.ok) {
        dispatch(removeFromCart(cartItem));
        dispatch(setUserCartItems(data));
      } else {
      }
    } catch (error) {
      // console.error("Failed to delete:", error);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  return (
    <div className="w-[153.65px]">
      <div className="w-full relative mb-4">
        <div
          onClick={handleClick}
          className="p-[5px] bg-white rounded-[10px] h-[152px] w-full shadow-md shadow-kitsaWhite"
        >
          <img
            src={
              res.image_url !== "NA" &&
              res.image_url !==
                "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
                ? res.image_url
                : "https://media-hosting.imagekit.io//a3385a43077f4e0e/Image%20Not%20Available.png?Expires=1833358862&Key-Pair-Id=K2ZIVPTIP2VGHC&Signature=h-gValwZSoRKUBi9iEHAzKOKdwPlgYLNYSi5K7sAEH2knqMt4gpgaf~GJytsoI9gQemKj-5mIaJ4SPminI9Ts4j4esZ4j-udW3LgKjxS5Vn7goq6Z4bhS~5hzKy4uzSjttW1KAoemJvrM3em5fiBlImWlXQ6lOkyt79rhvtE11KwbRFH2w2pbdXPemroPpZxgHLRAAJ6EEsWL-psJxPosRF6LZz5giAWuUNGeem~P0IieT38Qlzvb6~nyJ96og4-XODPBV46s6vk3amWOa20pBrPSDP0X6a7Tjwpo9vunyMapTz9U0ucJqtk~c6yn9kD-YcgMIlQtPJmOG8AbIdtSA__"
            }
            loading="lazy"
            className="aspect-square rounded-[10px]"
            alt="Product"
          />
          <div className="h-[28px] w-[23px] absolute -top-[7px] -left-[1px]">
            <img src={discount} alt="discount!" className="" />
            <div className="absolute top-0 left-0 h-[28px] w-[23px] flex items-center justify-center">
              <p className="text-[10px] text-formisBlack font-bold leading-none">
                {parseInt(Math.floor(res.discount_percentage.slice(0, -1)))}%
                <br /> OFF
              </p>
            </div>
          </div>
        </div>

        {isAuthenticated === "true" ? (
          <div className="absolute -bottom-3 -right-2 font-bold rounded-[10px] text-sm bg-kitsaWhite">
            {userCartItem ? (
              <div className="bg-kitsaGreen text-kitsaWhite h-[30px] rounded-[10px] absolute -bottom-3 -right-0 flex gap-2 items-center mb-3">
                <button
                  disabled={loading}
                  className="pl-3 py-2"
                  onClick={handleDecrement}
                >
                  {userCartItem?.quantity === 1 || productQuantity === 1 ? (
                    <div className="rotate-45">
                      <Plus width={15} color="#f1edeb" />
                    </div>
                  ) : (
                    <Minus width={15} color="#f1edeb" />
                  )}
                </button>
                {!loading ? (
                  <input
                    type="number"
                    min="1"
                    value={userCartItem?.quantity}
                    readOnly
                    className="max-w-4 bg-transparent rounded-[5px] text-center outline-none"
                  />
                ) : (
                  <div className="loader2"></div>
                )}
                <button className="pr-3 py-2" onClick={handleIncrement}>
                  <Plus width={15} color="#f1edeb" />
                </button>
              </div>
            ) : (
              <div
                onClick={handleAddToCart}
                className="absolute -bottom-0 right-1 h-[30px] text-xs font-medium rounded-[10px] bg-kitsaWhite/90 text-formisBlack"
              >
                <button className="h-full w-[71px] font-semibold rounded-[10px]">
                  ADD
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="absolute -bottom-3 -right-2 font-bold rounded-[10px] text-sm bg-kitsaWhite">
            {cartItem ? (
              <div className="bg-kitsaGreen text-kitsaWhite h-[30px] rounded-[10px] absolute -bottom-3 -right-0 flex gap-2 items-center mb-3">
                <div className="pl-3 py-2" onClick={handleReduxDecrement}>
                  {cartItem?.quantity === 1 ? (
                    <div className="rotate-45">
                      <Plus width={15} color="#f1edeb" />
                    </div>
                  ) : (
                    <Minus width={15} color="#f1edeb" />
                  )}
                </div>
                <input
                  type="number"
                  min="1"
                  value={cartItem?.quantity}
                  readOnly
                  className="max-w-4 bg-transparent rounded-[5px] text-center outline-none"
                />
                <div className="pr-3 py-2" onClick={handleIncrement}>
                  <Plus width={15} color="#f1edeb" />
                </div>
              </div>
            ) : (
              <div
                onClick={handleAddToCart}
                className="absolute -bottom-0 right-1 h-[30px] text-xs font-medium rounded-[10px] bg-kitsaWhite/90 text-formisBlack"
              >
                <button className="h-full w-[71px] font-semibold rounded-[10px]">
                  ADD
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="flex items-center gap-1 mb-[6px]">
        {res.package && (
          <div className="bg-kitsaWhite rounded-full">
            <span className="text-[10px] font-bold px-1 leading-none h-[13px] tracking-[-0.25px] flex items-center justify-center rounded-[10px] bg-kitsaWhite text-formisBlack border border-kitsaWhite">
              {res.package}
            </span>
          </div>
        )}
      </div>

      <div className="flex w-full items-center justify-between">
        <div>
          <p className="font-[500] text-[11px] leading-4">
            {res.name.length > 42 ? res.name.slice(0, 42) + "..." : res.name}
          </p>

          <div className="text-[10px] font-normal">
            <div className="break-words">
              <p className="capitalize">{res.packaging}</p>
            </div>
            <button
              onClick={() => dispatch(setShowComingSoon(true))}
              className="shrink-0 bg-kitsaGreen text-kitsaWhite rounded-full h-fit px-1 py-[1px] my-1"
            >
              Dosage Calculator
            </button>
          </div>

          <div className="flex items-center gap-2 ">
            <div className="flex items-center gap-1">
              <p className="text-[12px] font-[700]">
                ₹{res.final_price.toFixed(2)} |
              </p>
              <span className="text-[10px] font-[400]">
                MRP <s>₹{res.mrp.toFixed(2)}</s>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
