import { useDispatch, useSelector } from "react-redux";
import { setIsSurgeSliderOpen, setShowSurge } from "../features/slidersSlice";
import close from "../assets/icons/close.svg";
import heart from "../assets/icons/heart.svg";
import { surgeApi } from "../api";
import { useEffect, useState } from "react";

const SurgeSlider = () => {
  const dispatch = useDispatch();
  const isSurgeSliderOpen = useSelector(
    (state) => state.sliders.isSurgeSliderOpen
  );
  const [info, setInfo] = useState();

  const fetchInfo = async () => {
    try {
      const response = await fetch(surgeApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        setInfo(data);
        dispatch(setShowSurge(data));
        // console.log("surge data", data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  return (
    <div className="text-formisBlack">
      <div
        className={`transition-opacity duration-500 ${
          isSurgeSliderOpen ? "bg-formisBlack/30 z-20" : "bg-transparent -z-50"
        } fixed inset-0 cross-cursor`}
        onClick={() => dispatch(setIsSurgeSliderOpen(false))}
      />
      <div
        id="surge-slider-main"
        className={`fixed md2:top-0 overflow-scroll md2:right-0 bg-gradient-to-b from-[#203040] via-[#15171c] to-[#15171c] z-[31] md2:rounded-t-none md2:rounded-tl-[20px] md2:rounded-l-[20px] w-screen h-[calc(100dvh-3rem)] md2:w-[70vw] lg:w-[50vw] xl:w-[45vw] 2xl:w-[40vw] 3xl:w-[35vw] md2:h-screen
               transition-transform duration-500 flex flex-col md2:overflow-x-hidden ${
                 isSurgeSliderOpen
                   ? "slide-up md2:translate-x-0"
                   : "slide-down md2:translate-x-full"
               }`}
      >
        <div className="bg-kitsaWhite min-h-full">
          <div className="flex items-center justify-center bg-kitsaWhite min-h-[122px] w-full">
            <img
              src={close}
              alt="Close"
              onClick={() => dispatch(setIsSurgeSliderOpen(false))}
            />
          </div>

          <div className="min-h-[calc(100%-122px)] bg-gradient-to-b from-[#203040] via-[#203040] to-[#15171c] text-kitsaWhite py-10 px-4 flex flex-col gap-14 rounded-t-[30px]">
            <QnA
              qn="What Are Surge Fees?"
              an="Surge fees are a small additional charge added when demand for our services is unusually high. They help ensure we can meet your needs promptly by maintaining availability and efficiency during busy periods."
            />
            <QnA
              qn="Why Do Surge Fees Occur?"
              an="When demand spikes-such as during holidays, bad weather, or other peak times-additional resources and logistics are required to serve everyone efficiently. Surge fees allow us to accommodate this increased demand without compromising quality."
            />
            <QnA
              qn="How Much Will Be Incurred?"
              an="Surge fees will range from X% to Y% of your bill (exact percentage displayed at checkout). We aim to keep this as minimal as possible and always transparent."
            />
            <QnA
              qn="When Will Surge Fees Go Away?"
              an="Surge fees are temporary and will be rem automatically once demand normalises. We'll keep you updated so you can plan your orders accordingly."
            />
            <QnA
              qn="What Do We Do With Surge Fees?"
              an="The surge fees you pay go directly into ensuring:"
            >
              <div className="mt-5 flex flex-col gap-2">
                <p className="text-formisWhite text-xs font-bold">
                  1. Faster deliveries by covering the cost of additional
                  resources.
                </p>
                <p className="text-formisWhite text-xs font-bold">
                  2. Consistent service quality despite higher-than-usual
                  demand.
                </p>
                <p className="text-formisWhite text-xs font-bold">
                  3. Better availability of products and services during peak
                  times.
                </p>
              </div>
            </QnA>
            <div className="text-sm text-[#666666] ">
              <p className="font-[500]">
                Health Essentials, Delivered Before the Panic.
              </p>
              <div className="flex items-center gap-5 my-1 font-[500]">
                <p>It is Going to be FORMIS® for You</p>
                <img src={heart} alt="❤️" className="w-4" />
              </div>
              <p className="text-xs">Terms and Conditions Applied</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurgeSlider;

const QnA = ({ qn, an, children }) => {
  return (
    <div className="max-w-[80%]">
      <h5 className="font-bold text-xs mb-5">{qn}</h5>
      <p className="text-formisWhite text-xs font-bold">{an}</p>
      {children}
    </div>
  );
};
