import React from "react";

const Eta = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.255"
      height="13.609"
      viewBox="0 0 16.255 13.609"
    >
      <g id="ETA_Icon" data-name="ETA Icon" transform="translate(85.997 -1083)">
        <g
          id="ETA_Icon-2"
          data-name="ETA Icon"
          transform="translate(-85.997 1083)"
        >
          <path
            id="Path_172"
            data-name="Path 172"
            d="M5.915,16.532a.381.381,0,0,1-.762,0A6.028,6.028,0,0,1,6.629,12.57l-.6-.6a.381.381,0,0,1,.538-.539l.6.6a6.053,6.053,0,1,1,4.042,10.558H5.534a.381.381,0,0,1-.38-.357.381.381,0,0,1,.381-.4H7.6v-1.1H3.459a.381.381,0,1,1,0-.762H6.826v-1.1H1.384a.381.381,0,0,1,0-.762H9.131a.381.381,0,0,1,.381.381v3.341h1.694a5.291,5.291,0,1,0-5.292-5.291Zm5.56.269a.381.381,0,0,1-.539-.539l2.191-2.191a.381.381,0,0,1,.539.539ZM10.243,9.738a.381.381,0,0,1,0-.762h1.926a.381.381,0,1,1,0,.762Z"
            transform="translate(-1.003 -8.976)"
            fill={color}
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};

export default Eta;
