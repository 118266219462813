import Map from "../components/Order Track Components/Map";
import kitsaFavicon from "../assets/kitsa-logo-small.png";
import { useDispatch, useSelector } from "react-redux";
import { orderStatusApi } from "../api";
import { setPrevStatus, setUpdates } from "../features/apiCartSlice";
import Button from "../components/Order Track Components/Button";
import { useEffect, useRef, useState } from "react";
import mapOverlay from "../assets/map-overlay.json";
import Lottie from "lottie-react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import Accordian from "../components/Waiting Page Components/Accordian";
import ProductCardAvailable from "../components/Waiting Page Components/ProductCardAvailable";
import { Link, useNavigate } from "react-router-dom";
import { setShowComingSoon } from "../features/slidersSlice";
import ComingSoon from "../components/ComingSoon";

const WaitingScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { updates, prevStatus } = useSelector((state) => state.apiCart);
  const [tileNumber, setTileNumber] = useState(1);
  const [orderPlacedTime, setOrderPlacedTime] = useState(null);

  const getOrderUpdates = async () => {
    const access = localStorage.getItem("accessToken");
    const orderCode = localStorage.getItem("orderCode");
    try {
      const response = await fetch(orderStatusApi + orderCode, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        dispatch(setUpdates(data));
        if (data.status && data.status !== prevStatus) {
          dispatch(setPrevStatus(data.status));
        }
      } else if (response.status === 401) {
        localStorage.setItem("isAuthenticated", false);
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  };

  const intervalRef = useRef(null);
  useEffect(() => {
    if (localStorage.getItem("orderCode") !== "null") {
      getOrderUpdates();
      intervalRef.current = setInterval(() => {
        getOrderUpdates();
      }, 20000);
    }

    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTileNumber((prevState) => (prevState + 1) % 6);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (updates?.status === "ORDER MODIFIED") navigate("/alternates");
    if (
      updates?.status === "ALL AVAILABLE" ||
      updates?.status === "FULLY APPROVED" ||
      updates?.status === "RIDER ASSIGNED" ||
      updates?.status === "REACHED PICKUP LOCATION" ||
      updates?.status === "START TO PICKUP" ||
      updates?.status === "ORDER PICKED UP" ||
      updates?.status === "REACHED DELIVERY LOCATION" ||
      updates?.status === "DELIVERED" ||
      updates?.status === "CANCELLED DUE TO UNAVAILABILITY" ||
      updates?.status === "CANCELLED-OTC" ||
      updates?.status === "RETURNED" ||
      updates?.status === "CANCELLED BY USER" ||
      updates?.status === "OUT OF SERVICEABLE AREA" ||
      updates?.status === "NO MEDICINE AVAILABLE" ||
      updates?.invoice_url
    )
      navigate("/track-order");
  }, [updates]);

  // useEffect(() => {
  //   const datetime = updates?.created_at;
  //   const date = new Date(datetime);

  //   let hours = date.getHours();
  //   let minutes = date.getMinutes();

  //   const ampm = hours >= 12 ? "PM" : "AM";

  //   hours = hours % 12;
  //   hours = hours ? hours : 12;

  //   minutes = minutes < 10 ? "0" + minutes : minutes;

  //   const formattedTime = `${hours}:${minutes + 5} ${ampm}`;

  //   setOrderPlacedTime(formattedTime);
  // }, [updates]);

  useEffect(() => {
    const datetime = updates?.created_at;
    const date = new Date(datetime);

    // Add 5 minutes to the time
    date.setMinutes(date.getMinutes() + 5);

    let hours = date.getHours();
    let minutes = date.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    minutes = minutes < 10 ? "0" + minutes : minutes;

    const formattedTime = `${hours}:${minutes} ${ampm}`;

    setOrderPlacedTime(formattedTime);
  }, [updates]);

  const [time, setTime] = useState(300000);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1000 : 0));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60000)).padStart(2, "0");
    const seconds = String(Math.floor((time % 60000) / 1000)).padStart(2, "0");

    return `${minutes} ${seconds}`;
  };

  return (
    <div className="relative text-kitsaWhite h-screen overflow-x-hidden lg:w-[380px] 2xl:w-[430px]">
      <Link to="/" className="absolute z-10 left-0 top-0 py-3">
        <img src={kitsaFavicon} className="w-10" />
      </Link>

      <div className="relative">
        <div className="absolute top-0 right-0 z-[1] opacity-50 w-14">
          <Lottie animationData={mapOverlay} />
        </div>

        <Map
          latitude={parseFloat(updates?.shipping_address.latitude).toFixed(6)}
          longitude={parseFloat(updates?.shipping_address.longitude).toFixed(6)}
          showDeliverMessage={false}
          height="h-[50dvh] lg:h-[40dvh]"
        />
      </div>

      <div className="flex flex-col justify-between fixed bottom-0 left-0 bg-formisBlack w-full h-[60dvh] lg:h-[55dvh] rounded-t-[8px] pt-6">
        <div className="">
          <div className="flex justify-between gap-3 mb-6 px-3">
            <Swiper
              modules={[Navigation, Autoplay]}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              loop={true}
              className="flex items-center"
            >
              <SwiperSlide>
                <div className="flex items-center">
                  <p className="text-sm font-bold pr-2">
                    Your Order Has Been{" "}
                    <span className="text-kitsaGreen">Successfully Placed</span>
                    .
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <p className="text-sm font-medium pr-2">
                  Getting Availability From Fulfillment Hub. <br />{" "}
                  <span className="text-kitsaGreen">
                    Estimated Time: {orderPlacedTime}{" "}
                  </span>
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <div className="flex items-center">
                  <p className="text-sm font-medium pr-2">
                    <span className="text-kitsaGreen">Stay tuned!</span> Any
                    updates will appear here in real time.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>

            <a href="tel:+91-9911266699" className="w-[111px] flex justify-end">
              <Button>Help</Button>
            </a>
          </div>

          <div className="flex items-center justify-between gap-2 px-3">
            <div
              className={`${
                tileNumber >= 1 ? "bg-kitsaWhite" : "bg-[#666666]/50"
              } h-1 w-full rounded-full`}
            />
            <div
              className={`${
                tileNumber >= 2 ? "bg-kitsaWhite" : "bg-[#666666]/50"
              } h-1 w-full rounded-full`}
            />
            <div
              className={`${
                tileNumber >= 3 ? "bg-kitsaWhite" : "bg-[#666666]/50"
              } h-1 w-full rounded-full`}
            />
            <div
              className={`${
                tileNumber >= 4 ? "bg-kitsaWhite" : "bg-[#666666]/50"
              } h-1 w-full rounded-full`}
            />
            <div
              className={`${
                tileNumber >= 5 ? "bg-kitsaWhite" : "bg-[#666666]/50"
              } h-1 w-full rounded-full`}
            />
          </div>

          <div className="text-sm font-bold text-right mt-5 mb-2 px-3">
            <p>
              Order Status - Confirmation in{" "}
              <span className="text-kitsaGreen">{formatTime(time)} 00</span>
            </p>
          </div>

          <Accordian
            title="Order Created"
            open={true}
            items={updates?.order_items.length}
          >
            <div className="flex gap-4 overflow-x-scroll">
              <Swiper modules={[Navigation, Autoplay]} loop={true}>
                {updates?.order_items.map((item, index) => (
                  <SwiperSlide key={index}>
                    <ProductCardAvailable product={item} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Accordian>

          <div className="mx-3 mt-2">
            <div className="bg-formisBlack font-semibold text-xs h-[38px] border border-kitsaWhite flex items-center justify-between w-full rounded-[10px] px-[6px]">
              <p className="text-xs  font-semibold text-kitsaWhite">
                Explore nearby vouchers
              </p>

              <Button onClick={() => dispatch(setShowComingSoon(true))}>
                View
              </Button>
            </div>
          </div>
        </div>

        <div className="lg:mb-1 flex items-center justify-between gap-4 mt-5 bg-kitsaGreen">
          <Swiper
            modules={[Navigation, Autoplay]}
            autoplay={{
              delay: 8000,
              disableOnInteraction: false,
            }}
            loop={true}
            className="text-center text-sm"
          >
            <SwiperSlide>
              <p className="font-[600] font-[FranklinGothic]">
                ❄️ Temperature-Controlled Packaging
              </p>
            </SwiperSlide>
            <SwiperSlide>
              <p className="font-[600] font-[FranklinGothic]">
                🔒 100% Discreet & Tamper-Proof Packaging
              </p>
            </SwiperSlide>
            <SwiperSlide>
              <p className="font-[600] font-[FranklinGothic]">
                ✅ Authentic Products with Barcodes & Labels
              </p>
            </SwiperSlide>
            <SwiperSlide>
              <p className="font-[600] font-[FranklinGothic]">
                ❤️ Packed with Care by KITSA™
              </p>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <ComingSoon />
    </div>
  );
};

export default WaitingScreen;
