import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTitleUpdater = () => {
  const location = useLocation();

  const metaDescription = document.querySelector('meta[name="description"]');

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        document.title = "KITSA™ | Your Wellness Connection";
        metaDescription.content =
          "KITSA™—Your Wellness Connection. Essentials, consults & labs in 20 mins. Care is around the corner!";
        break;

      case "/privacy-policy": 
        document.title = "KITSA™ | Privacy";
        metaDescription.content =
          "KITSA™—Your Wellness Connection. Essentials, consults & labs in 20 mins. Care is around the corner!";
        break;

      case "/shipping-and-refunds-policy":
        document.title = "KITSA™ | Shipping and Refunds";
        metaDescription.content =
          "KITSA™—Your Wellness Connection. Essentials, consults & labs in 20 mins. Care is around the corner!";
        break;

      case "/terms":
        document.title = "KITSA™ | Terms";
        metaDescription.content =
          "KITSA™—Your Wellness Connection. Essentials, consults & labs in 20 mins. Care is around the corner!";
        break;

      case "/contact":
        document.title = "KITSA™ | Contact";
        metaDescription.content =
          "KITSA™—Your Wellness Connection. Essentials, consults & labs in 20 mins. Care is around the corner!";
        break;

      case "/cart":
        document.title = "KITSA™ | Cart";
        metaDescription.content =
          "KITSA™—Your Wellness Connection. Essentials, consults & labs in 20 mins. Care is around the corner!";
        break;

      case "/track-order":
        document.title = "KITSA™ | Track Order";
        metaDescription.content =
          "KITSA™—Your Wellness Connection. Essentials, consults & labs in 20 mins. Care is around the corner!";
        break;

      default:
        document.title = "KITSA™ | Your Wellness Connection";
    }
  }, [location.pathname]);

  return null;
};

export default PageTitleUpdater;
