import React from 'react';

const CloseIcon = ({ color = '#f1edeb', width = 32, height = 32 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 34 34"
    fill={color}
  >
    <g
      id="noun-close-582558"
      transform="translate(464.032 71.277)"
      style={{ mixBlendMode: 'normal', isolation: 'isolate' }}
    >
      <g
        id="Group_76"
        data-name="Group 76"
        transform="translate(-464.032 -71.277)"
      >
        <path
          id="Path_158"
          data-name="Path 158"
          d="M27.4,4.639A16.027,16.027,0,0,0,4.739,27.3,16.027,16.027,0,1,0,27.4,4.639ZM18.579,20.685l-2.608-2.608-2.608,2.608a1.418,1.418,0,0,1-2.006-2.006l2.608-2.608-2.608-2.608a1.418,1.418,0,1,1,2.006-2.006l2.608,2.608,2.608-2.608a1.418,1.418,0,0,1,2.006,2.006l-2.608,2.608,2.608,2.608a1.418,1.418,0,0,1-2.006,2.006Z"
          transform="translate(-0.075 0.025)"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default CloseIcon;
