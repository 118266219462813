//MUST TRY - MUST TRY
import centrumMen from "../../assets/must try products/Centrum Men.avif";
import fcl from "../../assets/must try products/FCL.webp";
import hkFishOil from "../../assets/must try products/HK Vitals Fish Oil.jpg";
import minimalist from "../../assets/must try products/Minimalist.webp";
import omron from "../../assets/must try products/Omron.png";
import perfora from "../../assets/must try products/perfora.jpg";
import tynor from "../../assets/must try products/Tynor.jpg";

//BRAND CARD - PRODUCTS OF THE WEEK
import centrumWomen from "../../assets/product of the day/Centrum women.jpg";
import durex from "../../assets/product of the day/Durex performa.jpg";
import fastAndUp from "../../assets/product of the day/Fast and up vitalise poster .png";
import hkvitals from "../../assets/product of the day/hkvitals collagen.jpg";
import kapiva from "../../assets/product of the day/Kapiva shilajit.jpg";

//BRAND CARD - BRANDS OF THE WEEK
import dermaco from "../../assets/brand of the day/Derma Co.jpg";
import mintop from "../../assets/brand of the day/Mintop.jpg";
import onWhey from "../../assets/brand of the day/On Whey Protein.webp";
import peeSafe from "../../assets/brand of the day/Pee Safe.jpg";
import vicks from "../../assets/brand of the day/Vicks Brand.webp";

//MUST TRY - DIABETES CARE
import drmorepen from "../../assets/must try products/diabetes care/Dr. Morepen.png";
import ensure from "../../assets/must try products/diabetes care/Ensure Diabetes .webp";
import freestylelibre from "../../assets/must try products/diabetes care/Freestyle Libre.png";
import himalaya from "../../assets/must try products/diabetes care/Himalaya.jpg";
import kapivaDiabetes from "../../assets/must try products/diabetes care/Kapiva.png";
import sugarFree from "../../assets/must try products/diabetes care/Sugar Free.webp";

//FREQUENTLY BOUGHT
import ahaglow from "../../assets/frequently bought/Ahaglow.jpg"
import neurobin from "../../assets/frequently bought/Neurobion Forte.jpg"
import otrivinAdvance from "../../assets/frequently bought/Otrivin Advance.png"
import photostable from "../../assets/frequently bought/Photostable Gold Sunscreen.webp"
import refreshTears from "../../assets/frequently bought/Refresh Tears.webp"
import revital from "../../assets/frequently bought/Revital H Men.webp"
import sesderma from "../../assets/frequently bought/Sesderma.webp"
import sevenSeas from "../../assets/frequently bought/Seven Seas COD Liver Oil.png"
import Threptin from "../../assets/frequently bought/Threptin Diskettes.webp"

export const mustTryData = {
  data: [
    { image: centrumMen, search_query: "Centrum Men" },
    { image: fcl, search_query: "Fcl Detan" },
    { image: hkFishOil, search_query: "HK Vitals Fish Oil" },
    { image: minimalist, search_query: "Minimalist Anti Pigmentation Serum" },
    { image: omron, search_query: "Omron Blood Pressure" },
    { image: perfora, search_query: "Perfora Dream Relief Toothpaste" },
    { image: tynor, search_query: "Tynor Sport" },
  ],
};

export const brandCardProductData = {
  data: [
    { image: centrumWomen, search_query: "Centrum Women" },
    { image: durex, search_query: "Durex Performa for Women" },
    { image: fastAndUp, search_query: "Fast and Up Vitalize" },
    { image: hkvitals, search_query: "HK Vitals Collagen" },
    { image: kapiva, search_query: "Kapiva Shilajit" },
  ],
};

export const brandCardBrandData = {
  data: [
    { image: dermaco, search_query: "Derma Co" },
    { image: mintop, search_query: "Mintop" },
    { image: onWhey, search_query: "On Whey Protein" },
    { image: peeSafe, search_query: "Pee Safe" },
    { image: vicks, search_query: "Vicks" },
  ],
};

export const diabetesData = {
  data: [
    { image: drmorepen, search_query: "Derma Co" },
    { image: ensure, search_query: "Mintop" },
    { image: freestylelibre, search_query: "On Whey Protein" },
    { image: himalaya, search_query: "Pee Safe" },
    { image: kapivaDiabetes, search_query: "Vicks" },
    { image: sugarFree, search_query: "Vicks" },
  ],
};

export const frequentlyBought = {
    resutls: [
        {}
    ]
}