import React from "react";

const DRain = ({color, width}) => {
  return (
    <svg id="Glyph" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill={color} width={width}>
      <path d="M57.84,20.8c-.74,3.62-3.95,6.34-7.78,6.34H25.61c-4.75,0-8.61-3.86-8.61-8.61,0-3.59,2.21-6.75,5.49-8.02,.75-1.63,1.98-2.94,3.49-3.81-1.82-1.08-3.95-1.71-6.22-1.71-6.76,0-12.24,5.48-12.24,12.24,0,1.14,.17,2.24,.46,3.29-2.4,1.62-3.98,4.36-3.98,7.47,0,4.97,4.03,9,9,9H50c5.52,0,10-4.48,10-10,0-2.34-.81-4.49-2.16-6.2Z" />
      <path d="M53.37,14.26c.19-.69,.3-1.42,.3-2.17,0-4.47-3.62-8.09-8.09-8.09-1.82,0-3.49,.61-4.84,1.62-1.2-.6-2.55-.95-3.99-.95-2.6,0-4.94,1.12-6.57,2.89-2.9,.02-5.35,1.93-6.18,4.56-2.88,.72-5.01,3.31-5.01,6.4,0,3.65,2.96,6.61,6.61,6.61h24.45c3.28,0,5.95-2.66,5.95-5.95,0-2.06-1.04-3.87-2.63-4.94Z" />
      <g>
        <path d="M10.92,45.6c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M9.7,52.8c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M8.47,60c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M16.92,45.6c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M15.7,52.8c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M14.47,60c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M22.92,45.6c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M21.7,52.8c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M20.47,60c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M28.92,45.6c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M27.7,52.8c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M26.47,60c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M34.92,45.6c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M33.7,52.8c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M32.47,60c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M40.92,45.6c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M39.7,52.8c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M38.47,60c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M46.92,45.6c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M45.7,52.8c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M44.47,60c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M52.92,45.6c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M51.7,52.8c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82s.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
        <path d="M50.47,60c-.06,0-.11,0-.17-.01-.54-.09-.91-.61-.82-1.15l.61-3.6c.09-.54,.61-.91,1.15-.82,.54,.09,.91,.61,.82,1.15l-.61,3.6c-.08,.49-.51,.83-.99,.83Z" />
      </g>
    </svg>
  );
};

export default DRain;
