const Button = ({
  children,
  onClick,
  color = "kitsaGreen",
  textColor = "kitsaWhite",
  disabled,
}) => {
  return (
    <button
      disabled={disabled}
      onTouchStart={(e) =>
        e.currentTarget.classList.add("scale-95", "opacity-70")
      }
      onTouchEnd={(e) =>
        e.currentTarget.classList.remove("scale-95", "opacity-70")
      }
      onClick={onClick}
      className={`font-medium text-[11px] bg-${color} h-[28px] flex items-center justify-center w-[111px] rounded-[8px] text-${textColor} transition-all`}
    >
      {children}
    </button>
  );
};

export default Button;
