import { useEffect, useRef, useState } from "react";
import Lottie from "lottie-react";
import animatedPin from "../../assets/map-pin.json";

const Map = ({ latitude, longitude, showDeliverMessage = true, height="40dvh" }) => {
  const google = window.google;
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const [mapLoaded, setMapLoaded] = useState();

  const defaultCoordinates = { lat: 28.48218, lng: 77.0933 };

  useEffect(() => {
    const checkGoogleMaps = setInterval(() => {
      if (window.google && window.google.maps) {
        setMapLoaded(true);
        clearInterval(checkGoogleMaps);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    const initMap = () => {
      if (mapLoaded) {
        const mapOptions = {
          center: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
          zoom: 16,
          draggable: false,
          zoomControl: false,
          scrollwheel: false,
          disableDoubleClickZoom: true,
          disableDefaultUI: true,
          gestureHandling: "greedy",
        };
        mapInstance.current = new google.maps.Map(mapRef.current, mapOptions);
      }
    };

    if (window.google && window.google.maps) {
      initMap();
    } else {
      window.addEventListener("load", initMap);
    }
    return () => {
      window.removeEventListener("load", initMap);
    };
  }, [mapRef, mapLoaded]);

  return (
    <div className={`relative ${height} w-full rounded-[inherit]`}>
      <div className="absolute top-0 left-0 z-10 flex items-center justify-center pointer-events-none h-full w-full">
        <div className="w-[3.25rem]">
          <Lottie animationData={animatedPin} />
        </div>
      </div>

      <div
        id="map"
        ref={mapRef}
        className="rounded-[inherit] h-full w-full"
      ></div>

      {showDeliverMessage && (
        <div className="absolute top-[55%] left-1/2 z-10">
          <p className="bg-kitsaGreen w-fit text-[10px] font-bold p-1 rounded-full shadow-md shadow-formisBlack">
            We will deliver your order here.
          </p>
        </div>
      )}
    </div>
  );
};

export default Map;
