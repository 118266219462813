import { Link } from "react-router-dom";

const SponsorCard = ({ featured, name }) => {
  return (
    <div className={`text-kitsaWhite bg-transparent`}>
      <div className="flex items-center justify-between mb-6 px-4">
        <h5 className="text-sm font-bold">{name}</h5>
        <div className="flex items-center eta-shadow text-kitsaWhite gap-2 px-[5px] py-[2px]">
          <p className="text-[11px] tracking-[-0.28px] font-bold leading-none">
            Curated
          </p>
        </div>
      </div>

      <div className="flex items-center overflow-x-scroll gap-4 pb-5 scrollbar-hide px-4">
        {featured?.map((result) => {
          const formatTitle = (title) => {
            return title
              .toLowerCase()
              .replace(/\s+/g, "-")
              .replace(/[^\w\-]+/g, "");
          };

          const formattedTitle = formatTitle(result.title);

          return (
            <Link
              to={`/blog/${formattedTitle}`}
              className="relative w-[50%] shrink-0 border border-[#666666] h-[200px] rounded-[10px] shadow-peachBox bg-cover bg-no-repeat object-cover"
              key={result.id}
            >
              <button className="absolute -bottom-2 -right-2 place-self-end text-formisBlack font-[MestizaSans] font-bold text-[11px] bg-kitsaWhite h-[28px] flex items-center justify-center px-4 rounded-[8px] shadow-md shadow-kitsaWhite">
                READ
              </button>

              <img
                src={result.media_file}
                alt=""
                className="w-full h-full rounded-[inherit] object-cover"
              />
            </Link>
          );
        })}

        <a
          target="_blank"
          rel="noreferrer"
          href="https://kitsaindia.medium.com/"
          className="bg-kitsaGreen text-kitsaWhite shrink-0 px-4 py-2 rounded-[8px] ml-5 font-medium"
        >
          Read More
        </a>
      </div>
    </div>
  );
};

export default SponsorCard;
