import React from "react";

const TickLine = ({ width, color }) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 128 128"
      width={width}
    >
      <g id="_x31_8">
        <polygon
          id="icon_17_"
          fill={color}
          points="41.371,115.427 0,74.057 16.228,57.83 41.371,82.972 111.772,12.573 128,28.801"
        />
      </g>
      <g id="Layer_1"></g>
    </svg>
  );
};

export default TickLine;
