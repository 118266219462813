import Categories from "./Categories";
import { useEffect, useState } from "react";
import { categoryProductsApi, recentSearchApi } from "../../api";

import all from "../../assets/icons/all.svg";
import beauty from "../../assets/icons/beauty.svg";
import sports from "../../assets/icons/sports.svg";
import everyday from "../../assets/icons/everyday.svg";
import close from "../../assets/icons/close-peach.svg";
import { useDispatch, useSelector } from "react-redux";
import AnimatedSearch from "./AnimatedSearch";
import { setSearchQuery } from "../../features/searchSlice";
import { useNavigate } from "react-router-dom";

import ayurved from "../../assets/icons/ayurved.svg";
import remedies from "../../assets/icons/remedies.svg";
import wellness from "../../assets/icons/wellness.svg";
import protect from "../../assets/icons/protect+.svg";
import CloseIcon from "../../assets/icon components/CloseIcon";
import EndText from "../EndText";

const MainPage = ({ handlePageChange, toggleSearch }) => {
  const categories = [
    "Skin, hair & oral care",
    "Stomach care",
    "Sexual wellness",
    "Feminine care",
    "Fitness supplements",
    "Vitamins & nutrition",
    "Ayurvedic supplements",
    "Nutritional drinks",
  ];
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSearchOpen = useSelector((state) => state.search.isSearchOpen);
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const [results, setResults] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);

  //FETCH ALL CATEGORIES AND THEIR PRODUCTS TO DISPLAY ON MAIN SEARCH PAGE
  const fetchCategory = async () => {
    try {
      const responses = await Promise.all(
        categories.map((category) =>
          fetch(categoryProductsApi + category).then((response) => {
            if (!response.ok) {
              throw new Error(`Failed to fetch for parameter: ${category}`);
            }
            return response.json();
          })
        )
      );
      setResults(responses);
    } catch {}
  };

  //FETCH RECENT SEARCHED IF USER IS LOGGED IN
  const fetchRecentSearch = async () => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(recentSearchApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        setRecentSearches(data);
        // console.log("recent searches", data);
      }
    } catch {}
  };

  //FETCH RECENT SEARCHED IF USER IS NOT LOGGED IN
  const fetchRecentGuestSearch = async () => {
    const guestToken = localStorage.getItem("guestToken");
    try {
      const response = await fetch(
        recentSearchApi + `?guest_token=${guestToken}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setRecentSearches(data);
        // console.log("recent searches", data);
      }
    } catch {}
  };

  useEffect(() => {
    if (isSearchOpen) {
      fetchCategory();
      if (isAuthenticated === "true") fetchRecentSearch();
      else fetchRecentGuestSearch();
    }
  }, [isSearchOpen]);

  return (
    <div className="overflow-scroll pb-64">
      <div className="pt-2 pb-3 px-2 sticky z-[29] top-0 bg-formisBlack left-0 cursor-text">
        <AnimatedSearch
          toggleSearch={toggleSearch}
          handlePageChange={handlePageChange}
        />
      </div>

      <div className="flex items-center gap-[56px] scrollbar-hide overflow-x-scroll text-kitsaWhite px-[22px] md:px-10 md2:px-14 lg:px-10 xl:px-12">
        <div
          className="flex flex-col gap-[6px] items-center shrink-0"
          onClick={() => {
            navigate("all-products");
            toggleSearch();
          }}
        >
          <img src={all} alt="All" className="w-[40px]" />
          <p className="text-xs font-bold tracking-wider font-[MestizaSans]">
            All
          </p>
        </div>
        <div
          className="flex flex-col gap-[6px] items-center shrink-0"
          onClick={() => {
            navigate(`/categories/Skin, hair & oral care`);
            toggleSearch();
          }}
        >
          <img src={beauty} alt="All" className="w-[40px]" />
          <p className="text-xs font-bold tracking-wider font-[MestizaSans]">
            Beauty
          </p>
        </div>
        <div
          className="flex flex-col gap-[6px] items-center shrink-0"
          onClick={() => {
            navigate(`/categories/Fitness supplements`);
            toggleSearch();
          }}
        >
          <img src={sports} alt="All" className="w-[40px]" />
          <p className="text-xs font-bold tracking-wider font-[MestizaSans]">
            Sports
          </p>
        </div>
        <div
          className="flex flex-col gap-[6px] items-center shrink-0"
          onClick={() => {
            navigate(`/categories/Vitamins & nutrition`);
            toggleSearch();
          }}
        >
          <img src={everyday} alt="All" className="w-[40px]" />
          <p className="text-xs font-bold tracking-wider font-[MestizaSans]">
            Everyday
          </p>
        </div>

        <div
          className="flex flex-col gap-[6px] items-center shrink-0"
          onClick={() => {
            navigate("/categories/Ayurvedic Supplements");
            toggleSearch();
          }}
        >
          <img src={ayurved} alt="All" className="w-[40px]" />
          <p className="text-xs font-bold tracking-wider font-[MestizaSans]">
            Ayurved
          </p>
        </div>
        <div
          className="flex flex-col gap-[6px] items-center shrink-0"
          onClick={() => {
            navigate(`/categories/Wellness`);
            toggleSearch();
          }}
        >
          <img src={wellness} alt="All" className="w-[40px]" />
          <p className="text-xs font-bold tracking-wider font-[MestizaSans]">
            Wellness
          </p>
        </div>
        <div
          className="flex flex-col gap-[6px] items-center shrink-0"
          onClick={() => {
            navigate(`/categories/Cold, Cough & Fever`);
            toggleSearch();
          }}
        >
          <img src={remedies} alt="All" className="w-[40px]" />
          <p className="text-xs font-bold tracking-wider font-[MestizaSans]">
            Remedies
          </p>
        </div>
        <div
          className="flex flex-col gap-[6px] items-center shrink-0"
          onClick={() => {
            navigate(`/categories/Protect+ Essentials`);
            toggleSearch();
          }}
        >
          <img src={protect} alt="All" className="w-[40px]" />
          <p className="text-xs font-bold tracking-wider font-[MestizaSans]">
            Protect+
          </p>
        </div>
      </div>

      {recentSearches.length > 0 && (
        <div className="px-[15px] pt-[22px]">
          <h5 className="text-sm font-bold">Recent Searches</h5>
          <div className="grid grid-cols-3 gap-y-[5px] pt-3">
            {recentSearches?.slice(-9).map((recent) => (
              <RecentSearch
                recent={recent}
                key={recent.id}
                handlePageChange={handlePageChange}
                setRecentSearches={setRecentSearches}
              />
            ))}
          </div>
        </div>
      )}

      <div className="pt-10">
        {results.map((res, index) => (
          <Categories
            key={index}
            result={res}
            handlePageChange={handlePageChange}
            name={categories[index]}
          />
        ))}
      </div>

      <EndText />
    </div>
  );
};

export default MainPage;

const RecentSearch = ({ recent, setRecentSearches, handlePageChange }) => {
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const deleteRecentSearch = async (query) => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(recentSearchApi + `?query=${query}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        // console.log("deleted", data);
        setRecentSearches(data);
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const deleteRecentGuestSearch = async (query) => {
    const guestToken = localStorage.getItem("guestToken");
    try {
      const response = await fetch(
        recentSearchApi + `?query=${query}&guest_token=${guestToken}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        setRecentSearches(data);
        // console.log("recent searches", data);
      }
    } catch (err) {
      // console.log("ERRORRRRRRR", err)
    }
  };

  return (
    <div className="relative bg-kitsaWhite w-[100px] rounded-[8px]">
      <p
        onClick={() => {
          dispatch(setSearchQuery(recent.query));
          handlePageChange("search");
        }}
        className="capitalize text-[10px] font-bold bg-kitsaWhite text-formisBlack border border-kitsaWhite rounded-full tracking-[-0.25px] flex items-center justify-center py-[2px]"
      >
        {recent.query.length > 13
          ? recent.query.slice(0, 13) + "..."
          : recent.query}
      </p>
      <div
        className="absolute -top-1 right-0"
        onClick={() => {
          if (isAuthenticated === "true") deleteRecentSearch(recent.query);
          else deleteRecentGuestSearch(recent.query);
        }}
      >
        <CloseIcon color="#47b24f" width="12" height="12" />
      </div>
    </div>
  );
};
