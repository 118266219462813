import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import imageNA from "../assets/Image-NA.webp";
import { MdNavigateNext } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from "../features/cartSlice";
import { addToCartApi, deleteFromCartApi } from "../api";

import discount from "../assets/icons/discount.svg";
import AddressSlider from "../components/AddressSlider";
import SurgeSlider from "../components/SurgeSlider";
import MobileFooter from "../components/MobileFooterMenu";
import Search from "../components/Search";
import Profile from "../components/Profile";

import ViewCartButton from "../components/Footer Menu Buttons/ViewCartButton";
import { setUserCartItems } from "../features/apiCartSlice";

import Minus from "../assets/icon components/Minus";
import Plus from "../assets/icon components/Plus";

const CategoryPage = () => {
  const { categoryName } = useParams();
  const [productsData, setProductsData] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const userCart = useSelector((state) => state.apiCart.userCartItems);

  const fetchProducts = async () => {
    try {
      const response = await fetch(
        `https://kitsa.co.in/api/v1/medicines/category/${categoryName}/?page=${currentPage}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (response.ok) {
        setProductsData(data);
      }
    } catch {}
  };

  useEffect(() => {
    fetchProducts();
  }, [currentPage]);

  const handlePreviousClick = () => {
    setCurrentPage(currentPage - 1);
    window.scrollTo(0, 300);
  };
  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
    window.scrollTo(0, 300);
  };

  return (
    <div className="bg-formisBlack text-kitsaWhite h-screen overflow-y-scroll overflow-x-hidden scrollbar-hide">
      <Topbar />
      <div className="bg-formisBlack pt-12">
        <Header />
      </div>

      <h2 className="title-md leading-none font-bold my-5 px-3">
        {categoryName}
      </h2>

      <div className="flex flex-wrap gap-y-8 justify-between rounded-t-[10px] px-4 pb-10">
        {productsData &&
          productsData.results.map((product, index) => (
            <ProductCard key={index} res={product} userCart={userCart} />
          ))}
      </div>

      <div className="flex gap-5 items-center justify-center pb-10">
        {currentPage !== 1 && (
          <button
            className="bg-kitsaWhite text-formisBlack py-3 px-5 rounded-full flex items-center justify-center gap-2"
            onClick={handlePreviousClick}
          >
            <MdNavigateNext className="rotate-180 text-xl" />
            Previous
          </button>
        )}
        <button
          className="bg-kitsaWhite text-formisBlack py-3 px-5 rounded-full flex items-center justify-center gap-2"
          onClick={handleNextClick}
        >
          Next <MdNavigateNext />
        </button>
      </div>

      <Footer />

      <AddressSlider />
      <SurgeSlider />
      <div className="fixed z-[30] bottom-0 w-full left-0">
        <ViewCartButton />
        <MobileFooter showBack={true} />
      </div>
      <Search />
      <Profile />
    </div>
  );
};

export default CategoryPage;

const ProductCard = ({ res, userCart }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartItem = useSelector((state) =>
    state.cartItems.items.find(
      (cartItem) => cartItem.product_id === res.product_id
    )
  );

  const userCartItem = userCart?.find(
    (item) => item.product_id === res.product_id
  );

  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const [productQuantity, setProductQuantity] = useState(
    userCartItem?.quantity || 1
  );

  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    navigate(`/product/${res.slug}`);
  };

  const handleAddToCart = () => {
    if (loading) return;
    if (isAuthenticated === "true") {
      handleAddToCartApiCall(productQuantity);
    } else dispatch(addToCart(res));
  };

  const handleDecrement = () => {
    if (loading) return;
    if (productQuantity > 1) {
      const updatedQuantity = productQuantity - 1;
      setProductQuantity(updatedQuantity);
      handleAddToCartApiCall(updatedQuantity);
    } else {
      handleDeleteApiCall();
    }
  };

  const handleReduxDecrement = () => {
    if (cartItem?.quantity === 1) dispatch(removeFromCart(cartItem));
    if (cartItem?.quantity > 1) {
      dispatch(decrementQuantity({ product_id: res.product_id }));
    }
  };

  const handleIncrement = () => {
    if (loading) return;
    const updatedQuantity = productQuantity + 1;
    setProductQuantity(updatedQuantity);
    if (isAuthenticated === "true") {
      handleAddToCartApiCall(updatedQuantity);
      // dispatch(incrementQuantity({ product_id: res.product_id }));
    } else dispatch(incrementQuantity({ product_id: res.product_id }));
  };

  const handleAddToCartApiCall = async (quantity) => {
    const access = localStorage.getItem("accessToken");
    setLoading(true);
    try {
      const response = await fetch(addToCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          cart_data: [
            {
              product_id: res.product_id,
              quantity,
            },
          ],
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // dispatch(addToCart(res));
        dispatch(setUserCartItems(data));
        // localStorage.setItem("userCart", true)
      } else {
      }
    } catch (error) {
      // console.error("Failed to add to cart:", error);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  const handleDeleteApiCall = async () => {
    const access = localStorage.getItem("accessToken");
    setLoading(true);
    try {
      const response = await fetch(deleteFromCartApi, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({
          product_ids: [res.product_id],
        }),
      });

      const data = await response.json();

      if (response.ok) {
        dispatch(removeFromCart(cartItem));
        dispatch(setUserCartItems(data));
      } else {
      }
    } catch (error) {
      // console.error("Failed to delete:", error);
    } finally {
      setLoading(false); // Stop loader
    }
  };

  return (
    <div className="w-[145.65px]">
      <div className="relative mb-4">
        <div
          onClick={handleClick}
          className="p-[5px] bg-white rounded-[10px] h-[144px] w-[145.65px]"
        >
          <img
            src={
              res.image_url !== "NA" &&
              res.image_url !==
                "http://34.131.96.18/wp-content/uploads/2024/11/Image-NA.png"
                ? res.image_url
                : "https://media-hosting.imagekit.io//a3385a43077f4e0e/Image%20Not%20Available.png?Expires=1833358862&Key-Pair-Id=K2ZIVPTIP2VGHC&Signature=h-gValwZSoRKUBi9iEHAzKOKdwPlgYLNYSi5K7sAEH2knqMt4gpgaf~GJytsoI9gQemKj-5mIaJ4SPminI9Ts4j4esZ4j-udW3LgKjxS5Vn7goq6Z4bhS~5hzKy4uzSjttW1KAoemJvrM3em5fiBlImWlXQ6lOkyt79rhvtE11KwbRFH2w2pbdXPemroPpZxgHLRAAJ6EEsWL-psJxPosRF6LZz5giAWuUNGeem~P0IieT38Qlzvb6~nyJ96og4-XODPBV46s6vk3amWOa20pBrPSDP0X6a7Tjwpo9vunyMapTz9U0ucJqtk~c6yn9kD-YcgMIlQtPJmOG8AbIdtSA__"
            }
            loading="lazy"
            className="aspect-square rounded-[10px]"
            alt="Product"
          />
          <div className="h-[28px] w-[23px] absolute -top-[7px] -left-[1px]">
            <img src={discount} alt="discount!" className="" />
            <div className="absolute top-0 left-0 h-[28px] w-[23px] flex items-center justify-center">
              <p className=" text-[10px] text-formisBlack font-bold leading-none">
                {parseInt(Math.floor(res.discount_percentage.slice(0, -1)))}%
                <br /> OFF
              </p>
            </div>
          </div>
        </div>

        {isAuthenticated === "true" ? (
          <div className="absolute -bottom-3 -right-2 font-bold rounded-[10px] text-sm bg-kitsaWhite">
            {userCartItem ? (
              <div className="bg-kitsaGreen text-kitsaWhite h-[30px] rounded-[10px] absolute -bottom-3 -right-0 flex gap-2 items-center mb-3">
                <button
                  disabled={loading}
                  className="pl-3 py-2"
                  onClick={handleDecrement}
                >
                  {userCartItem?.quantity === 1 || productQuantity === 1 ? (
                    <div className="rotate-45">
                      <Plus width={15} color="#f1edeb" />
                    </div>
                  ) : (
                    <Minus width={15} color="#f1edeb" />
                  )}
                </button>
                {!loading ? (
                  <input
                    type="number"
                    min="1"
                    value={userCartItem?.quantity}
                    readOnly
                    className="max-w-4 bg-transparent rounded-[5px] text-center outline-none"
                  />
                ) : (
                  <div className="loader2"></div>
                )}
                <button className="pr-3 py-2" onClick={handleIncrement}>
                  <Plus width={15} color="#f1edeb" />
                </button>
              </div>
            ) : (
              <div
                onClick={handleAddToCart}
                className="absolute -bottom-0 right-1 h-[30px] text-xs font-medium rounded-[10px] bg-kitsaWhite/90 text-formisBlack"
              >
                <button className="h-full w-[71px] font-semibold rounded-[10px]">
                  ADD
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="absolute -bottom-3 -right-2 font-bold rounded-[10px] text-sm bg-kitsaWhite">
            {cartItem ? (
              <div className="bg-kitsaGreen text-kitsaWhite h-[30px] rounded-[10px] absolute -bottom-3 -right-0 flex gap-2 items-center mb-3">
                <div className="pl-3 py-2" onClick={handleReduxDecrement}>
                  {cartItem?.quantity === 1 ? (
                    <div className="rotate-45">
                      <Plus width={15} color="#f1edeb" />
                    </div>
                  ) : (
                    <Minus width={15} color="#f1edeb" />
                  )}
                </div>
                <input
                  type="number"
                  min="1"
                  value={cartItem?.quantity}
                  readOnly
                  className="max-w-4 bg-transparent rounded-[5px] text-center outline-none"
                />
                <div className="pr-3 py-2" onClick={handleIncrement}>
                  <Plus width={15} color="#f1edeb" />
                </div>
              </div>
            ) : (
              <div
                onClick={handleAddToCart}
                className="absolute -bottom-0 right-1 h-[30px] text-xs font-medium rounded-[10px] bg-kitsaWhite/90 text-formisBlack"
              >
                <button className="h-full w-[71px] font-semibold rounded-[10px]">
                  ADD
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <div className="flex items-center gap-1 mb-[6px]">
        {res.package && (
          <div className="bg-kitsaWhite rounded-full">
            <span className="text-[10px] font-bold px-1 leading-none h-[13px] tracking-[-0.25px] flex items-center justify-center rounded-[10px] bg-kitsaWhite text-formisBlack border border-kitsaWhite">
              {res.package}
            </span>
          </div>
        )}
      </div>

      <div className="flex w-full items-center justify-between">
        <div>
          <p className=" font-[500] text-[11px] leading-4">
            {res.name.length > 42 ? res.name.slice(0, 42) + "..." : res.name}
          </p>

          <div className="flex items-center gap-2 ">
            <div className="flex items-center gap-1">
              <p className="text-[10px] font-[500]">
                ₹{res.final_price.toFixed(2)}
              </p>
              |
              <span className="text-[10px] font-[500]">
                MRP <s>₹{res.mrp.toFixed(2)}</s>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
