import React from "react";

const NSun = ({color, width}) => {
  return (
    <svg id="Glyph" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" fill={color} width={width}>
      <path d="M57.06,13.36c-1.13-3.18-4.16-5.34-7.72-5.45-2.39-.08-4.03,.9-4.03,.9-.91-.68-2.04-1.09-3.27-1.09-3.02,0-5.46,2.45-5.46,5.46,0,.51,.08,1,.21,1.47-1.07,.72-1.78,1.95-1.78,3.34,0,2.22,1.8,4.02,4.02,4.02h16.52c2.47,0,4.46-2,4.46-4.46,0-1.93-1.23-3.55-2.94-4.18Z" />
      <path d="M48,46c-14.36,0-26-11.64-26-26,0-5.84,1.93-11.24,5.18-15.58C14.02,6.71,4,18.18,4,32c0,4.63,1.13,8.99,3.12,12.84,1.14-.71,2.48-1.12,3.92-1.12,1.2,0,2.37,.29,3.42,.83,.85-.31,2.1-.65,3.61-.65,.11,0,.22,0,.33,0,4.07,.13,7.6,2.44,9.21,5.96,2.06,1.12,3.39,3.29,3.39,5.67,0,1.67-.64,3.19-1.68,4.33,.88,.08,1.78,.13,2.68,.13,11.03,0,20.57-6.38,25.13-15.65-2.84,1.07-5.92,1.65-9.13,1.65Z" />
      <path d="M26.06,51.36c-1.13-3.18-4.16-5.34-7.72-5.45-2.39-.08-4.03,.9-4.03,.9-.91-.68-2.04-1.09-3.27-1.09-3.02,0-5.46,2.45-5.46,5.46,0,.51,.08,1,.21,1.47-1.07,.72-1.78,1.95-1.78,3.34,0,2.22,1.8,4.02,4.02,4.02H24.54c2.47,0,4.46-2,4.46-4.46,0-1.93-1.23-3.55-2.94-4.18Z" />
    </svg>
  );
};

export default NSun;
