import cart from "../../assets/icons/cart.svg";
import back from "../../assets/icons/back-peach.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSearchComponent } from "../../features/searchSlice";
import { useEffect, useState } from "react";
import { currentUserOrderApi } from "../../api";
import BackIcon from "../../assets/icon components/Back";
import { setUpdates } from "../../features/apiCartSlice";

const ViewCartButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const { items } = useSelector((state) => state.cartItems);
  const userCart = useSelector((state) => state.apiCart.userCartItems);

  const [isOngoingOrder, setIsOngoingOrder] = useState(null);

  const [cartCount, setCartCount] = useState(userCart?.length || 0);
  const [cartCountLocal, setCartCountLocal] = useState(items?.length || 0);
  const [isBouncing, setIsBouncing] = useState(false);

  useEffect(() => {
    if (isAuthenticated === "true" && userCart?.length !== cartCount) {
      setIsBouncing(true);
      setCartCount(userCart?.length);
      return;
    }
    if (isAuthenticated !== "true" && items?.length !== cartCountLocal) {
      setIsBouncing(true);
      setCartCountLocal(items?.length);
      return;
    }
  }, [userCart, cartCount, items, cartCountLocal]);

  useEffect(() => {
    if (isBouncing) {
      const timeout = setTimeout(() => {
        setIsBouncing(false);
      }, 500); // Match the animation duration
      return () => clearTimeout(timeout);
    }
  }, [isBouncing]);

  const getOngoingOrder = async () => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(currentUserOrderApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        if (data.order_code === null) return;
        setIsOngoingOrder(data);
        if (
          data.status !== "DELIVERED" ||
          data.status !== "CANCELLED DUE TO UNAVAILABILITY" ||
          data.status !== "CANCELLED-OTC" ||
          data.status !== "CANCELLED BY USER"
        )
          localStorage.setItem("orderCode", data.order_code);
      } else {
        localStorage.removeItem("orderCode");
        dispatch(setUpdates(null))
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    getOngoingOrder();
  }, []);

  return (
    <>
      {isOngoingOrder === null ||
      isOngoingOrder?.status === "DELIVERED" ||
      isOngoingOrder?.status === "CANCELLED DUE TO UNAVAILABILITY" ||
      isOngoingOrder?.status === "CANCELLED-OTC" ||
      isOngoingOrder?.status === "CANCELLED BY USER" ? (
        <div className="relative w-fit left-0 right-0 mx-auto">
          {isAuthenticated === "true" && userCart?.length > 0 && (
            <div
              className={`relative flex items-center gap-2 justify-center mb-3 text-kitsaWhite w-fit mx-auto`}
              onClick={() => {
                navigate("/cart");
                dispatch(setSearchComponent(false));
              }}
            >
              <div className="bg-kitsaGreen w-fit p-[0.65rem] h-10 rounded-full flex items-center gap-3 shadow-md shadow-formisBlack">
                <img src={cart} alt="Cart" className="w-5" />
                <div className="text-xs font-bold">View Cart</div>
                <div className="rotate-180">
                  <BackIcon width={18} color="#f1edeb" />
                </div>
              </div>

              <div
                className={`bg-kitsaGreen size-10 rounded-full flex items-center justify-center gap-3 text-[17px] font-bold shadow-md shadow-formisBlack`}
              >
                <p className={`${isBouncing ? "bounce" : ""}`}>
                  {userCart?.length}
                </p>
              </div>
            </div>
          )}
          {isAuthenticated !== "true" && items.length > 0 && (
            <div
              className={`relative flex items-center gap-2 justify-center mb-3 text-kitsaWhite`}
              onClick={() => {
                navigate("/cart");
                dispatch(setSearchComponent(false));
              }}
            >
              <div className="bg-kitsaGreen w-fit p-[0.65rem] h-10 rounded-full flex items-center gap-3 shadow-md shadow-formisBlack">
                <img src={cart} alt="Cart" className="w-5" />
                <div className="text-xs font-bold">View Cart</div>
                <div className="rotate-180">
                  <BackIcon width={18} color="#f1edeb" />
                </div>
              </div>

              <div className="bg-kitsaGreen size-10 rounded-full flex items-center justify-center gap-3 text-[17px] font-bold shadow-md shadow-formisBlack">
                <p className={`${isBouncing ? "bounce" : ""}`}>
                  {items.length}
                </p>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className={`relative flex items-center gap-2 justify-center mb-3 text-kitsaWhite w-fit mx-auto`}
          onClick={() => {
            navigate("/pending-approval");
            dispatch(setSearchComponent(false));
          }}
        >
          <div className="bg-kitsaGreen w-fit p-[0.65rem] h-10 rounded-full flex items-center gap-3 shadow-md shadow-formisBlack">
            <img src={cart} alt="Cart" className="w-5" />
            <div className="text-xs font-bold">Track Ongoing Order</div>
            <div className="rotate-180">
              <BackIcon width={18} color="#f1edeb" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewCartButton;
