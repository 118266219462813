import { lazy, Suspense } from "react";

import Footer from "../../components/Footer";
import { Link } from "react-router-dom";

import { blogPageData, featuredMain, featured } from "./blogData";
import MobileFooter from "../../components/MobileFooterMenu";

import ViewCartButton from "../../components/Footer Menu Buttons/ViewCartButton";

const Search = lazy(() => import("../../components/Search"));
const Profile = lazy(() => import("../../components/Profile"));
const AddressSlider = lazy(() => import("../../components/AddressSlider"));
const SurgeSlider = lazy(() => import("../../components/SurgeSlider"));

const Blog = () => {
  return (
    <div className="h-screen overflow-y-scroll overflow-x-hidden scrollbar-hide">
      <section className="pt-10 pb-[80px] px-4 bg-formisBlack text-kitsaWhite">
        <h2 className="text-[34px] font-medium mb-5">
          KITSA™.PULSE
        </h2>

        <section className="">
          <h4 className="text-[18px] font-medium">
            Featured
          </h4>
        </section>

        <section className="pb-[60px] mb-[60px]">
          <section className="mb-[30px]">
            <Link to={`/blog/${featuredMain[0].id}`}>
              <BlogContainer
                img={featuredMain[0].img}
                title={featuredMain[0].title}
              />
            </Link>
          </section>

          <section className="flex flex-col gap-[30px]">
            {featured.map((blog, index) => (
              <Link to={`/blog/${blog.id}`}>
                <FeaturedContainer img={blog.img} title={blog.title} />
              </Link>
            ))}
          </section>
        </section>

        <h4 className="text-[18px] mb-[40px] font-medium">
          All Blogs
        </h4>
        <section className="grid grid-cols-1 gap-[20px]">
          {blogPageData.map((blog, index) => (
            <Link key={index} to={`/blog/${blog.id}`}>
              <BlogContainer img={blog.img} title={blog.title} />
            </Link>
          ))}
        </section>
      </section>

      <Footer />

      <div className="fixed z-[30] bottom-0 w-full left-0">
        <ViewCartButton />
        <MobileFooter />
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <Search />
        <Profile />
        <AddressSlider />
        <SurgeSlider />
      </Suspense>
    </div>
  );
};

export default Blog;

const BlogContainer = ({ title, img }) => {
  return (
    <div className="flex flex-col gap-[16px] group">
      <img src={img} alt="blog" className="aspect-[16/9]" />
      <div className="flex flex-col gap-[16px]">
        <h6 className="text-[12px] font-medium tracking-widest">COMPANY</h6>
        <h3 className="text-[18px] font-medium opacity-50 transition-opacity group-hover:opacity-100">
          {title}
        </h3>
      </div>
    </div>
  );
};

const FeaturedContainer = ({ title, img }) => {
  return (
    <div className="flex flex-col gap-[16px] group">
      <img src={img} alt="blog" className="" />
      <div className="flex flex-col gap-[16px]">
        <h6 className="text-[12px] font-medium tracking-widest">COMPANY</h6>
        <h3 className="text-[18px] font-medium opacity-50 transition-opacity group-hover:opacity-100">
          {title}
        </h3>
      </div>
    </div>
  );
};
