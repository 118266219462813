import Expandable from "./Expandable";
// import avi from "../../../assets/avinandan.png";
// import nikhil from "../../../assets/nikhil.png";
// import sarthak from "../../../assets/sarthak.png";

const ProductFAQ = ({ product }) => {
  const questionsAndAnswers =
    product.questions !== "NA" &&
    product.questions.split("|").map((qa) => {
      const [question, answer] = qa.split(":::");
      return { question: question.trim(), answer: answer.trim() };
    });

  return (
    <div className="mt-14 bg-formisBlack text-kitsaWhite rounded-[10px]">
      <div className="p-7">
        <h3 className="title-lg font-bold leading-none mb-4">FAQs</h3>

        {questionsAndAnswers.map((faq, index) => (
          <Expandable
            key={index}
            title={faq.question}
            faq={true}
            isFirst={index === 0}
          >
            <p className="font-[MestizaSans]">{faq.answer}</p>
          </Expandable>
        ))}
      </div>
      {/* 
      <div className="p-7 bg-formisGrey/10">
        <h4 className="text-2xl leading-none font-bold mb-[.625rem]">
          Didn't find your answer?
        </h4>
        <p className="mb-6">Don't hesitate to contact us</p>
        <div className="flex mb-6">
          <div
            style={{ backgroundImage: `url(${avi})` }}
            className="bg-cover size-11 rounded-full "
          ></div>
          <div
            style={{ backgroundImage: `url(${nikhil})` }}
            className="bg-cover size-11 rounded-full relative right-3"
          ></div>
          <div
            style={{ backgroundImage: `url(${sarthak})` }}
            className="bg-cover size-11 rounded-full relative right-6"
          ></div>
        </div>
      </div> */}
    </div>
  );
};

export default ProductFAQ;
