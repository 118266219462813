import { useDispatch, useSelector } from "react-redux";
import {
  setAddressDetails,
  setAddressStep,
  setCoordinates,
  toggleIsAddressSliderOpen,
} from "../../features/mapSlice";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import searchIcon from "../../assets/icons/search.svg";
import LocationPageButtons from "./LocationPageButtons";
import { addressesApi } from "../../api";
import { useState } from "react";
import { setSavedAddresses } from "../../features/mapSlice";
import EditAddress from "./EditAddress";
import AddressOther from "../../assets/icon components/AddressOther";
import AddressHome from "../../assets/icon components/AddressHome";

const SavedAddresses = () => {
  const dispatch = useDispatch();
  const { savedAddresses } = useSelector((state) => state.googleMapLocation);

  return (
    <div className="text-kitsaWhite overflow-x-hidden relative h-full">
      <button
        className="bg-kitsaWhite text-formisBlack px-4 w-full grid grid-cols-4 items-center justify-center rounded-b-[30px] h-[52px] mb-5"
        onClick={() => dispatch(setAddressStep(null))}
      >
        <MdOutlineKeyboardArrowLeft className="text-2xl" />
        <p className="text-sm font-[700] col-span-2">Your Saved Addresses</p>
      </button>

      <div
        onClick={() => dispatch(setAddressStep(3))}
        className="mx-3 bg-formisWhite flex flex-col gap-4 rounded-[10px] mb-5"
      >
        <div className="flex items-center py-[18px] px-3 gap-5">
          <img src={searchIcon} alt="Search" className="w-4" />
          <input
            className="bg-transparent outline-none w-full font-[700] text-formisBlack placeholder:text-formisBlack text-xs"
            placeholder="Search for area, street name..."
            readOnly
          />
        </div>
      </div>

      <LocationPageButtons />

      <div className="mt-10">
        <h5 className="text-sm font-medium px-3 mb-5">Your Saved Addresses</h5>
        {savedAddresses?.results
          ?.slice()
          .sort((a, b) => {
            if (a.name === "Home") return -1;
            if (b.name === "Home") return 1;
            return 0;
          })
          .map((address) => (
            <Address address={address} key={address.id} />
          ))}
      </div>
    </div>
  );
};

export default SavedAddresses;

const Address = ({ address }) => {
  const dispatch = useDispatch();
  const { addressId } = useSelector((state) => state.googleMapLocation);
  const [editAddress, setEditAddress] = useState(false);

  const fetchAddresses = async () => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(addressesApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        dispatch(setSavedAddresses(data));
        // console.log("saved addresses", data);
      } else if (response.status === 401) {
        localStorage.setItem("isAuthenticated", false);
      } else {
      }
    } catch {}
  };

  const deleteAddress = async () => {
    const access = localStorage.getItem("accessToken");
    try {
      const response = await fetch(addressesApi + `/${address.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
      });

      if (response.ok) {
        // console.log("Address Deleted");
        fetchAddresses();
      } else if (response.status === 401) {
        localStorage.setItem("isAuthenticated", false);
      } else {
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const handleSelectAddress = () => {
    dispatch(
      setAddressDetails({
        address: address.address_line_1,
        address2: address.address_line_2,
        city: address.city,
        state: address.state,
        pinCode: address.postal_code,
        addressPerson: address.address_person,
        addressType: address.name,
        nearbyLandmark: address.nearby_landmark,
        contactName: address.contact_name,
        contactNumber: address.contact_number,
        addressId: address.id,
      })
    );

    dispatch(
      setCoordinates({
        lat: parseFloat(address.latitude),
        lng: parseFloat(address.longitude),
      })
    );

    dispatch(toggleIsAddressSliderOpen(false));
  };

  return (
    <div className="mb-8">
      <div className="flex items-center justify-between gap-2 mx-3">
        <div className="flex items-start gap-4">
          {address.name === "Home" ? (
            <AddressHome width="30" color="#f1edeb" />
          ) : (
            <AddressOther width="30" color="#f1edeb" />
          )}
          <div>
            <h6 className="text-xs font-bold capitalize">{address.name}</h6>
            <p className="text-sm font-normal capitalize">
              {address.address_line_1}
            </p>
            <p className="text-sm font-normal mb-2 capitalize">
              {address.address_line_2}
            </p>
          </div>
        </div>

        {address.id === addressId ? (
          <div className="shrink-0 bg-kitsaWhite text-formisBlack py-1 px-4 text-[10px] font-medium rounded-[8px]">
            You are here
          </div>
        ) : (
          <div
            onClick={handleSelectAddress}
            className="shrink-0 bg-kitsaWhite text-formisBlack py-1 px-4 text-[10px] font-medium rounded-[8px]"
          >
            Select
          </div>
        )}
      </div>
      <div className="ml-14">
        <button
          onClick={() => setEditAddress(true)}
          className="bg-kitsaWhite text-formisBlack py-1 px-4 text-xs font-medium rounded-[8px] mr-4"
        >
          Edit
        </button>
        <button
          onClick={deleteAddress}
          className="bg-kitsaWhite text-formisBlack py-1 px-4 text-xs font-medium rounded-[8px]"
        >
          Remove
        </button>
      </div>

      <div
        className={`overflow-y-scroll absolute bottom-0 left-0 bg-gradient-to-b from-[#203040] via-[#203040] to-[#15171c] transition-all duration-300 ease-out h-full w-full text-formisBlack ${
          editAddress ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <EditAddress address={address} setEditAddress={setEditAddress} />
      </div>
    </div>
  );
};
