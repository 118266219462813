import React from "react";

const LocationIcon = ({ width, color }) => {
  return (
    <svg viewBox="0 0 64 64" fill={color} width={width}>
      <defs>
        <style>
          {`
          .cls-1 {
            stroke-width: 0px;
          }
        `}
        </style>
      </defs>
      <path
        className="cls-1"
        d="m32,21.37c-4.23,0-7.67,3.44-7.67,7.67s3.44,7.68,7.67,7.68,7.67-3.44,7.67-7.68-3.44-7.67-7.67-7.67Zm0,13.35c-3.13,0-5.67-2.55-5.67-5.68s2.55-5.67,5.67-5.67,5.67,2.55,5.67,5.67-2.55,5.68-5.67,5.68Z"
      />
      <path
        className="cls-1"
        d="m31.66,17.73c-5.88.17-10.7,4.92-10.97,10.79-.21,4.59,2.34,8.82,6.5,10.78.13.06.3.17.41.35l1.93,3.35c.52.89,1.44,1.42,2.47,1.42s1.95-.53,2.47-1.43l1.93-3.35c.11-.19.28-.3.41-.36,3.96-1.86,6.51-5.88,6.51-10.25,0-3.09-1.22-5.98-3.44-8.13-2.22-2.15-5.13-3.28-8.23-3.19Zm4.31,19.76c-.56.26-1,.67-1.29,1.17l-1.93,3.35c-.22.38-.59.43-.74.43s-.52-.04-.74-.43l-1.93-3.35c-.29-.5-.74-.9-1.29-1.17-3.43-1.61-5.53-5.1-5.36-8.88.22-4.76,4.27-8.74,9.03-8.89.1,0,.19,0,.29,0,2.44,0,4.74.93,6.5,2.63,1.83,1.77,2.83,4.15,2.83,6.69,0,3.59-2.11,6.91-5.36,8.44Z"
      />
      <path
        className="cls-1"
        d="m28.37,16c.31,0,.63-.08.93-.25l2.7-1.53,2.7,1.53c.71.4,1.57.31,2.17-.24.61-.55.79-1.39.46-2.14l-3.62-8.26c-.3-.69-.96-1.12-1.71-1.12s-1.41.43-1.71,1.12l-3.62,8.26c-.33.75-.15,1.59.46,2.14.36.32.8.49,1.24.49Zm3.56-1.82s0,0,0,0h0Zm.07-7.98l3.34,7.61-2.42-1.37c-.57-.32-1.28-.32-1.84,0l-2.42,1.37,3.34-7.61Z"
      />
      <path
        className="cls-1"
        d="m34.7,48.25l-2.7,1.53-2.7-1.53c-.71-.4-1.57-.31-2.17.24-.61.55-.79,1.39-.46,2.14l3.62,8.26c.3.69.96,1.12,1.71,1.12s1.41-.43,1.71-1.12l3.62-8.26c.33-.75.15-1.59-.46-2.14-.61-.55-1.46-.64-2.17-.24Zm-2.77,1.57h0s0,0,0,0c0,0,0,0,0,0Zm.07,7.98l-3.34-7.61,2.42,1.37c.57.32,1.28.32,1.84,0l2.42-1.37-3.34,7.61Z"
      />
      <path
        className="cls-1"
        d="m57.3,27.92l-8.26-3.62c-.75-.33-1.59-.15-2.13.46-.55.61-.64,1.46-.24,2.17l1.53,2.7-1.53,2.7c-.4.71-.31,1.56.24,2.17.37.41.86.62,1.38.62.25,0,.51-.05.76-.16l8.26-3.62c.69-.3,1.12-.96,1.12-1.71s-.43-1.41-1.12-1.71Zm-8.69,5.05l1.37-2.42h0c.32-.57.32-1.27,0-1.84l-1.37-2.42,7.61,3.34-7.61,3.34Z"
      />
      <path
        className="cls-1"
        d="m15.8,29.62l1.53-2.7c.4-.71.31-1.56-.24-2.17-.55-.61-1.39-.79-2.13-.46l-8.26,3.62c-.69.3-1.12.96-1.12,1.71s.43,1.41,1.12,1.71l8.26,3.62c.25.11.51.16.76.16.51,0,1.01-.22,1.38-.62.55-.61.64-1.46.24-2.17l-1.53-2.7Zm-1.78.92h0s1.37,2.42,1.37,2.42l-7.61-3.34,7.61-3.34-1.37,2.42c-.32.57-.32,1.27,0,1.84Z"
      />
    </svg>
  );
};

export default LocationIcon;
