import { useEffect, useRef, useState } from "react";

const Accordian = ({
  data,
  status,
  title,
  children,
  id,
  desc,
  open = false,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [images, setImages] = useState([]);
  // const isEffectCalled = useRef(false);

  // useEffect(() => {
  //   if (isEffectCalled.current === false) {
  //     data
  //       ?.filter((item) => item.status === status)
  //       .map((item) => {
  //         setImages((prevResuls) => [
  //           ...prevResuls,
  //           item.product.primary_image_url,
  //         ]);
  //       });
  //   }
  //   return () => (isEffectCalled.current = true);
  // }, []);

  useEffect(() => {
    if (!data) return;
  
    const filteredImages = data
      .filter((item) => item.status === status)
      .map((item) => item.product.primary_image_url);
  
    setImages(filteredImages);
  }, [data, status]);

  return (
    <div className="scroll-smooth" id={id}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-kitsaWhite font-medium flex justify-between items-center gap-3 w-full py-[13px]"
      >
        <div className="flex">
          <div className="shrink-0">
            <div className="cart-items-images flex">
              {images.slice(0, 3).map((image, index) => (
                <div key={index} className="bg-white rounded-full">
                  <img
                    src={image}
                    className="size-6 rounded-full aspect-square"
                  />
                </div>
              ))}
            </div>
            <p className="text-start text-[10px] opacity-50">
              {images.length} items
            </p>
          </div>
          <div className="flex flex-col pl-4">
            <span className="text-xs text-left">{title}</span>
            <p className="text-[10px] opacity-50 text-left">{desc}</p>
          </div>
        </div>

        {isOpen ? (
          <div className="text-xs font-bold bg-kitsaGreen text-kitsaWhite px-2 py-1 rounded-[8px] transition-all duration-500">
            Hide
          </div>
        ) : (
          <div className="text-xs font-bold bg-kitsaGreen text-kitsaWhite px-2 py-1 rounded-[8px] transition-all duration-500">
            View
          </div>
        )}
      </button>

      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-white ${
          isOpen ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className="mb-[8px] font-light text-[14px] md:text-[16px] text-kitsaWhite overflow-hidden">
          <div className="">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Accordian;
