import { useEffect, useState } from "react";
import { searchTermsApi } from "../../api";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const AnimatedSearch = ({ handlePageChange }) => {
  const navigate = useNavigate();

  const [currentText, setCurrentText] = useState("");
  const [visible, setVisible] = useState(true);

  const [list, setList] = useState([]);

  const getSearchTerms = async () => {
    try {
      const response = await fetch(searchTermsApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        setList(data.results);
      }
    } catch {}
  };

  useEffect(() => {
    getSearchTerms();
  }, []);

  let i = 0;

  useEffect(() => {
    const textAnimation = () => {
      if (list && list.length > 0) setCurrentText(list[i].term);
      setVisible(true);
      setTimeout(() => setVisible(false), 1700);
      i = (i + 1) % list?.length;
    };
    textAnimation();
    const interval = setInterval(() => {
      textAnimation();
    }, 2000);
    return () => clearInterval(interval);
  }, [list]);

  return (
    <div className="flex items-center gap-3 bg-formisWhite text-formisBlack rounded-[10px] px-3 h-[51px] backdrop-blur-sm">
      <IoIosArrowBack
        onClick={() => {
          navigate(-1);
        }}
        className="text-xl h-[51px]"
      />
      <div
        onClick={() => handlePageChange("search")}
        className="h-[51px] flex items-center gap-2 bg-transparent w-full outline-none"
      >
        <div
          className={`text-[13px] lg:text-[12px] font-[500] leading-[-0.3px] slider left-[-5px] ${
            visible
              ? "opacity-100 translate-y-0"
              : "opacity-0 -translate-y-full"
          } transition-all`}
        >
          {currentText}
        </div>
      </div>
    </div>
  );
};

export default AnimatedSearch;
